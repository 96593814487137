import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Button } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import SettingsSideBar from './SettingsSideBar';
import SettingsHeader from './SettingsHeader';
import { COMPANY_DOMAIN_ADD } from '../../../lib/mutations';
import { useMutation, useQuery } from '@apollo/client';
import { TbWorldHeart } from 'react-icons/tb';
import { COMPANY_BY_ID_ACCESS_DOMAIN } from 'lib/query';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import usePermission from 'components/common/usePermission';

const SettingsDomains = () => {
  const { authorized: updatedAuthorized, styles } = usePermission('S21');
  const getCompany = Cookies.get('company');
  const company = getCompany ? JSON.parse(getCompany) : null;
  const companyId = company?._id;
  const { data: companyData } = useQuery(COMPANY_BY_ID_ACCESS_DOMAIN, {
    variables: { companyId }
  });
  const [domainData, setDomain] = useState();
  const [observer, setObserver] = useState(false);
  const [createCompanyDomain, { data }] = useMutation(COMPANY_DOMAIN_ADD);
  const [edit, setEdit] = useState({
    profile: false,
    address: false
  });

  useEffect(() => {
    if (companyData) {
      if (companyData?.companyById?.status?.code === 200) {
        setDomain(companyData?.companyById?.data?.domain);
      }
    }
  }, [companyData]);

  const sendDataHandle = () => {
    if (domainData && observer) {
      createCompanyDomain({
        variables: {
          input: {
            companyId: companyId.toString(),
            domain: domainData
          }
        }
      });
    }
  };

  useEffect(() => {
    if (data) {
      if (data?.companyDomainAdd?.status?.code === 200) {
        toast.success(`Амжилттай шинэчиллээ`, {
          theme: 'colored'
        });
        setObserver(false);
        setEdit({ ...edit, address: false });
        Cookies.set('company', JSON.stringify(data?.companyDomainAdd?.data), {
          expires: 7
        });
      } else {
        toast.warning(`${data?.companyDomainAdd?.status?.moreInfo}`, {
          theme: 'colored'
        });
      }
    }
  }, [data]);

  return (
    <Row className="g-3">
      <Col xl={4} xxl={3}>
        <SettingsSideBar />
      </Col>
      <Col xl={8} xxl={9}>
        <SettingsHeader data={'Домэйн'} />
        <Card className="mt-4">
          <Card.Header className="bg-light d-flex justify-content-between align-items-md-center">
            <h6 className="mb-0">Домэйний мэдээлэл</h6>
            <button
              className="btn btn-link"
              onClick={() => setEdit({ ...edit, address: !edit.address })}
              style={styles}
              disabled={updatedAuthorized}
            >
              {' '}
              <h6 className="text-primary">Домэйн засах</h6>
            </button>
          </Card.Header>
          <Card.Body>
            <Row>
              <Col lg={12} className="px-4">
                <div className="d-flex justify-content-start gap-3 align-items-center">
                  <div className="bg-soft-warning d-inline-block rounded py-1 text-warning px-2">
                    <TbWorldHeart size={20} />
                  </div>
                  {edit.address ? (
                    <>
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            {' '}
                            https://www.
                          </span>
                        </div>
                        <input
                          type="text"
                          className="form-control"
                          value={domainData}
                          onChange={e => {
                            setDomain(e.target.value), setObserver(true);
                          }}
                        />
                      </div>
                    </>
                  ) : (
                    <button className="px-0 text-bold btn btn-link">
                      {domainData ? (
                        <h6 className="mt-2 text-primary">
                          https://www.
                          {domainData && (
                            <span className="d-inline-block">{domainData}</span>
                          )}
                        </h6>
                      ) : (
                        <div className="bg-soft-warning px-3 py-1 rounded">
                          <h6 className="text-warning mt-1">
                            Mэдээлэл байхгүй байна
                          </h6>
                        </div>
                      )}
                    </button>
                  )}
                </div>
              </Col>
            </Row>
            {edit.address && (
              <Col lg={12} xxl={12} className="mt-4">
                <Flex
                  alignItems="center"
                  justifyContent="end"
                  className={`px-2 ${
                    edit.profile ? 'py-x1' : 'py'
                  } position-relative`}
                >
                  <Button
                    variant="falcon-default"
                    className="me-2 mb-1"
                    onClick={() => setEdit({ ...edit, address: !edit.address })}
                  >
                    Буцах
                  </Button>
                  <Button
                    type="submit"
                    className="me-2 mb-1"
                    disabled={!observer}
                    onClick={sendDataHandle}
                  >
                    Хадгалах
                  </Button>
                </Flex>
              </Col>
            )}
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default SettingsDomains;
