import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Form, Button } from 'react-bootstrap';
import SettingsSideBar from './SettingsSideBar';
import SettingsHeader from './SettingsHeader';
import { useMutation, useQuery } from '@apollo/client';
import { COMPANY_BY_ID_ACCESS_LOCALES } from 'lib/query';
import { COMPANY_ADD_POLICY } from 'lib/mutations';
import Cookies from 'js-cookie';
import Flex from 'components/common/Flex';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import TinymceEditor from 'components/common/TinymceEditor';
import usePermission from 'components/common/usePermission';

const SettingsLegal = () => {
  const { authorized, styles } = usePermission('S21');
  const getCompany = Cookies.get('company');
  const company = getCompany ? JSON.parse(getCompany) : null;
  const companyId = company?._id;
  const { data: companyData } = useQuery(COMPANY_BY_ID_ACCESS_LOCALES, {
    variables: { companyId }
  });
  const [companyUpdatePolicy, { data }] = useMutation(COMPANY_ADD_POLICY);
  const [edit, setEdit] = useState({
    profile: false,
    address: false
  });

  const [observer, setObserver] = useState(false);
  const [companylist, setCompanyList] = useState();
  useEffect(() => {
    if (companyData) {
      if (companyData?.companyById?.status?.code === 200) {
        setCompanyList(companyData?.companyById?.data?.policy);
      }
    }
  }, [companyData]);

  const handleTextChange = newValue => {
    setObserver(true);
    setValue('companylist', newValue);
    setCompanyList(newValue);
  };

  const { setValue } = useForm();

  const handleSubmit = async e => {
    e.preventDefault();
    try {
      const response = await companyUpdatePolicy({
        variables: {
          companyId: companyId.toString(),
          policy: companylist
        }
      });
      console.log(response);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (data) {
      if (data) {
        if (data?.companyAddPolicy?.status?.code === 200) {
          toast.success(`Амжилттай шинэчиллээ`, {
            theme: 'colored'
          });
          setEdit({ ...edit, address: false });
        } else {
          toast.warning(`${data?.companyUpdate?.status?.moreInfo}`, {
            theme: 'colored'
          });
        }
      }
    }
  }, [data]);

  return (
    <Row className="g-3">
      <Col xl={4} xxl={3}>
        <SettingsSideBar />
      </Col>
      <Col xl={8} xxl={9}>
        <SettingsHeader data={' Сайт ашиглалтын нөхцөл'} />
        <Card className="mt-4">
          <Card.Header className="bg-light d-flex justify-content-between align-items-md-center">
            <h6 className="mb-0">Ашиглалтын нөхцөл</h6>
            <button
              className="btn btn-link"
              onClick={() => setEdit({ ...edit, address: !edit.address })}
              style={styles}
              disabled={authorized}
            >
              {' '}
              <h6 className="text-primary">Засах</h6>
            </button>
          </Card.Header>
          <Card.Body>
            <Row>
              {edit.address ? (
                <Form onSubmit={handleSubmit}>
                  <Row className="gx-2 gy-3 px-4">
                    <Col xs="12">
                      <Form.Group>
                        <Form.Label>Дэлгэрэнгүй:</Form.Label>
                        <div className="create-product-description-textarea ">
                          <TinymceEditor
                            height="33.438rem"
                            handleChange={handleTextChange}
                            value={companylist}
                          />
                        </div>
                        <Form.Control.Feedback type="invalid">
                          {/* {errors.description?.message} */}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col lg={12} xxl={12}>
                      <Flex
                        alignItems="center"
                        justifyContent="end"
                        className={`px-2 ${
                          edit.address ? 'py-x1' : 'py'
                        } position-relative`}
                      >
                        <Button
                          variant="falcon-default"
                          className="me-2 mb-1"
                          onClick={() =>
                            setEdit({ ...edit, address: !edit.address })
                          }
                        >
                          Буцах
                        </Button>
                        <Button
                          variant="primary"
                          type="submit"
                          className="me-2 mb-1"
                          disabled={!observer}
                        >
                          Хадгалах
                        </Button>
                      </Flex>
                    </Col>
                  </Row>
                </Form>
              ) : (
                <div>
                  {companylist ? (
                    <div
                      className=" mb-2 mt-2 px-3"
                      dangerouslySetInnerHTML={{ __html: companylist }}
                    ></div>
                  ) : (
                    <div className="alert alert-warning" role="alert">
                      Mэдээлэл байхгүй байна
                    </div>
                  )}
                </div>
              )}
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default SettingsLegal;
