// import FalconCardFooterLink from 'components/common/FalconCardFooterLink';
import FalconCardHeader from 'components/common/FalconCardHeader';
import PropTypes from 'prop-types';
import PerfomanceTableForAgent from './PerfomanceTableForAgent';
import React from 'react';
import { Card } from 'react-bootstrap';

const UseragentProjects = ({ visitors }) => {
  return (
    <Card>
      <Card className="h-100">
        <FalconCardHeader
          title="Хандалтын бүртгэл"
          light
          titleTag="h6"
          endEl={<p className="fs--2">Сүүлийн 15 хандалт</p>}
        />
        <Card.Body className="pb-0">
          <div className="mx-ncard">
            <PerfomanceTableForAgent visitors={visitors} />
          </div>
        </Card.Body>
      </Card>
      {/* 
      <FalconCardFooterLink title="Цааш үзэх" size="sm" /> */}
    </Card>
  );
};

UseragentProjects.propTypes = {
  visitors: PropTypes.array
};

export default UseragentProjects;
