import React from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';

const CompanyType = () => {
  const {
    register,
    formState: { errors }
  } = useFormContext();

  return (
    <Card className="mb-3">
      <Card.Header as="h6" className="bg-light">
        Төлбөрийн тэмдэглэл
      </Card.Header>
      <Card.Body>
        <Row className="gx-2 gy-3">
          <Col md="12" style={{ textAlign: 'right' }}>
            <Form.Group>
              <Form.Control
                type="text"
                placeholder="Төрөл нэмэх"
                isInvalid={!!errors.description}
                {...register('description')}
              />
              {errors.description && (
                <Form.Control.Feedback type="invalid">
                  {errors.description.message}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default CompanyType;
