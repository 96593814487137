import React, { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import CompanyBasicInformation from './NewCompanyForms/CompanyBasicInformation';
import ProductHeader from './NewCompanyForms/CompanyHeader';
import ProductUpdateUpload from './NewCompanyForms/CompanyUpdateUpload';
import CompanyAddress from './NewCompanyForms/CompanyAddress';
import ProductType from './NewCompanyForms/CompanyType';
import ProductTags from './NewCompanyForms/CompanyTags';
import { useMutation, useQuery } from '@apollo/client';
import { COMPANY_UPDATE } from '../../../lib/mutations';
import { COMPANY_BY_ID } from 'lib/query';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Resizer from 'react-image-file-resizer';
import CompanyPeriod from './NewCompanyForms/CompanyPeriod';

const schema = yup
  .object({
    name: yup.string().required('Энэ талбарыг оруулна уу!'),
    phone: yup.string().required('Энэ талбарыг оруулна уу!'),
    email: yup.string().required('Энэ талбарыг оруулна уу!'),
    registerNumber: yup.string().required('Энэ талбарыг оруулна уу!'),
    companyRegister: yup.string().required('Энэ талбарыг оруулна уу!'),
    city: yup.string().required('Энэ талбарыг оруулна уу!'),
    country: yup.string().required('Энэ талбарыг оруулна уу!'),
    address: yup.string().required('Энэ талбарыг оруулна уу!'),
    address1: yup.string().required('Энэ талбарыг оруулна уу!'),
    companyType: yup.string().required('Энэ талбарыг оруулна уу!'),
    payment: yup.string().required('Энэ талбарыг оруулна уу!'),
    conditionPeriod: yup.string().required('Энэ талбарыг оруулна уу!')
  })
  .required();

// const cloudinaryURLToDropzoneFile = async (url, filename) => {
//   return await fetch(url)
//     .then(response => response.blob())
//     .then(blob => {
//       const file = new File([blob], filename, { type: blob.type });
//       return file;
//     });
// };

const CompanyUpdate = () => {
  let navigage = useNavigate();
  const { companyId } = useParams();
  const [updateCompanyData, { data }] = useMutation(COMPANY_UPDATE);
  const { data: getBlogOne } = useQuery(COMPANY_BY_ID, {
    variables: { companyId }
  });
  // images
  const [files, setFiles] = useState([]);
  const [imageUrls, setImageUrls] = useState([]);
  const submittedValues = {};
  const methods = useForm({
    resolver: yupResolver(schema)
  });
  const { handleSubmit, reset } = methods;
  const [loading, setLoading] = useState(false);

  const imageUploader = image => {
    setLoading(true);
    Resizer.imageFileResizer(
      image,
      800,
      600,
      'WEBP',
      100,
      0,
      uri => {
        axios
          .post(`${process.env.REACT_APP_FILE_URL}/uploadimage`, {
            image: uri
          })
          .then(response => {
            if (response.data?.url && response.data?.public_id) {
              const newImageUrl = {
                url: String(response?.data?.url),
                public_id: String(response?.data?.public_id),
                bucketname: image.name
              };

              setImageUrls(prevUrls => [...prevUrls, newImageUrl]);
              setLoading(false);
            }
          })
          .catch(error => {
            console.log('CLOUDINARY UPLOAD FAILED', error);
          });
      },
      'base64',
      400,
      300
    );
  };

  const onSubmit = data => {
    const newPaymently = data.paymently.map(item => {
      const { __typename, ...rest } = item;
      console.log(
        '🚀 ~ file: CompanyUpdate.js:98 ~ newPaymently ~ __typename:',
        __typename
      );

      return {
        ...rest
      };
    });

    if (Object.keys(data).length === 0) {
      toast.warning('Та мэдээллээ гүйцэт оруулна уу', {
        theme: 'colored'
      });
    } else {
      updateCompanyData({
        variables: {
          input: {
            companyType: data.companyType,
            name: data.name,
            phone: data.phone,
            email: data.email,
            companyRegister: data.companyRegister,
            registerNumber: data.registerNumber,
            logo: imageUrls[0],
            tags:
              data.tags && Array.isArray(data.tags) && data.tags.length > 0
                ? [...data.tags.map(item => item.value)]
                : [],
            address: {
              country: data.country,
              city: data.city,
              address: data.address,
              address1: data.address1
            },
            payment: {
              status: data.status,
              payment: parseFloat(data.payment),
              conditionPeriod: parseFloat(data.conditionPeriod),
              paymently: newPaymently
            }
          },
          companyId
        }
      });
      const keys = Object.keys(data);
      for (const key of keys) {
        submittedValues[key] = '';
      }
      reset({ ...submittedValues });
      // navigage('/blogs/blog-list');
    }
  };

  useEffect(() => {
    if (getBlogOne) {
      const {
        companyType,
        name,
        email,
        phone,
        tags,
        logo,
        companyRegister,
        registerNumber,
        address,
        payment
      } = getBlogOne.companyById.data;
      reset({
        companyType,
        name,
        email,
        phone,
        logo,
        companyRegister,
        registerNumber,
        city: address.city,
        address: address.address,
        address1: address.address1,
        country: address.country,
        tags: tags.map(tag => ({ value: tag, label: tag })),
        payment: payment.payment,
        conditionPeriod: payment.conditionPeriod,
        status: payment.status,
        paymently: payment.paymently
      });
      const buffer = [];
      [logo].forEach(item => {
        const newFile = {};
        Object.assign(newFile, {
          path: item?.bucketname,
          preview: item?.url
        });
        buffer.push(newFile);
      });
      const updatedData = [logo].map(item => {
        const obj = {};
        obj.url = item?.url;
        obj.bucketname = item?.bucketname;
        obj.public_id = item?.public_id;
        return obj;
      });
      setImageUrls(updatedData);
      setFiles(buffer);
    }
  }, [getBlogOne, reset]);

  useEffect(() => {
    if (data) {
      if (data?.companyUpdate?.status?.code === 200) {
        toast.success(`Амжиллтай илгээлээ`, {
          theme: 'colored'
        });
        navigage('/company/company-list');
      } else {
        toast.warning(`${data?.companyUpdate?.status?.moreInfo}`, {
          theme: 'colored'
        });
      }
    }
  }, [data]);

  const handleRemove = path => {
    let removeItem = '';
    imageUrls.forEach(item => {
      if (item.bucketname === path) {
        removeItem = item.public_id;
      }
    });
    setFiles(files.filter(file => file.path !== path));
    const updatedImageUrls = imageUrls.filter(
      image => image.bucketname !== path
    );
    setImageUrls(updatedImageUrls);
    if (removeItem) {
      axios
        .post(`${process.env.REACT_APP_FILE_URL}/removeimage`, {
          public_id: removeItem
        })
        .then(response => {
          console.log(response);
        })
        .catch(error => {
          console.log('CLOUDINARY UPLOAD FAILED', error);
        });
    }
  };

  return (
    <FormProvider {...methods}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row className="g-3">
          <Col xs={12}>
            <ProductHeader />
          </Col>
          <Col lg={8}>
            <CompanyBasicInformation />
            <ProductUpdateUpload
              setFiles={setFiles}
              files={files}
              imageUploader={imageUploader}
              handleRemove={handleRemove}
              loading={loading}
            />
            <CompanyAddress />
          </Col>
          <Col lg={4}>
            <div className="sticky-sidebar">
              <ProductType />
              <ProductTags />
              <CompanyPeriod />
            </div>
          </Col>
        </Row>
      </Form>
    </FormProvider>
  );
};

export default CompanyUpdate;
