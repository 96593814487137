import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

const Notification = ({
  title,
  message,
  modelUrl,
  status,
  unread,
  flush,
  className
}) => (
  <Link
    className={classNames(
      'notification',
      { 'notification-unread': unread, 'notification-flush': flush },
      className
    )}
    to={modelUrl}
  >
    <div className="notification-avatar pe-2">📩 </div>
    <div className="notification-body">
      <span className="notification-time">
        {title && (
          <span className="me-2 fs--1" role="img" aria-label="Emoji">
            {' '}
            {title}
          </span>
        )}
        <span className={status ? 'text-1000' : 'text-600'}>{message}</span>
      </span>
    </div>
  </Link>
);

Notification.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  modelUrl: PropTypes.string,
  unread: PropTypes.bool,
  flush: PropTypes.bool,
  className: PropTypes.string,
  status: PropTypes.bool
};

export default Notification;
