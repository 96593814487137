import React from 'react';
import { Card } from 'react-bootstrap';
import Background from 'components/common/Background';
import corner6 from 'assets/img/illustrations/corner-6.png';
import PropTypes from 'prop-types';

const CourseHeader = ({ data }) => {
  return (
    <Card className="mb-3">
      <Card.Header className="position-relative">
        <h5 className="mb-0 mt-1">{data}</h5>
        <Background image={corner6} className="d-none d-md-block bg-card" />
      </Card.Header>
    </Card>
  );
};

CourseHeader.propTypes = {
  data: PropTypes.string.isRequired
};

export default CourseHeader;
