import IconButton from 'components/common/IconButton';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { Col, Form, Row, Button } from 'react-bootstrap';
import {
  MESSAGE_BULK_UPDATE,
  MESSAGE_BULK_DELETE
} from '../../../lib/mutations';
import * as XLSX from 'xlsx';
import usePermission from 'components/common/usePermission';

function convertArrayToExcel(array) {
  const worksheet = XLSX.utils.json_to_sheet(array);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
  const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

  // Create a Blob object with the Excel data
  const blob = new Blob([excelBuffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  });

  // Create a temporary anchor element
  const anchor = document.createElement('a');
  anchor.href = URL.createObjectURL(blob);
  anchor.download = 'data.xlsx'; // Change the filename as desired
  anchor.style.display = 'none';

  // Append the anchor to the document body and click it programmatically
  document.body.appendChild(anchor);
  anchor.click();

  // Clean up
  document.body.removeChild(anchor);
  URL.revokeObjectURL(anchor.href);
}

// function workbookToExcelBlob(workbook) {
//   const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
//   return new Blob([excelBuffer], {
//     type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
//   });
// }

function removeHtmlTags(text) {
  const tempElement = document.createElement('div');
  tempElement.innerHTML = text;
  return tempElement.textContent || tempElement.innerText || '';
}

// function convertArrayToCSVDownload(array) {
//   const separator = ','; // Change this if you want a different separator
//   const csvRows = [];

//   // Create headers row
//   const headers = Object.keys(array[0]);
//   csvRows.push(headers.join(separator));

//   // Create data rows
//   for (const row of array) {
//     const values = headers.map(header => row[header]);
//     csvRows.push(values.join(separator));
//   }

//   // Join all rows into a single CSV string
//   const csvString = csvRows.join('\n');

//   // Create a Blob object with the CSV data
//   const blob = new Blob([csvString], { type: 'text/csv' });

//   // Create a temporary anchor element
//   const anchor = document.createElement('a');
//   anchor.href = URL.createObjectURL(blob);
//   anchor.download = 'newsnuud.csv'; // Change the filename as desired
//   anchor.style.display = 'none';

//   // Append the anchor to the document body and click it programmatically
//   document.body.appendChild(anchor);
//   anchor.click();

//   // Clean up
//   document.body.removeChild(anchor);
//   URL.revokeObjectURL(anchor.href);
// }

const ProductCatalogTableHeader = ({
  selectedRowIds,
  getBlogDatas,
  refetch
}) => {
  const { authorized: deleteAuthorized } = usePermission('M31');
  const [messageBulkUpdate, { data }] = useMutation(MESSAGE_BULK_UPDATE);
  const [messageBulkDelete, { data: deletedData }] =
    useMutation(MESSAGE_BULK_DELETE);
  const [actionType, setActionType] = useState('editable');
  const keyArray = Object.keys(selectedRowIds).map(Number);
  const newUpdateIds = keyArray.map(item => {
    return getBlogDatas[item]?._id;
  });

  useEffect(() => {
    if (data) {
      if (data?.messageBulkUpdate) {
        refetch();

        toast.success(`Амжиллтай засварлалаа`, {
          theme: 'colored'
        });
      } else {
        toast.warning(`Ямар нэгэн алдаа гарлаа`, {
          theme: 'colored'
        });
      }
    }
    if (deletedData) {
      if (deletedData?.messageBulkDelete) {
        refetch();

        toast.success(`Амжиллтай устгагдлаа`, {
          theme: 'colored'
        });
      } else {
        toast.warning(`Ямар нэгэн алдаа гарлаа`, {
          theme: 'colored'
        });
      }
    }
  }, [data, deletedData]);

  const handleSubmit = e => {
    e.preventDefault();
    if (newUpdateIds.length > 0 && actionType !== 'editable') {
      if (actionType !== 'delete') {
        messageBulkUpdate({
          variables: {
            actionType: actionType === 'active' ? true : false,
            messageIds: newUpdateIds
          }
        });
      } else {
        messageBulkDelete({
          variables: {
            messageIds: newUpdateIds
          }
        });
      }
    } else {
      toast.warning(`Засварлах утгаа сонгоно уу`, {
        theme: 'colored'
      });
    }
  };

  const handleFieldChange = e => {
    setActionType(e.target.value);
  };

  const handleChangeFileFormat = () => {
    const newRawData = getBlogDatas.map(blog => {
      return {
        name: blog.name,
        email: blog.email,
        phone: blog.phone,
        content: removeHtmlTags(blog.content)
      };
    });
    convertArrayToExcel(newRawData);
    // convertArrayToCSVDownload(newRawData);
  };

  return (
    <Row className="flex-between-center">
      <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
        <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">Мэдээлэл</h5>
      </Col>
      <Col xs={8} sm="auto" className="ms-auto text-end ps-0">
        {Object.keys(selectedRowIds).length > 0 ? (
          <div className="d-flex">
            <Form.Select
              size="sm"
              aria-label="Bulk actions"
              onChange={handleFieldChange}
            >
              <option value="editable">Засварлах</option>
              <option value="closed">Үзсэн гэж бүртгэх</option>
              <option value="delete">Устгах</option>
            </Form.Select>
            <Button
              type="button"
              variant="falcon-default"
              size="sm"
              className="ms-2"
              onClick={handleSubmit}
              style={
                actionType === 'delete' && deleteAuthorized
                  ? { cursor: 'not-allowed', opacity: '0.5' }
                  : undefined
              }
              disabled={actionType === 'delete' && deleteAuthorized}
            >
              Хадгалах
            </Button>
          </div>
        ) : (
          <div id="orders-actions">
            <IconButton
              variant="falcon-default"
              size="sm"
              icon="filter"
              transform="shrink-3"
              className="mx-2"
            >
              <span className="d-none d-sm-inline-block ms-1">Шүүлтүүр</span>
            </IconButton>
            <IconButton
              variant="falcon-default"
              size="sm"
              icon="external-link-alt"
              transform="shrink-3"
              onClick={handleChangeFileFormat}
            >
              <span className="d-none d-sm-inline-block ms-1">Экспорт</span>
            </IconButton>
          </div>
        )}
      </Col>
    </Row>
  );
};

ProductCatalogTableHeader.propTypes = {
  selectedRowIds: PropTypes.object,
  getBlogDatas: PropTypes.array,
  refetch: PropTypes.func
};

export default ProductCatalogTableHeader;
