import React from 'react';
import { Image, Card, Nav } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import { courseFilters } from 'data/courseData';
import { NavLink, useLocation } from 'react-router-dom';

const SettingsSideBar = () => {
  const location = useLocation();
  return (
    <Card className="course-filter">
      <Card.Header as={Flex} className="flex-between-center pt-x1">
        <Flex className="gap-2 flex-xl-grow-1 align-items-center justify-content-xl-between">
          <h5 className="mb-0 text-700 fs-0 d-flex align-items-center">
            {/* <FontAwesomeIcon icon="wrench" className="fs-wrench me-1" /> */}
            <span>Тохиргоо</span>
          </h5>
        </Flex>
      </Card.Header>
      <Card.Body className="py-0">
        <ul className="list-unstyled">
          {courseFilters.map((filter, index) => (
            <li key={index}>
              <ul className="list-unstyled">
                <Nav className="flex-column">
                  {filter.options &&
                    filter.options.map(option => (
                      <NavLink
                        key={option.label}
                        className={` fs--1 flex-1 text-truncate ${
                          option.to === location.pathname
                            ? 'active nav-link'
                            : 'nav-link text-800'
                        }  fw-medium`}
                        to={option.to}
                      >
                        {option.icon && (
                          <FontAwesomeIcon
                            icon={option.icon}
                            className={`me-3 ${
                              option.iconShrink ? 'fs--2' : 'fs--1'
                            }`}
                          />
                        )}
                        {option.svg && (
                          <Image
                            src={option.svg}
                            width={13}
                            alt=""
                            className="me-3"
                          />
                        )}{' '}
                        {option.label}
                      </NavLink>
                    ))}
                </Nav>
              </ul>
            </li>
          ))}
        </ul>
      </Card.Body>
    </Card>
  );
};

export default SettingsSideBar;
