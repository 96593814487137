import React, { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import ProductBasicInformation from './NewBlogForms/ProductBasicInformation';
import ProductHeader from './NewBlogForms/ProductHeader';
import ProductUpload from './NewBlogForms/ProductUpload';
import ProductDetails from './NewBlogForms/ProductDetails';
import ProductType from './NewBlogForms/ProductType';
import ProductTags from './NewBlogForms/ProductTags';
import { useMutation } from '@apollo/client';
import { BLOG_POST_DATA } from '../../../lib/mutations';
import axios from 'axios';
import Resizer from 'react-image-file-resizer';

const schema = yup
  .object({
    title: yup.string().required('Энэ талбарыг оруулна уу!'),
    description: yup.string().required('Энэ талбарыг оруулна уу!'),
    category: yup.string().required('Энэ талбарыг оруулна уу!')
  })
  .required();

const BlogAdd = () => {
  const navigage = useNavigate();
  const [postBlogData, { data }] = useMutation(BLOG_POST_DATA);
  const submittedValues = {};
  const methods = useForm({
    resolver: yupResolver(schema)
  });
  const [loading, setLoading] = useState(false);
  const { handleSubmit, reset } = methods;
  const [files, setFiles] = useState([]);
  const [imageUrls, setImageUrls] = useState([]);

  const imageUploader = image => {
    setLoading(true);
    Resizer.imageFileResizer(
      image,
      800,
      600,
      'WEBP',
      100,
      0,
      uri => {
        axios
          .post(`${process.env.REACT_APP_FILE_URL}/uploadimage`, {
            image: uri
          })
          .then(response => {
            if (response.data?.url && response.data?.public_id) {
              const newImageUrl = {
                url: String(response?.data?.url),
                public_id: String(response?.data?.public_id),
                bucketname: image.name
              };

              setImageUrls(prevUrls => [...prevUrls, newImageUrl]);
              setLoading(false);
            }
          })
          .catch(error => {
            console.log('CLOUDINARY UPLOAD FAILED', error);
          });
      },
      'base64',
      400,
      300
    );
  };

  const onSubmit = data => {
    if (Object.keys(data).length === 0) {
      toast.warning('Та мэдээллээ гүйцэт оруулна уу', {
        theme: 'colored'
      });
    } else {
      postBlogData({
        variables: {
          input: {
            category: data.category,
            description: data.description,
            image: imageUrls,
            tags:
              data.tags && Array.isArray(data.tags) && data.tags.length > 0
                ? [...data.tags.map(item => item.value)]
                : [],
            title: data.title
          }
        }
      });
      const keys = Object.keys(data);
      for (const key of keys) {
        submittedValues[key] = '';
      }
      reset({ ...submittedValues });
    }
  };

  useEffect(() => {
    if (data) {
      if (data?.blogCreate?.status?.code === 200) {
        toast.success(`Амжиллтай илгээлээ`, {
          theme: 'colored'
        });
        navigage(`/blogs/blog-update/${data?.blogCreate?.data?._id}`);
      } else {
        toast.warning(`${data?.blogCreate?.status?.moreInfo}`, {
          theme: 'colored'
        });
      }
    }
  }, [data]);

  const handleRemove = path => {
    let removeItem = '';
    imageUrls.forEach(item => {
      if (item.bucketname === path) {
        removeItem = item.public_id;
      }
    });
    setFiles(files.filter(file => file.path !== path));
    const updatedImageUrls = imageUrls.filter(
      image => image.bucketname !== path
    );
    setImageUrls(updatedImageUrls);
    if (removeItem) {
      axios
        .post(`https://admin.gegeensoft.live/api/v1/images/removeimage`, {
          public_id: removeItem
        })
        .then(response => {
          console.log(response);
        })
        .catch(error => {
          console.log('CLOUDINARY UPLOAD FAILED', error);
        });
    }
  };

  return (
    <FormProvider {...methods}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row className="g-3">
          <Col xs={12}>
            <ProductHeader />
          </Col>
          <Col lg={8}>
            <ProductBasicInformation />

            <ProductUpload
              setFiles={setFiles}
              files={files}
              imageUploader={imageUploader}
              handleRemove={handleRemove}
              loading={loading}
            />
            <ProductDetails />
          </Col>
          <Col lg={4}>
            <div className="sticky-sidebar">
              <ProductType />
              <ProductTags />
            </div>
          </Col>
        </Row>
      </Form>
    </FormProvider>
  );
};

export default BlogAdd;
