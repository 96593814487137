import React, { useState, useEffect, useContext } from 'react';
import { Card, Col, Row, Button, Form, ProgressBar } from 'react-bootstrap';
import SoftBadge from 'components/common/SoftBadge';
import { v4 as uuid } from 'uuid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SettingsSideBar from './SettingsSideBar';
import { GiToken } from 'react-icons/gi';
import TooltipBadge from 'components/common/TooltipBadge';
import SettingsHeader from './SettingsHeader';
import Flex from 'components/common/Flex';
import { toast } from 'react-toastify';
import {
  COMPANY_CREATE_TOKEN,
  CUSTOMER_UPDATE_ROLE_AND_PERMISSION
} from '../../../lib/mutations';
import {
  COMPANY_BY_ID_ACCESS_TOKEN,
  COMPANY_BY_ID_USERS
} from '../../../lib/query';
import { useMutation, useQuery } from '@apollo/client';
import { AuthWizardContext } from '../../../context/Context';
import usePermission from 'components/common/usePermission';

const SettingsCustomerAccounts = () => {
  const { authorized: updatedAuthorized, styles } = usePermission('S21');
  const { company } = useContext(AuthWizardContext);
  const { data: getCompanyById } = useQuery(COMPANY_BY_ID_ACCESS_TOKEN, {
    variables: { companyId: company._id },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first'
  });
  const { data: getCompanyRoles } = useQuery(COMPANY_BY_ID_USERS, {
    variables: { companyId: company._id }
  });
  const [customerUpdateRole, { data }] = useMutation(
    CUSTOMER_UPDATE_ROLE_AND_PERMISSION
  );
  const [createToken] = useMutation(COMPANY_CREATE_TOKEN);
  const [accessTokenList, setAccessTokenList] = useState();
  const [fsHandle, setFsHandle] = useState();
  const [successMessage, setSuccessMessage] = useState(false);
  const [observer, setObserver] = useState(false);
  const [usersRoles, setUsersRoles] = useState([]);
  const [roleName, setRoleName] = useState();

  useEffect(() => {
    if (getCompanyById?.companyById?.status?.code === 200) {
      const provided = getCompanyById?.companyById?.data?.accessTokenExpire;
      const providedTime = new Date(provided);
      setFsHandle(getCompanyById?.companyById?.data?.handle);
      const now = new Date();
      if (providedTime > now) {
        setAccessTokenList(getCompanyById?.companyById?.data?.accessToken);
      }
    }
    if (getCompanyRoles?.companyByIdUsers?.status?.code === 200) {
      const data = getCompanyRoles?.companyByIdUsers?.data.map(item => {
        return {
          ...item,
          role: item,
          edit: false
        };
      });
      setUsersRoles(data);
    }
  }, [getCompanyById, getCompanyRoles]);

  const createTokenHandler = async () => {
    try {
      const { data } = await createToken();

      if (data?.companyCreateToken?.status?.code === 200) {
        const accessToken = data?.companyCreateToken?.data?.accessToken;
        setAccessTokenList(accessToken);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getBadgeProps = roleValue => {
    let bg, text;

    if (roleValue === 'Worker') {
      bg = 'warning';
      text = 'Ажилчин';
    } else if (roleValue === 'Publisher') {
      bg = 'primary';
      text = 'Нийтлэгч';
    } else {
      bg = 'success';
      text = 'Админ';
    }

    return (
      <SoftBadge pill bg={bg} className="me-2 mb-0">
        {text}
      </SoftBadge>
    );
  };

  const convertToTitle = content => {
    switch (content.charAt(0)) {
      case 'M':
        return 'Зурвас';
      case 'P':
        return 'Каталог';
      case 'C':
        return 'Контент';
      case 'B':
        return 'Мэдээлэл';
      case 'S':
        return 'Тохиргоо';
      case 'U':
        return 'Хэрэглэгч';
      default:
        return '';
    }
  };

  function findRoleName(sum) {
    let roleName = '';
    if (sum > 0 && sum <= 3333) {
      roleName = 'Worker';
    } else if (sum >= 3333 && sum <= 4444) {
      roleName = 'Publisher';
    } else if (sum >= 4444 && sum <= 6666) {
      roleName = 'Admin';
    }
    setRoleName(roleName);
  }
  function calculateRoleName(array) {
    const newArray = array.map(item => parseInt(item.split('W')[1]));
    const sum = newArray.reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0
    );
    return findRoleName(sum);
  }

  function calculateProcent(array) {
    const full = 6666;
    let result = 0;
    const newArray = array.map(item => parseInt(item.split('W')[1]));
    const sum = newArray.reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0
    );
    result = Number((100 * sum) / full);
    return result;
  }

  function checkValue(value, item) {
    const newArr = item.split('W')[1];
    const first = value.split('')[0];
    const two = value.split('')[1];
    const foo = newArr[first] === two;
    return foo;
  }

  const handleChange = (e, data, key) => {
    const filteredArray = data.filter(item => item.includes(e.target.value));
    const newArray = filteredArray[0].split('W');
    const newToo = newArray[1].split('');
    if (!checkValue(e.target.name, filteredArray[0])) {
      newToo[e.target.name.split('')[0]] = e.target.name.split('')[1];
    } else {
      newToo[e.target.name.split('')[0]] = 0;
    }
    const newCipher = e.target.value + 'W' + newToo.join('');
    const updatedUsersRoles = usersRoles.map(role => {
      if (role?.role?.role?.permission) {
        const updatedPermission = role.role.role.permission.map(
          (item, index) => {
            if (index === key) {
              return newCipher;
            }
            return item;
          }
        );
        return {
          ...role,
          role: {
            ...role.role,
            role: {
              ...role.role.role,
              permission: updatedPermission
            }
          }
        };
      }
      return role;
    });
    setObserver(true);
    setUsersRoles(updatedUsersRoles);
  };

  const handleSubmit = e => {
    e.preventDefault();
    const userId = usersRoles.find(user => user.edit === true)._id;
    const permission = usersRoles.find(user => user.edit === true).role.role
      .permission;
    const role = roleName;
    if (userId && permission?.length > 0 && role) {
      customerUpdateRole({
        variables: {
          userId: userId,
          input: {
            permission: permission,
            value: role
          }
        }
      });
    }
  };

  useEffect(() => {
    if (data) {
      if (data?.customerUpdateRoleAndPermission?.status?.code === 200) {
        toast.success(`Хэрэглэгчийн эрх амжилттай солигдлоо`, {
          theme: 'colored'
        });
      } else {
        toast.warning(
          `${data?.customerUpdateRoleAndPermission?.status?.moreInfo}`,
          {
            theme: 'colored'
          }
        );
      }
    }
  }, [data]);

  return (
    <Row className="g-3">
      <Col xl={4} xxl={3}>
        <SettingsSideBar />
      </Col>
      <Col xl={8} xxl={9}>
        <SettingsHeader data={'Хэрэглэгчийн мэдээлэл'} />
        <Card>
          <Card.Header>
            <h6>Шинэ токен үүсгэх</h6>{' '}
          </Card.Header>
          <Card.Body>
            <div className="my-x3 mt-2 m-lg-2 ">
              <h6 className="mt-lg-2">{fsHandle}</h6>
            </div>
            {accessTokenList ? (
              <>
                <Flex justifyContent="end">
                  <button
                    className="btn btn-outline-secondary btn-sm mb-2 "
                    onClick={() => {
                      navigator.clipboard
                        .writeText(accessTokenList)
                        .then(() => {
                          setSuccessMessage(true);
                          console.log('Token copied to clipboard');
                        })
                        .catch(error => {
                          console.error(
                            'Failed to copy token to clipboard:',
                            error
                          );
                        });
                    }}
                  >
                    {successMessage ? (
                      <span>Хуулагдсан</span>
                    ) : (
                      <span> Хуулах</span>
                    )}
                  </button>
                </Flex>

                <input
                  disabled
                  placeholder={accessTokenList}
                  className="form-control"
                />
              </>
            ) : (
              <div className="d-flex justify-content-lg-start align-items-center ">
                <button
                  className="btn btn-link"
                  onClick={() => createTokenHandler()}
                >
                  <GiToken size={20} className="" />{' '}
                  <h6 className="text-primary mt-2 ">Токен үүсгэх</h6>
                </button>
              </div>
            )}
          </Card.Body>
        </Card>
        <Form onSubmit={handleSubmit}>
          {usersRoles?.length > 0 &&
            usersRoles?.map((usersRole, index) => (
              <Card className="mt-4" key={index}>
                <Card.Header className="d-flex justify-content-between align-items-md-center">
                  <div>
                    <h6 className="fw-bold">Хэрэглэгчийн эрхийг тодорхойлох</h6>{' '}
                    <h6 className="fw-light mb-0">
                      Үйлчлүүлэгчийн дансууд нь үйлчлүүлэгчдэд захиалгаа хянах,
                      захиалгын түүхийг үзэх, хаягийг хадгалах боломжийг
                      олгодог.
                    </h6>
                  </div>

                  <div className="border-bottom" />
                </Card.Header>
                <Card.Body className="mb-4">
                  <div className="d-flex justify-content-lg-start align-items-center ">
                    <div>
                      {usersRole?.role?.avatar ? (
                        ''
                      ) : (
                        <FontAwesomeIcon
                          icon="user-circle"
                          className="fs-3 me-2 text-700"
                        />
                      )}
                    </div>
                    <div className="flex-1">
                      <h6 className="mb-0 fw-bold">
                        {usersRole?.role?.firstName} {usersRole?.role?.lastName}
                      </h6>
                    </div>
                    <div className="flex-1 mt-2">
                      <h6 className="text-primary">{usersRole?.role?.email}</h6>
                    </div>
                    <div className="flex-1 mt-2">
                      <h6>{getBadgeProps(usersRole?.role?.role?.value)}</h6>
                    </div>
                    <div className="flex-2 mt-2  ">
                      <Button
                        className="btn btn-success mt-3 mb-4"
                        size="sm"
                        disabled={updatedAuthorized}
                        style={styles}
                        onClick={() => {
                          setUsersRoles(prevRoles =>
                            prevRoles.map((role, i) => ({
                              ...role,
                              edit: i === index ? !role.edit : role.edit
                            }))
                          );
                          setRoleName(usersRole?.role?.role?.value);
                        }}
                      >
                        {' '}
                        Ажилтны эрх солих
                      </Button>
                    </div>
                  </div>
                  {usersRole?.edit && (
                    <Form.Group as={Row} className="mb-3">
                      <Col className="my-4" sm={12} md={12}>
                        <Form.Label>{getBadgeProps(roleName)}</Form.Label>
                        <ProgressBar
                          variant="success"
                          now={calculateProcent(
                            usersRole?.role?.role?.permission
                          )}
                          label={
                            parseInt(
                              calculateProcent(
                                usersRole?.role?.role?.permission
                              )
                            ) + '%'
                          }
                        />
                      </Col>
                      {usersRole?.role?.role?.permission?.map((item, index) => {
                        if (item !== 'ZW0000') {
                          return (
                            <Col key={index} sm={{ span: 6 }}>
                              <div className="border-dashed border-bottom my-3 pe-2" />
                              <h6 className="fw-bold">
                                {convertToTitle(item)}
                                <TooltipBadge
                                  tooltip="Only The group of selected people can see your profile"
                                  icon="question-circle"
                                />
                              </h6>
                              <div className="ps-2">
                                <Form.Check
                                  type="checkbox"
                                  id={`invalidCheck-${uuid()}`}
                                  label="Унших"
                                  className="form-label-nogutter"
                                  name="01"
                                  value={item.split('W')[0]}
                                  onChange={e => {
                                    handleChange(
                                      e,
                                      usersRole?.role?.role?.permission,
                                      index
                                    ),
                                      calculateRoleName(
                                        usersRole?.role?.role?.permission
                                      );
                                  }}
                                  checked={checkValue('01', item)}
                                />
                                <Form.Check
                                  type="checkbox"
                                  id={`invalidCheck-${uuid()}`}
                                  label="Үүсгэх"
                                  className="form-label-nogutter"
                                  name="11"
                                  value={item.split('W')[0]}
                                  onChange={e => {
                                    handleChange(
                                      e,
                                      usersRole?.role?.role?.permission,
                                      index
                                    ),
                                      calculateRoleName(
                                        usersRole?.role?.role?.permission
                                      );
                                  }}
                                  checked={checkValue('11', item)}
                                />
                                <Form.Check
                                  type="checkbox"
                                  id={`invalidCheck-${uuid()}`}
                                  label="Засварлах"
                                  className="form-label-nogutter"
                                  name="21"
                                  value={item.split('W')[0]}
                                  onChange={e => {
                                    handleChange(
                                      e,
                                      usersRole?.role?.role?.permission,
                                      index
                                    ),
                                      calculateRoleName(
                                        usersRole?.role?.role?.permission
                                      );
                                  }}
                                  checked={checkValue('21', item)}
                                />
                                <Form.Check
                                  type="checkbox"
                                  id={`invalidCheck-${uuid()}`}
                                  label="Устгах"
                                  className="form-label-nogutter"
                                  name="31"
                                  value={item.split('W')[0]}
                                  onChange={e => {
                                    handleChange(
                                      e,
                                      usersRole?.role?.role?.permission,
                                      index
                                    ),
                                      calculateRoleName(
                                        usersRole?.role?.role?.permission
                                      );
                                  }}
                                  checked={checkValue('31', item)}
                                />
                              </div>
                            </Col>
                          );
                        }
                      })}
                      <Col lg={12} xxl={12} className="mt-4">
                        <Flex
                          alignItems="center"
                          justifyContent="end"
                          className={`px-2  'py-x1' 
                           position-relative`}
                        >
                          <Button
                            variant="falcon-default"
                            className="me-2 mb-1 btn-sm"
                            onClick={() =>
                              setUsersRoles(prevRoles =>
                                prevRoles.map((role, i) => ({
                                  ...role,
                                  edit: i === index ? !role.edit : role.edit
                                }))
                              )
                            }
                          >
                            Буцах
                          </Button>
                          <Button
                            type="submit"
                            className="me-2 mb-1 btn-sm"
                            disabled={!observer}
                          >
                            Хадгалах
                          </Button>
                        </Flex>
                      </Col>
                    </Form.Group>
                  )}
                </Card.Body>
              </Card>
            ))}
        </Form>
      </Col>
    </Row>
  );
};

export default SettingsCustomerAccounts;
