import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { AuthWizardContext } from 'context/Context';
import Cookies from 'js-cookie';

const AuthWizardProvider = ({ children }) => {
  const [user, setUser] = useState({});
  const [company, setCompany] = useState({});
  const [step, setStep] = useState(1);

  const value = { user, setUser, step, setStep, company, setCompany };

  useEffect(() => {
    const accessToken = Cookies.get('accessToken');
    if (accessToken) {
      const savedUser = Cookies.get('user');
      const user = savedUser ? JSON.parse(savedUser) : null;
      setUser(user);

      const savedCompany = Cookies.get('company');
      const company = savedCompany ? JSON.parse(savedCompany) : null;
      setCompany(company);
    }
  }, []);

  return (
    <AuthWizardContext.Provider value={value}>
      {children}
    </AuthWizardContext.Provider>
  );
};

AuthWizardProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AuthWizardProvider;
