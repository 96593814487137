import React from 'react';
import { Card, Col, Button, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const MessageHeader = () => {
  return (
    <Card>
      <Card.Body>
        <Row className="flex-between-center">
          <Col md>
            <h5 className="mb-2 mb-md-0">Зурвас</h5>
          </Col>
          <Col xs="auto">
            <Button
              variant="link"
              className="text-secondary fw-medium p-0 me-3"
              as={Link}
              to="/message/message-list"
              type="button"
            >
              Буцах
            </Button>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default MessageHeader;
