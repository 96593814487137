import React from 'react';
import { Card, Col, Table, Row } from 'react-bootstrap';
import SimpleBarReact from 'simplebar-react';
import PropTypes from 'prop-types';
import SoftBadge from 'components/common/SoftBadge';

import { formattedAmount } from 'helpers/helperFunctions';

const CompanyPaymentDetail = ({ payment }) => {
  if (!payment || !payment.paymently || payment.paymently.length === 0) {
    // Render a fallback UI or return null if the necessary data is not available
    return null;
  }

  const handleCalculate = (tulbur, sar, tulsun) => {
    const niit = tulbur * sar;
    const convertedTulsun = parseFloat(tulsun) * -1;
    const result = niit - convertedTulsun;
    return result;
  };

  const handleResidualCalculator = payment => {
    let residual = 0;
    payment &&
      payment.paymently.map(item => {
        const result = handleCalculate(
          payment.payment,
          payment.conditionPeriod,
          item.paidmoney
        );
        residual += result;
      });
    return residual;
  };

  return (
    <Card className="mb-3">
      <Card.Header as="h6" className="bg-light">
        Төлбөрийн мэдээлэл
      </Card.Header>
      <Card.Body>
        <div className="mt-4 fs--1">
          <SimpleBarReact>
            <Table striped className="border-bottom">
              <thead className="light">
                <tr className="bg-primary text-white dark__bg-1000">
                  <th className="border-0">Төлбөр</th>
                  <th className="border-0 text-center">Статус</th>
                  <th className="border-0 text-center">Мэдэгдэх хугацаа</th>
                  <th className="border-0 text-center">Хугацаа</th>
                  <th className="border-0 text-end">Үлдэгдэл</th>
                  <th className="border-0 text-end">Төлсөн</th>
                </tr>
              </thead>
              <tbody>
                {payment &&
                  payment.paymently.length > 0 &&
                  payment.paymently.map((item, i) => (
                    <tr key={i}>
                      <td className="align-middle">
                        <h6 className="mb-0 text-nowrap">
                          {formattedAmount(
                            Math.abs(payment.payment * payment.conditionPeriod)
                          )}
                        </h6>
                        <p className="mb-0">
                          {formattedAmount(payment.payment)} x{' '}
                          {payment.conditionPeriod + ' сар'}
                        </p>
                      </td>
                      <td className="align-middle text-center">
                        {item.isPaidMoney === 'Төлсөн' ? (
                          <SoftBadge pill bg="success" className="me-2">
                            {item.isPaidMoney}
                          </SoftBadge>
                        ) : item.isPaidMoney === 'Төлөөгүй' ? (
                          <SoftBadge pill bg="primary" className="me-2">
                            {item.isPaidMoney}
                          </SoftBadge>
                        ) : (
                          <SoftBadge pill bg="secondary" className="me-2">
                            {item.isPaidMoney}
                          </SoftBadge>
                        )}
                      </td>
                      <td className="align-middle text-center">
                        <p className="mb-0">
                          {item.notificationPeriod.split('T')[0]}
                        </p>
                      </td>
                      <td className="align-middle text-center">
                        <p className="mb-1">
                          {item.paymentActivationAt.split('T')[0]}
                        </p>
                        <p className="mb-0">{item.untilPeriod.split('T')[0]}</p>
                      </td>
                      <td className="align-middle text-end">
                        {formattedAmount(
                          handleCalculate(
                            Number(payment.payment),
                            Number(payment.conditionPeriod),
                            Number(item.paidmoney)
                          )
                        )}
                      </td>
                      <td className="align-middle text-end">
                        {formattedAmount(Math.abs(item.paidmoney))}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </SimpleBarReact>
        </div>

        <Row className="justify-content-end">
          <Col xs="auto">
            <Table borderless size="sm" className="fs--1 text-end">
              <tbody>
                <tr>
                  <th className="text-900">Үлдэгдэл:</th>
                  <td className="fw-semi-bold">
                    {formattedAmount(handleResidualCalculator(payment))}
                  </td>
                </tr>
                <tr>
                  <th className="text-900">НӨАТ 2%:</th>
                  <td className="fw-semi-bold">{formattedAmount(0)}</td>
                </tr>
                <tr className="border-top">
                  <th className="text-900">Нийт төлбөр:</th>
                  <td className="fw-semi-bold">
                    {handleResidualCalculator(payment) > 0
                      ? formattedAmount(handleResidualCalculator(payment))
                      : formattedAmount(0)}
                  </td>
                </tr>
                <tr className="border-top border-top-2 fw-bolder text-900">
                  <th>Төлөх дүн:</th>
                  <td>{formattedAmount(handleResidualCalculator(payment))}</td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

CompanyPaymentDetail.propTypes = {
  payment: PropTypes.object
};

export default CompanyPaymentDetail;
