import React, { useState, useContext, useEffect } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import { toast } from 'react-toastify';
import { useMutation } from '@apollo/client';
import { CUSTOMER_UPDATE } from '../../../../lib/mutations';
import Avatar from 'components/common/Avatar';
import { AuthWizardContext } from '../../../../context/Context';
import Cookies from 'js-cookie';
import axios from 'axios';
import Resizer from 'react-image-file-resizer';

const styles = {
  cursor: 'pointer'
};

const ProfileSettings = () => {
  const { user, setUser } = useContext(AuthWizardContext);
  const [userUpdate, { data }] = useMutation(CUSTOMER_UPDATE);
  const [formData, setFormData] = useState();
  const [imageUrl, setImageUrl] = useState({});
  const [observer, setObserver] = useState(false);
  const imageUploader = image => {
    Resizer.imageFileResizer(
      image,
      1920,
      1080,
      'JPEG',
      100,
      0,
      uri => {
        axios
          .post(`${process.env.REACT_APP_FILE_URL}/uploadimage`, {
            image: uri
          })
          .then(response => {
            if (response.data?.url && response.data?.public_id) {
              const newImageUrl = {
                url: response?.data?.url.toString(),
                public_id: response?.data?.public_id.toString(),
                bucketname: image.name,
                avatarImage: ''
              };
              formData.avatar = newImageUrl;
              setFormData(formData);
              setImageUrl(newImageUrl);
            }
          })
          .catch(error => {
            console.log('CLOUDINARY UPLOAD FAILED', error);
          });
      },

      'base64'
    );
  };

  useEffect(() => {
    user && setFormData(user);
  }, [user]);

  useEffect(() => {
    if (data) {
      if (data?.customerUpdate?.status?.code === 200) {
        toast.success(`Амжиллтай нэвтэрлээ ${formData.email}`, {
          theme: 'colored'
        });
        Cookies.set('user', JSON.stringify(data?.customerUpdate?.data), {
          expires: 7
        });
        setUser(data?.customerUpdate?.data);
      } else {
        toast.warning(`${data?.customerUpdate?.status?.moreInfo}`, {
          theme: 'colored'
        });
      }
    }
  }, [data]);

  const handleChange = e => {
    setObserver(true);
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleChangeImage = e => {
    const file = e.target.files[0];
    imageUploader(file);
  };

  const handleSubmit = e => {
    e.preventDefault();
    const preparedImage = {
      url: formData?.avatar?.url.toString(),
      bucketname: formData?.avatar?.bucketname,
      public_id: formData?.avatar?.public_id,
      avatarImage: formData?.avatar?.avatarImage,
      isAvatarImageSet: formData?.avatar?.isAvatarImageSet
    };
    userUpdate({
      variables: {
        input: {
          firstName: formData?.firstName,
          lastName: formData?.lastName,
          phone: formData?.phone,
          avatar: imageUrl ? imageUrl : preparedImage
        },
        userId: formData._id.toString()
      }
    });
    setObserver(false);
  };

  return (
    <Card>
      <FalconCardHeader title="Хувийн мэдээлэл" />
      <Card.Body className="bg-light">
        <Form onSubmit={handleSubmit}>
          <Row className="mb-3 g-3 align-items-center">
            <Form.Group as={Col} lg={6} controlId="firstName">
              <Avatar src={formData?.avatar?.url} rounded="circle" size="4xl" />
            </Form.Group>

            <Form.Group as={Col} lg={6} controlId="firstName">
              <label className="btn btn-primary mb-0" htmlFor="uploadfile-1">
                Өөрчлөх
              </label>
              <input
                id="uploadfile-1"
                style={styles}
                accept="image/*"
                type="file"
                name="image"
                className="form-control d-none"
                onChange={handleChangeImage}
              />
            </Form.Group>
          </Row>
          <Row className="mb-3 g-3">
            <Form.Group as={Col} lg={6} controlId="firstName">
              <Form.Label>Нэр</Form.Label>
              <Form.Control
                type="text"
                placeholder="First Name"
                value={formData?.firstName || ''}
                name="firstName"
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group as={Col} lg={6} controlId="lastName">
              <Form.Label>Овог</Form.Label>
              <Form.Control
                type="text"
                placeholder="Last Name"
                value={formData?.lastName || ''}
                name="lastName"
                onChange={handleChange}
              />
            </Form.Group>
          </Row>
          <Row className="mb-3 g-3">
            <Form.Group as={Col} lg={6} controlId="email">
              <Form.Label>Имэйл</Form.Label>
              <Form.Control
                disabled
                type="email"
                placeholder="Email"
                value={formData?.email || ''}
                name="email"
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group as={Col} lg={6} controlId="phone">
              <Form.Label>Утас</Form.Label>
              <Form.Control
                type="text"
                placeholder="Phone"
                value={formData?.phone || ''}
                name="phone"
                onChange={handleChange}
              />
            </Form.Group>
          </Row>

          <div className="text-end">
            <Button variant="primary" type="submit" disabled={!observer}>
              Шинэчлэх
            </Button>
          </div>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default ProfileSettings;
