import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import PageHeader from 'components/common/PageHeader';
import SoftBadge from 'components/common/SoftBadge';

const ProductCatalogHeader = ({ getMessageDatas }) => {
  return (
    <Row className="g-3 mb-3">
      <Col md={7} xxl={7}>
        <PageHeader title="Контент засах" titleTag="h5">
          <p className="fs--1 mt-1">
            <SoftBadge pill bg="success" className="fs--2"></SoftBadge>
            Та вебийн бичвэрийг өөрлчөх бол Текст нүдэн дээр очоод засварлаад
            нүднээс курсорыг холдуулахад засагдах болно.
          </p>
        </PageHeader>
      </Col>
      <Col md={5} xxl={5}>
        <PageHeader title="Нийт зурвас" titleTag="h5">
          <p className="fs--1 mt-1">
            <SoftBadge pill bg="primary" className="fs--2">
              Нийт {getMessageDatas.length} бичвэр байна
            </SoftBadge>
          </p>
        </PageHeader>
      </Col>
    </Row>
  );
};
ProductCatalogHeader.propTypes = {
  getMessageDatas: PropTypes.array
};
export default ProductCatalogHeader;
