import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import MessageDetailAside from './MessageDetailAside';
import MessageDetailContent from './MessageDetailContent';
import MessageHeader from './MessageHeader';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { MESSAGE_BY_ID } from '../../../../lib/query';

const MessageDetail = () => {
  const { messageId } = useParams();
  const { data: getMessageOne } = useQuery(MESSAGE_BY_ID, {
    variables: { messageId }
  });
  const [getMessage, setGetMessage] = useState();

  useEffect(() => {
    if (getMessageOne) {
      setGetMessage(getMessageOne.messageById.data);
    }
  }, [getMessageOne]);

  return (
    <>
      <MessageHeader />
      <Row className="g-3 mt-1">
        <Col lg={8}>
          <MessageDetailContent getMessage={getMessage} />
        </Col>
        <Col lg={4}>
          <MessageDetailAside getMessage={getMessage} />
        </Col>
      </Row>
    </>
  );
};

export default MessageDetail;
