import { useQuery } from '@apollo/client';
import { PRODUCT_CATALOG_CATEGORIES } from '../../../../lib/query';
import React, { useState, useEffect } from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';

const ProductCatalogType = () => {
  const {
    loading,
    error,
    data: getCategories
  } = useQuery(PRODUCT_CATALOG_CATEGORIES);
  const [categories, setCategories] = useState([]);

  const {
    register,
    formState: { errors }
  } = useFormContext();

  useEffect(() => {
    if (getCategories && getCategories.productCatalogCategories) {
      setCategories(getCategories.productCatalogCategories);
    }
  }, [getCategories]);

  if (loading) return 'Loading...';
  if (error) return `Error! ${error.message}`;

  return (
    <Card className="mb-3">
      <Card.Header as="h6" className="bg-light">
        Категори
      </Card.Header>
      <Card.Body>
        <Row className="gx-2 gy-3">
          <Col md="12">
            <Form.Group>
              <Form.Label>Категори сонгох</Form.Label>
              <Form.Select
                {...register(`category`)}
                isInvalid={!!errors.category}
              >
                {categories.map((category, i) => (
                  <option key={i} value={category}>
                    {category}
                  </option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errors.category?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default ProductCatalogType;
