import React, { useEffect, useContext } from 'react';
import LogoutContent from 'components/authentication/LogoutContent';
import Cookies from 'js-cookie';
import AuthCardLayout from 'layouts/AuthCardLayout';
import { AuthWizardContext } from '../../../context/Context';

const Logout = () => {
  const { setUser } = useContext(AuthWizardContext);
  useEffect(() => {
    Cookies.remove('accessToken');
    Cookies.remove('user');
    Cookies.remove('company');
    setUser({});
  }, []);

  return (
    <AuthCardLayout>
      <div className="text-center">
        <LogoutContent layout="card" titleTag="h3" />
      </div>
    </AuthCardLayout>
  );
};

export default Logout;
