import React, { useState, useEffect } from 'react';
import MultiSelect from 'components/common/MultiSelect';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import { COMPANY_TAGS } from '../../../../lib/query';
import { useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';

const CompanyTags = () => {
  const { loading, error, data: getTags } = useQuery(COMPANY_TAGS);
  const { control } = useFormContext();
  const [tags, setTags] = useState([]);
  const [addTag, setAddTag] = useState(false);
  const [newTag, setNewTag] = useState('');

  useEffect(() => {
    if (getTags && getTags?.companyTags) {
      setTags(getTags.companyTags);
    }
  }, [getTags]);

  const handleFieldChange = e => {
    setNewTag(e.target.value);
  };

  const handleAddCategory = () => {
    if (newTag) {
      setTags([...tags, newTag]);
      setNewTag('');
    }
  };

  if (loading) return 'Loading...';
  if (error) return `Error! ${error.message}`;

  return (
    <Card className="mb-3">
      <Card.Header as="h6" className="bg-light">
        Таг
      </Card.Header>
      <Card.Body>
        <Row className="gx-2 gy-3">
          <Col xs="12">
            <Form.Group>
              <Form.Label>Таг сонгох</Form.Label>
              <Controller
                name="tags"
                render={({ field, ref }) => (
                  <MultiSelect
                    {...field}
                    ref={ref}
                    closeMenuOnSelect={false}
                    isMulti
                    options={tags.map(option => ({
                      value: option,
                      label: option
                    }))}
                  />
                )}
                control={control}
              />
            </Form.Group>
          </Col>
          <Col md="12" style={{ textAlign: 'right' }}>
            <Link onClick={() => setAddTag(!addTag)}>
              <h6 className="text-primary">Таг нэмэх</h6>
            </Link>
            {addTag && (
              <Form.Group>
                <Form.Control
                  value={newTag}
                  name="tag"
                  onChange={handleFieldChange}
                  type="text"
                  placeholder="Таг нэмэх"
                  onBlur={handleAddCategory}
                />
              </Form.Group>
            )}
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default CompanyTags;
