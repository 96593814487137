import React from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';

const ProductCatalogPrice = () => {
  const {
    register,
    formState: { errors }
  } = useFormContext();

  return (
    <Card className="mb-3">
      <Card.Header as="h6" className="bg-light">
        Бүтээгдэхүүн үнэ
      </Card.Header>
      <Card.Body>
        <Row className="gx-2 gy-3">
          <Col md="12">
            <Form.Label>Үнэ</Form.Label>
            <Form.Group>
              <Form.Control
                name="price"
                isInvalid={!!errors.price}
                {...register('price')}
                type="number"
                placeholder="Үнэ"
              />
              <Form.Control.Feedback type="invalid">
                {errors.price?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col md="12">
            <Form.Label>Тоо ширхэг</Form.Label>
            <Form.Group>
              <Form.Control
                name="quantity"
                isInvalid={!!errors.quantity}
                {...register('quantity')}
                type="number"
                placeholder="Тоо ширхэг"
              />
              <Form.Control.Feedback type="invalid">
                {errors.quantity?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default ProductCatalogPrice;
