import React from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';

const CompanyPeriod = () => {
  const {
    register,
    formState: { errors }
  } = useFormContext();

  return (
    <Card className="mb-3">
      <Card.Header as="h6" className="bg-light">
        Төлбөрийн мэдээлэл
      </Card.Header>
      <Card.Body>
        <Row className="gx-2 gy-3">
          <Col md="12" style={{ textAlign: 'right' }}>
            <Form.Group>
              <Form.Control
                type="number"
                placeholder="48000 төг"
                isInvalid={!!errors.payment}
                {...register('payment')}
              />
              {errors.payment && (
                <Form.Control.Feedback type="invalid">
                  {errors.payment.message}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Col>
          <Col md="12" style={{ textAlign: 'right' }}>
            <Form.Group>
              <Form.Control
                type="number"
                placeholder="6 сар"
                isInvalid={!!errors.conditionPeriod}
                {...register('conditionPeriod')}
              />
              {errors.conditionPeriod && (
                <Form.Control.Feedback type="invalid">
                  {errors.conditionPeriod.message}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default CompanyPeriod;
