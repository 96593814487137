import PropTypes from 'prop-types';
import React, { useEffect, useState, useContext } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useMutation } from '@apollo/client';
import { CUSTOMER_LOGIN } from '../../lib/mutations';
import Cookies from 'js-cookie';
import { AuthWizardContext } from '../../context/Context';

const LoginForm = ({ hasLabel, layout }) => {
  const [userLogin, { data }] = useMutation(CUSTOMER_LOGIN);
  const { setUser } = useContext(AuthWizardContext);

  // State
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    remember: false
  });

  useEffect(() => {
    // if (loading) return 'Submitting...';
    // if (error) return `Submission error! ${error.message}`;
    if (data) {
      if (data?.customerLogin?.status?.code === 200) {
        toast.success(`Амжиллтай нэвтэрлээ ${formData.email}`, {
          theme: 'colored'
        });
        if (data?.customerLogin?.token) {
          Cookies.set('accessToken', data?.customerLogin?.token, {
            expires: 7
          });
        }
        if (data?.customerLogin?.data) {
          Cookies.set('user', JSON.stringify(data?.customerLogin?.data), {
            expires: 7
          });
          setUser(data?.customerLogin?.data);
        }
        if (data?.customerLogin?.company) {
          Cookies.set('company', JSON.stringify(data?.customerLogin?.company), {
            expires: 7
          });
          setUser(data?.customerLogin?.company);
        }

        window.location.assign('/');
      } else {
        toast.warning(`${data?.customerLogin?.status?.moreInfo}`, {
          theme: 'colored'
        });
      }
    }
  }, [data]);

  // Handler
  const handleSubmit = e => {
    e.preventDefault();
    userLogin({
      variables: {
        input: {
          email: formData.email,
          password: formData.password
        }
      }
    });
  };

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Имэйл хаяг</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Email address' : ''}
          value={formData.email}
          name="email"
          onChange={handleFieldChange}
          type="email"
        />
      </Form.Group>

      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Нууц үг</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Password' : ''}
          value={formData.password}
          name="password"
          onChange={handleFieldChange}
          type="password"
        />
      </Form.Group>

      <Row className="justify-content-between align-items-center">
        <Col xs="auto">
          <Form.Check type="checkbox" id="rememberMe" className="mb-0">
            <Form.Check.Input
              type="checkbox"
              name="remember"
              checked={formData.remember}
              onChange={e =>
                setFormData({
                  ...formData,
                  remember: e.target.checked
                })
              }
            />
            <Form.Check.Label className="mb-0 text-700">
              Сануулах
            </Form.Check.Label>
          </Form.Check>
        </Col>

        <Col xs="auto">
          <Link
            className="fs--1 mb-0"
            to={`/authentication/${layout}/forgot-password`}
          >
            Нууц үг солих
          </Link>
        </Col>
      </Row>

      <Form.Group>
        <Button
          type="submit"
          color="primary"
          className="mt-3 w-100"
          disabled={!formData.email || !formData.password}
        >
          Нэвтрэх
        </Button>
      </Form.Group>
    </Form>
  );
};

LoginForm.propTypes = {
  layout: PropTypes.string,
  hasLabel: PropTypes.bool
};

LoginForm.defaultProps = {
  layout: 'simple',
  hasLabel: false
};

export default LoginForm;
