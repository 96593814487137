import React, { useState, useEffect, useContext } from 'react';
import { Card, Col, Row, Button, Form } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import SettingsHeader from './SettingsHeader';
import SettingsSideBar from './SettingsSideBar';
import { AuthWizardContext } from '../../../context/Context';
import Avatar from 'components/common/Avatar';
import axios from 'axios';
import Resizer from 'react-image-file-resizer';
import { useMutation } from '@apollo/client';
import {
  FcServices,
  FcHome,
  FcCallback,
  FcInvite,
  FcDocument,
  FcInspection,
  FcGlobe,
  FcOrganization
} from 'react-icons/fc';
import { COMPANY_UPDATE_WITHOUT_PAYMENT } from '../../../lib/mutations';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';
import usePermission from 'components/common/usePermission';

const SettingsGemeral = () => {
  const { authorized: updatedAuthorized } = usePermission('S21');
  const [companyUpdate, { data }] = useMutation(COMPANY_UPDATE_WITHOUT_PAYMENT);
  const { company, setCompany } = useContext(AuthWizardContext);
  const [formData, setFormData] = useState({});
  const [formAddress, setFormAddress] = useState({});
  const [imageUrl, setImageUrl] = useState({});
  const [observer, setObserver] = useState(false);
  const [observerAddress, setObserverAddress] = useState(false);

  const [edit, setEdit] = useState({
    profile: false,
    address: false
  });

  useEffect(() => {
    company && setFormData(company);
    company && setFormAddress(company?.address);
  }, [company]);

  useEffect(() => {
    if (data) {
      if (data?.companyUpdateWithoutPayment?.status?.code === 200) {
        toast.success(`Амжилттай шинэчиллээ`, {
          theme: 'colored'
        });
        Cookies.set(
          'company',
          JSON.stringify(data?.companyUpdateWithoutPayment?.data),
          {
            expires: 7
          }
        );
        setCompany(data?.companyUpdateWithoutPayment?.data);
      } else {
        toast.warning(
          `${data?.companyUpdateWithoutPayment?.status?.moreInfo}`,
          {
            theme: 'colored'
          }
        );
      }
    }
  }, [data]);

  const imageUploader = image => {
    Resizer.imageFileResizer(
      image,
      800,
      600,
      'WEBP',
      100,
      0,
      uri => {
        axios
          .post(`${process.env.REACT_APP_FILE_URL}/uploadimage`, {
            image: uri
          })
          .then(response => {
            if (response.data?.url && response.data?.public_id) {
              const newImageUrl = {
                url: response?.data?.url.toString(),
                public_id: response?.data?.public_id.toString(),
                bucketname: image.name
              };
              formData.logo = newImageUrl;
              setFormData(formData);
              setImageUrl(newImageUrl);
            }
          })
          .catch(error => {
            console.log('CLOUDINARY UPLOAD FAILED', error);
          });
      },
      'base64',
      400,
      300
    );
  };

  const handleChangeImage = e => {
    setObserver(true);
    const file = e.target.files[0];
    imageUploader(file);
  };

  const handleChange = e => {
    setObserver(true);
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = e => {
    e.preventDefault();

    const preparedImage = {
      url: formData?.logo?.url.toString(),
      bucketname: formData?.logo?.bucketname,
      public_id: formData?.logo?.public_id
    };
    companyUpdate({
      variables: {
        input: {
          name: formData?.name,
          address: {
            country: formData?.address?.country,
            city: formData?.address?.country,
            address: formData?.address?.address,
            address1: formData?.address?.address1
          },
          email: formData?.email,
          companyType: formData?.companyType,
          phone: formData?.phone,
          registerNumber: formData?.registerNumber,
          companyRegister: formData?.companyRegister,
          logo: imageUrl?.url ? imageUrl : preparedImage,
          tags: formData?.tags
        },
        companyId: formData._id.toString()
      }
    });
  };
  const handleSubmitAddress = e => {
    e.preventDefault();

    companyUpdate({
      variables: {
        input: {
          name: formData?.name,
          address: {
            country: formAddress?.country,
            city: formAddress?.city,
            address: formAddress?.address,
            address1: formAddress?.address1
          },
          email: formData?.email,
          companyType: formData?.companyType,
          phone: formData?.phone,
          registerNumber: formData?.registerNumber,
          companyRegister: formData?.companyRegister,
          logo: {
            url: formData.logo.url.toString(),
            bucketname: formData.logo.bucketname,
            public_id: formData.logo.public_id
          },
          tags: formData?.tags
        },
        companyId: formData._id.toString()
      }
    });
  };

  const addressHandleChange = event => {
    setObserverAddress(true);
    setFormAddress({ ...formAddress, [event.target.name]: event.target.value });
  };

  return (
    <Row className="g-3">
      <Col xl={4} xxl={3}>
        <SettingsSideBar />
      </Col>
      <Col xl={8} xxl={9}>
        <SettingsHeader data={'Байгууллагын мэдээлэл'} />
        <Card>
          <Card.Header className="bg-light d-flex justify-content-between align-items-md-center">
            <div>
              <h6 className="mb-0">Профайл</h6>
            </div>
            <div>
              <button
                className="btn btn-link"
                onClick={() => setEdit({ ...edit, profile: !edit.profile })}
              >
                <h6 className="mb-0 text-primary">
                  {' '}
                  {edit.profile ? 'Буцах' : updatedAuthorized ? '' : 'Засах'}
                </h6>
              </button>{' '}
            </div>
          </Card.Header>
          <Card.Body>
            <Form onSubmit={handleSubmit}>
              <Row className="g-0">
                {edit.profile ? (
                  <Col lg={6} xxl={6}>
                    <Flex
                      alignItems="center"
                      className={`px-2 ms-2 ${
                        edit.profile ? 'py-x1' : 'py'
                      } position-relative`}
                    >
                      <Avatar
                        src={formData?.logo?.url}
                        rounded="soft"
                        size="4xl"
                      />
                    </Flex>
                  </Col>
                ) : (
                  <Col lg={12} xxl={12}>
                    <Flex
                      alignItems="center"
                      className={`px-2 ms-5 ${
                        edit.profile ? 'py-x1' : 'py'
                      } position-relative`}
                    >
                      <Avatar
                        src={formData?.logo?.url}
                        rounded="soft"
                        size="4xl"
                      />
                    </Flex>
                  </Col>
                )}

                {edit.profile && (
                  <Col lg={6} xxl={6}>
                    <Flex
                      alignItems="center"
                      className={`px-2 ${
                        edit.profile ? 'py-x1' : 'py'
                      } position-relative`}
                    >
                      <label
                        className="btn btn-primary mb-0"
                        htmlFor="uploadfile-1"
                      >
                        Өөрчлөх
                      </label>
                      <input
                        id="uploadfile-1"
                        accept="image/*"
                        type="file"
                        name="image"
                        className="form-control d-none"
                        onChange={handleChangeImage}
                      />
                    </Flex>
                  </Col>
                )}

                <Col lg={6} xxl={6}>
                  <Flex
                    alignItems="center"
                    className={`px-2 ${
                      edit.profile ? 'py-x1' : 'py'
                    } position-relative`}
                  >
                    {edit.profile ? (
                      <Form.Group as={Col} lg={12} controlId="name">
                        <Form.Label>Нэр</Form.Label>
                        <input
                          placeholder="Байгууллагын нэр"
                          className="form-control"
                          name="name"
                          onChange={handleChange}
                          value={formData?.name || ''}
                        />
                      </Form.Group>
                    ) : (
                      <>
                        <div
                          className={`bg-orange p-2 text-dark bg-opacity-10 rounded`}
                        >
                          <div className="bg-soft-info d-inline-block rounded py-1 text-warning px-2">
                            <FcHome size={20} />
                          </div>
                        </div>

                        <div className="ms-3 my-x1">
                          <h5 className="fs-0 fw-semi-bold mb-2">
                            <div className="text-900">{'Байгууллагын нэр'}</div>
                          </h5>
                          <h6 className="mb-0 text-600">{formData?.name}</h6>
                        </div>
                      </>
                    )}
                  </Flex>
                </Col>
                <Col lg={6} xxl={6}>
                  <Flex
                    alignItems="center"
                    className={`px-2 ${
                      edit.profile ? 'py-x1' : 'py'
                    } position-relative`}
                  >
                    {edit.profile ? (
                      <Form.Group as={Col} lg={12} controlId="companyType">
                        <Form.Label> Үйлчилгээний төрөл</Form.Label>
                        <input
                          placeholder="Үйлчилгээний төрөл"
                          className="form-control"
                          name="companyType"
                          onChange={handleChange}
                          value={formData?.companyType || ''}
                        />
                      </Form.Group>
                    ) : (
                      <>
                        <div
                          className={`bg-orange p-2 text-dark bg-opacity-10 rounded`}
                        >
                          <div className="bg-soft-warning d-inline-block rounded py-1 text-warning px-2">
                            <FcServices size={20} />
                          </div>
                        </div>

                        <div className="ms-3 my-x1">
                          <h5 className="fs-0 fw-semi-bold mb-2">
                            <div className="text-900">
                              {'Үйлчилгээний төрөл'}
                            </div>
                          </h5>
                          <h6 className="mb-0 text-600">
                            {formData?.companyType}
                          </h6>
                        </div>
                      </>
                    )}
                  </Flex>
                </Col>
                <Col lg={6} xxl={6}>
                  <Flex
                    alignItems="center"
                    className={`px-2 ${
                      edit.profile ? 'py-x1' : 'py'
                    } position-relative`}
                  >
                    {edit.profile ? (
                      <Form.Group as={Col} lg={12} controlId="phone">
                        <Form.Label>Утас</Form.Label>
                        <input
                          placeholder="Утас"
                          className="form-control"
                          name="phone"
                          onChange={handleChange}
                          value={formData?.phone || ''}
                        />
                      </Form.Group>
                    ) : (
                      <>
                        <div
                          className={`bg-orange p-2 text-dark bg-opacity-10 rounded`}
                        >
                          <div className="bg-soft-primary d-inline-block rounded py-1 text-warning px-2">
                            <FcCallback size={20} />
                          </div>
                        </div>
                        <div className="ms-3 my-x1">
                          <h5 className="fs-0 fw-semi-bold mb-2">
                            <div className="text-900">{'Холбоо барих'}</div>
                          </h5>
                          <h6 className="mb-0 text-600">{formData?.phone}</h6>
                        </div>
                      </>
                    )}
                  </Flex>
                </Col>
                <Col lg={6} xxl={6}>
                  <Flex
                    alignItems="center"
                    className={`px-2 ${
                      edit.profile ? 'py-x1' : 'py'
                    } position-relative`}
                  >
                    {edit.profile ? (
                      <Form.Group as={Col} lg={12} controlId="email">
                        <Form.Label>Имэйл</Form.Label>
                        <input
                          placeholder="Бизнес имэйл"
                          className="form-control"
                          value={formData?.email}
                          disabled
                        />
                      </Form.Group>
                    ) : (
                      <>
                        <div
                          className={`bg-orange p-2 text-dark bg-opacity-10 rounded`}
                        >
                          <div className="bg-200 d-inline-block rounded py-1 text-warning px-2">
                            <FcInvite size={20} />
                          </div>
                        </div>

                        <div className="ms-3 my-x1">
                          <h5 className="fs-0 fw-semi-bold mb-2">
                            <div className="text-900">{'Бизнес имэйл'}</div>
                          </h5>
                          <h6 className="mb-0 text-600">{formData?.email}</h6>
                        </div>
                      </>
                    )}
                  </Flex>
                </Col>

                <Col lg={6} xxl={6}>
                  <Flex
                    alignItems="center"
                    className={`px-2 ${
                      edit.profile ? 'py-x1' : 'py'
                    } position-relative`}
                  >
                    {edit.profile ? (
                      <Form.Group as={Col} lg={12} controlId="companyRegister">
                        <Form.Label>Компанийн гэрчилгээ</Form.Label>
                        <input
                          placeholder="Компанийн гэрчилгээ"
                          className="form-control"
                          name="companyRegister"
                          onChange={handleChange}
                          value={formData?.companyRegister || ''}
                        />
                      </Form.Group>
                    ) : (
                      <>
                        <div
                          className={`bg-orange p-2 text-dark bg-opacity-10 rounded`}
                        >
                          <div className="bg-soft-primary d-inline-block rounded py-1 text-warning px-2">
                            <FcDocument size={20} />
                          </div>
                        </div>

                        <div className="ms-3 my-x1">
                          <h5 className="fs-0 fw-semi-bold mb-2">
                            <div className="text-900">
                              {'Компанийн гэрчилгээ'}
                            </div>
                          </h5>
                          <h6 className="mb-0 text-600">
                            {formData?.companyRegister}
                          </h6>
                        </div>
                      </>
                    )}
                  </Flex>
                </Col>

                <Col lg={6} xxl={6}>
                  <Flex
                    alignItems="center"
                    className={`px-2 ${
                      edit.profile ? 'py-x1' : 'py'
                    } position-relative`}
                  >
                    {edit.profile ? (
                      <Form.Group as={Col} lg={12} controlId="registerNumber">
                        <Form.Label>Бүртгэлийн дугаар</Form.Label>
                        <input
                          placeholder="Бүртгэлийн дугаар"
                          className="form-control"
                          name="registerNumber"
                          onChange={handleChange}
                          value={formData?.registerNumber || ''}
                        />
                      </Form.Group>
                    ) : (
                      <>
                        <div
                          className={`bg-orange p-2 text-dark bg-opacity-10 rounded`}
                        >
                          <div className="bg-soft-primary d-inline-block rounded py-1 text-warning px-2">
                            <FcInspection size={20} />
                          </div>
                        </div>

                        <div className="ms-3 my-x1">
                          <h5 className="fs-0 fw-semi-bold mb-2">
                            <div className="text-900">
                              {'Бүртгэлийн дугаар'}
                            </div>
                          </h5>
                          <h6 className="mb-0 text-600">
                            {formData?.registerNumber}
                          </h6>
                        </div>
                      </>
                    )}
                  </Flex>
                </Col>
                {edit.profile && (
                  <Col lg={12} xxl={12}>
                    <Flex
                      alignItems="center"
                      justifyContent="end"
                      className={`px-2 ${
                        edit.profile ? 'py-x1' : 'py'
                      } position-relative`}
                    >
                      <Button
                        variant="falcon-default"
                        className="me-2 mb-1"
                        onClick={() =>
                          setEdit({ ...edit, profile: !edit.profile })
                        }
                      >
                        Буцах
                      </Button>
                      <Button
                        variant="primary"
                        type="submit"
                        className="me-2 mb-1"
                        disabled={!observer}
                      >
                        Хадгалах
                      </Button>
                    </Flex>
                  </Col>
                )}
              </Row>
            </Form>
          </Card.Body>
        </Card>
        {/* address form */}
        <Card className="mt-3">
          <Card.Header className="bg-light d-flex justify-content-between align-items-md-center">
            <div>
              <h6 className="mb-0">Хаягийн мэдээлэл</h6>
            </div>
            <div>
              <button
                className="btn btn-link"
                onClick={() => setEdit({ ...edit, address: !edit.address })}
              >
                <h6 className="mb-0 text-primary">
                  {' '}
                  {edit.address ? 'Буцах' : updatedAuthorized ? '' : 'Засах'}
                </h6>
              </button>{' '}
            </div>
          </Card.Header>
          <Card.Body>
            <Form onSubmit={handleSubmitAddress}>
              <Row className="g-0">
                <Col lg={6} xxl={6}>
                  <Flex
                    alignItems="center"
                    className={`px-2 ${
                      edit.address ? 'py-x1' : 'py'
                    } position-relative`}
                  >
                    {edit.address ? (
                      <Form.Group as={Col} lg={12} controlId="country">
                        <Form.Label>Улс</Form.Label>
                        <input
                          placeholder="Улс"
                          className="form-control"
                          name="country"
                          onChange={addressHandleChange}
                          value={formAddress?.country || ''}
                        />
                      </Form.Group>
                    ) : (
                      <>
                        <div
                          className={`bg-orange p-2 text-dark bg-opacity-10 rounded`}
                        >
                          <div className="bg-soft-info d-inline-block rounded py-1 text-warning px-2">
                            <FcGlobe size={20} />
                          </div>
                        </div>

                        <div className="ms-3 my-x1">
                          <h5 className="fs-0 fw-semi-bold mb-2">
                            <div className="text-900">{'Улс'}</div>
                          </h5>
                          <h6 className="mb-0 text-600">
                            {formAddress?.country}
                          </h6>
                        </div>
                      </>
                    )}
                  </Flex>
                </Col>
                <Col lg={6} xxl={6}>
                  <Flex
                    alignItems="center"
                    className={`px-2 ${
                      edit.address ? 'py-x1' : 'py'
                    } position-relative`}
                  >
                    {edit.address ? (
                      <Form.Group as={Col} lg={12} controlId="city">
                        <Form.Label> Хот</Form.Label>
                        <input
                          placeholder="Хот"
                          className="form-control"
                          name="city"
                          onChange={addressHandleChange}
                          value={formAddress?.city || ''}
                        />
                      </Form.Group>
                    ) : (
                      <>
                        <div
                          className={`bg-orange p-2 text-dark bg-opacity-10 rounded`}
                        >
                          <div className="bg-soft-primary d-inline-block rounded py-1 text-warning px-2">
                            <FcOrganization size={20} />
                          </div>
                        </div>

                        <div className="ms-3 my-x1">
                          <h5 className="fs-0 fw-semi-bold mb-2">
                            <div className="text-900">{'Хот'}</div>
                          </h5>
                          <h6 className="mb-0 text-600">{formAddress?.city}</h6>
                        </div>
                      </>
                    )}
                  </Flex>
                </Col>
                <Col lg={6} xxl={6}>
                  <Flex
                    alignItems="center"
                    className={`px-2 ${
                      edit.address ? 'py-x1' : 'py'
                    } position-relative`}
                  >
                    {edit.address ? (
                      <Form.Group as={Col} lg={12} controlId="address">
                        <Form.Label>Хаяг</Form.Label>
                        <input
                          placeholder="Утас"
                          className="form-control"
                          name="address"
                          onChange={addressHandleChange}
                          value={formAddress?.address || ''}
                        />
                      </Form.Group>
                    ) : (
                      <>
                        <div
                          className={`bg-orange p-2 text-dark bg-opacity-10 rounded`}
                        >
                          <div className="bg-soft-info d-inline-block rounded py-1 text-warning px-2">
                            <FcHome size={20} />
                          </div>
                        </div>
                        <div className="ms-3 my-x1">
                          <h5 className="fs-0 fw-semi-bold mb-2">
                            <div className="text-900">{'Хаяг'}</div>
                          </h5>
                          <h6 className="mb-0 text-600">
                            {formAddress?.address}
                          </h6>
                        </div>
                      </>
                    )}
                  </Flex>
                </Col>
                <Col lg={6} xxl={6}>
                  <Flex
                    alignItems="center"
                    className={`px-2 ${
                      edit.address ? 'py-x1' : 'py'
                    } position-relative`}
                  >
                    {edit.address ? (
                      <Form.Group as={Col} lg={12} controlId="address">
                        <Form.Label>Имэйл</Form.Label>
                        <input
                          placeholder="Бизнес имэйл"
                          className="form-control"
                          value={formAddress?.address1}
                          name="address1"
                          onChange={addressHandleChange}
                        />
                      </Form.Group>
                    ) : (
                      <>
                        <div
                          className={`bg-orange p-2 text-dark bg-opacity-10 rounded`}
                        >
                          <div className="bg-soft-dark d-inline-block rounded py-1 text-warning px-2">
                            <FcHome size={20} />
                          </div>
                        </div>

                        <div className="ms-3 my-x1">
                          <h5 className="fs-0 fw-semi-bold mb-2">
                            <div className="text-900">{'Хаяг 1'}</div>
                          </h5>
                          <h6 className="mb-0 text-600">
                            {formAddress?.address1}
                          </h6>
                        </div>
                      </>
                    )}
                  </Flex>
                </Col>

                {edit.address && (
                  <Col lg={12} xxl={12}>
                    <Flex
                      alignItems="center"
                      justifyContent="end"
                      className={`px-2 ${
                        edit.address ? 'py-x1' : 'py'
                      } position-relative`}
                    >
                      <Button
                        variant="falcon-default"
                        className="me-2 mb-1"
                        onClick={() =>
                          setEdit({ ...edit, address: !edit.address })
                        }
                      >
                        Буцах
                      </Button>
                      <Button
                        variant="primary"
                        type="submit"
                        className="me-2 mb-1"
                        disabled={!observerAddress}
                      >
                        Хадгалах
                      </Button>
                    </Flex>
                  </Col>
                )}
              </Row>
            </Form>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default SettingsGemeral;
