import React, { useState, useEffect } from 'react';
import { Card, Dropdown } from 'react-bootstrap';
import SoftBadge from 'components/common/SoftBadge';
import CardDropdown from 'components/common/CardDropdown';
import Flex from 'components/common/Flex';
import Avatar from 'components/common/Avatar';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import CustomersTableHeader from './CustomersTableHeader';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import { toast } from 'react-toastify';
import {
  CUSTOMER_BULK_UPDATE,
  CUSTOMER_BULK_DELETE
} from '../../../lib/mutations';
import { CUSTOMER_LIST } from '../../../lib/query';
import { useQuery, useMutation } from '@apollo/client';
import usePermission from 'components/common/usePermission';

const Customers = () => {
  const { authorized: updatedAuthorized, styles: updatedStyles } =
    usePermission('U21');
  const { authorized: deleteAuthorized, styles: deleteStyles } =
    usePermission('U31');
  const [customerBulkUpdate, { data }] = useMutation(CUSTOMER_BULK_UPDATE);
  const [customerBulkDelete, { data: deletedData }] =
    useMutation(CUSTOMER_BULK_DELETE);
  const { data: getCustomers, refetch } = useQuery(CUSTOMER_LIST);
  const [customers, setCustomers] = useState([]);

  useEffect(() => {
    if (getCustomers?.users?.status?.code === 200) {
      setCustomers(getCustomers?.users?.data);
    }
  }, [getCustomers]);

  useEffect(() => {
    if (data) {
      if (data?.usersBulkUpdate) {
        refetch();

        toast.success(`Амжиллтай засварлалаа`, {
          theme: 'colored'
        });
      } else {
        toast.warning(`Ямар нэгэн алдаа гарлаа`, {
          theme: 'colored'
        });
      }
    }
    if (deletedData) {
      if (deletedData?.usersBulkDelete) {
        refetch();

        toast.success(`Амжиллтай устгагдлаа`, {
          theme: 'colored'
        });
      } else {
        toast.warning(`Ямар нэгэн алдаа гарлаа`, {
          theme: 'colored'
        });
      }
    }
  }, [data, deletedData]);

  const handleFieldChange = (value, blogId) => {
    if (value === 'delete') {
      customerBulkDelete({
        variables: {
          usersIds: [blogId]
        }
      });
    } else {
      customerBulkUpdate({
        variables: {
          actionType: value === 'active' ? true : false,
          usersIds: [blogId]
        }
      });
    }
  };

  const columns = [
    {
      accessor: 'firstName',
      Header: 'Нэр',
      headerProps: { className: 'pe-1' },
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        const { firstName, avatar } = rowData.row.original;
        return (
          <Flex alignItems="center">
            {avatar ? (
              <Avatar src={avatar?.url} size="xl" className="me-2" />
            ) : (
              <Avatar size="xl" name={avatar?.url} className="me-2" />
            )}
            <div className="flex-1">
              <h5 className="mb-0 fs--1">{firstName}</h5>
            </div>
          </Flex>
        );
      }
    },
    {
      accessor: 'lastName',
      Header: 'Овог',
      headerProps: { className: 'pe-7' },
      Cell: rowData => {
        const { lastName } = rowData.row.original;
        return <>{lastName}</>;
      }
    },
    {
      accessor: 'email',
      Header: 'Имэйл',
      headerProps: { className: 'pe-7' },
      Cell: rowData => {
        const { email } = rowData.row.original;
        return <>{email}</>;
        // return <a href={`mailto:${email}`}>{email}</a>;
      }
    },
    {
      accessor: 'phone',
      Header: 'Утас',
      headerProps: { className: 'pe-7' },
      Cell: rowData => {
        const { phone } = rowData.row.original;
        return <span className="text-primary">{phone}</span>;
        // return <a href={`tel:${phone}`}>{phone}</a>;
      }
    },
    {
      accessor: 'status',
      Header: 'Төлөв',
      headerProps: { className: 'pe-7' },
      Cell: rowData => {
        const { status } = rowData.row.original;
        return status ? (
          <SoftBadge pill bg="success" className="me-2">
            Идэвхтэй
          </SoftBadge>
        ) : (
          <SoftBadge pill bg="secondary" className="me-2">
            Идэвхгүй
          </SoftBadge>
        );
      }
    },
    {
      accessor: 'role',
      Header: 'Үүрэг',
      headerProps: { className: 'pe-7' },
      Cell: rowData => {
        const { role } = rowData.row.original;
        return <>{role?.value}</>;
      }
    },
    {
      accessor: 'date',
      Header: 'Огноо',
      headerProps: { className: 'pe-2' },
      Cell: rowData => {
        const { createdAt } = rowData.row.original;
        return <>{createdAt.split('T')[0]}</>;
      }
    },
    {
      accessor: 'none',
      Header: '',
      disableSortBy: true,
      cellProps: {
        className: 'text-end'
      },
      Cell: rowData => {
        const { _id: userId } = rowData.row.original;
        return (
          <CardDropdown>
            <div className="py-2">
              <Dropdown.Item
                onClick={() => handleFieldChange('active', userId)}
                style={updatedStyles}
                disabled={updatedAuthorized}
              >
                Нээх
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => handleFieldChange('close', userId)}
                style={updatedStyles}
                disabled={updatedAuthorized}
              >
                Хаах
              </Dropdown.Item>
              <Dropdown.Divider as="div" />
              <Dropdown.Item
                onClick={() => handleFieldChange('delete', userId)}
                className="text-danger"
                style={deleteStyles}
                disabled={deleteAuthorized}
              >
                Устгах
              </Dropdown.Item>
            </div>
          </CardDropdown>
        );
      }
    }
  ];

  return (
    <AdvanceTableWrapper
      columns={columns}
      data={customers}
      selection
      sortable
      pagination
      perPage={10}
    >
      <Card className="mb-3">
        <Card.Header>
          <CustomersTableHeader table customers={customers} refetch={refetch} />
        </Card.Header>
        <Card.Body className="p-0">
          <AdvanceTable
            table
            headerClassName="bg-200 text-900 text-nowrap align-middle"
            rowClassName="align-middle white-space-nowrap"
            tableProps={{
              size: 'sm',
              striped: true,
              className: 'fs--1 mb-0 overflow-hidden'
            }}
          />
        </Card.Body>
        <Card.Footer>
          <AdvanceTablePagination table />
        </Card.Footer>
      </Card>
    </AdvanceTableWrapper>
  );
};

export default Customers;
