export const dashboardRoutes = {
  label: 'Dashboard',
  labelDisable: true,
  children: [
    {
      name: 'Эхлэл',
      active: true,
      to: '/',
      icon: 'chart-pie',
      roles: ['Root', 'Admin', 'Publisher', 'Worker']
    }
  ]
};
export const appRoutes = {
  label: 'Үндсэн цэс',
  children: [
    {
      name: 'Компани',
      icon: 'file-alt',
      to: '/company/company-list',
      active: true,
      roles: ['Root']
    },
    {
      name: 'Мэдээлэл',
      icon: 'file-alt',
      to: '/blogs/blog-list',
      active: true,
      roles: ['Root', 'Admin', 'Publisher']
    },
    {
      name: 'Каталогиуд',
      icon: ['fab', 'trello'],
      to: '/product-catalog/product-list',
      active: false,
      roles: ['Root', 'Admin', 'Publisher', 'Worker']
    },
    {
      name: 'Зурвас',
      icon: 'envelope-open',
      to: '/message/message-list',
      active: false,
      roles: ['Root', 'Admin', 'Publisher', 'Worker']
    },
    {
      name: 'Контент',
      icon: 'poll',
      to: '/content/content-list',
      active: false,
      roles: ['Root', 'Admin', 'Publisher', 'Worker']
    },
    {
      name: 'Ажилтан',
      icon: 'user',
      to: '/customers/customer-list',
      active: false,
      roles: ['Root', 'Admin']
    },
    {
      name: 'Тохиргоо',
      icon: 'wrench',
      to: '/settings/general',
      active: false,
      roles: ['Root', 'Admin']
    }
  ]
};

export default [dashboardRoutes, appRoutes];
