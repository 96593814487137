import FalconCardHeader from 'components/common/FalconCardHeader';
import React, { useState, useEffect, useContext } from 'react';
import { Button, Card, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useMutation } from '@apollo/client';
import { CUSTOMER_CHANGE_PASSWORD } from '../../../../lib/mutations';
import { AuthWizardContext } from '../../../../context/Context';
import Cookies from 'js-cookie';

const ChangePassword = () => {
  const [userChangePassword, { data }] = useMutation(CUSTOMER_CHANGE_PASSWORD);
  const { user, setUser } = useContext(AuthWizardContext);
  const [formData, setFormData] = useState({
    oldPassword: '',
    newPassword: '',
    confirmPassword: ''
  });

  useEffect(() => {
    if (data) {
      if (data?.customerChangePassword?.status?.code === 200) {
        toast.success(`Таны нууц үг амжиллтай шинэчлэгдлээ`, {
          theme: 'colored'
        });
        Cookies.set('accessToken', data?.customerChangePassword?.token, {
          expires: 7
        });
        Cookies.set(
          'user',
          JSON.stringify(data?.customerChangePassword?.data),
          {
            expires: 7
          }
        );
        setUser(data?.customerChangePassword?.data);
      } else {
        toast.warning(`${data?.customerChangePassword?.status?.moreInfo}`, {
          theme: 'colored'
        });
      }
    }
  }, [data]);

  const handleChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = e => {
    e.preventDefault();
    if (formData.newPassword === formData.confirmPassword) {
      userChangePassword({
        variables: {
          newPassword: formData.newPassword.toString(),
          password: formData.oldPassword.toString(),
          userId: user._id.toString()
        }
      });
      setFormData({
        oldPassword: '',
        newPassword: '',
        confirmPassword: ''
      });
    } else {
      toast.warning(`Батлах нууц үг болон шинэ нууц үг таарахгүй байна`, {
        theme: 'colored'
      });
    }
  };

  return (
    <Card className="mb-3" style={{ zIndex: '0' }}>
      <FalconCardHeader title="Нууц үг шинэчлэх" />
      <Card.Body className="bg-light">
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="oldPassword">
            <Form.Label>Хуучин нууц үг</Form.Label>
            <Form.Control
              type="password"
              value={formData.oldPassword}
              name="oldPassword"
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="newPassword">
            <Form.Label>Шинэ нууц үг</Form.Label>
            <Form.Control
              type="password"
              value={formData.newPassword}
              name="newPassword"
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="confirmPassword">
            <Form.Label>Батлах нууц үг</Form.Label>
            <Form.Control
              type="password"
              value={formData.confirmPassword}
              name="confirmPassword"
              onChange={handleChange}
            />
          </Form.Group>
          <Button
            className="w-100"
            type="submit"
            disabled={
              !formData.oldPassword ||
              !formData.newPassword ||
              !formData.confirmPassword
            }
          >
            Нууц үг шинэчлэх
          </Button>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default ChangePassword;
