import { gql } from '@apollo/client';

export const CUSTOMER_UPDATE = gql`
  mutation CustomerUpdate($userId: String!, $input: UpdateUserInput!) {
    customerUpdate(userId: $userId, input: $input) {
      data {
        _id
        avatar {
          bucketname
          public_id
          url
          avatarImage
          isAvatarImageSet
        }
        createdAt
        email
        firstName
        lastName
        loggedIn
        password
        phone
        role {
          permission
          value
        }
        updatedAt
      }
      status {
        code
        description
        header
        moreInfo
      }
    }
  }
`;

export const CUSTOMER_LOGIN = gql`
  mutation CustomerLogin($input: LoginInput!) {
    customerLogin(input: $input) {
      data {
        _id
        avatar {
          bucketname
          public_id
          url
          avatarImage
          isAvatarImageSet
        }
        createdAt
        email
        firstName
        lastName
        loggedIn
        password
        phone
        role {
          permission
          value
        }
        updatedAt
      }
      status {
        code
        description
        header
        moreInfo
      }
      token
      company {
        _id
        address {
          address
          address1
          city
          country
        }
        payment {
          conditionPeriod
          payment
          paymently {
            description
            fromBankAccount
            isPaidMoney
            notificationPeriod
            paidmoney
            paymentActivationAt
            paymentActivationAtBy {
              _id
            }
            paymentAt
            paymentAtBy {
              _id
            }
            untilPeriod
          }
          status
        }
        companyId
        companyRegister
        companyType
        createdAt
        createdAtBy {
          _id
        }
        domain
        email
        logo {
          bucketname
          public_id
          url
        }
        name
        phone
        registerNumber
        tags
        updatedAt
        updatedAtBy {
          _id
        }
        workers {
          _id
        }
      }
    }
  }
`;

export const CUSTOMER_FORGOT_PASSWORD = gql`
  mutation CustomerForgotPassword($email: String!) {
    customerForgotPassword(email: $email) {
      data {
        _id
        createdAt
      }
      status {
        code
        description
        header
        moreInfo
      }
      token
    }
  }
`;

export const CUSTOMER_INVITE_ACTIVATION = gql`
  mutation CustomerInviteUserActivation($input: TokenInviteInput!) {
    customerInviteUserActivation(input: $input) {
      data {
        _id
        avatar {
          avatarImage
          bucketname
          isAvatarImageSet
          public_id
          url
        }
        createdAt
        email
        firstName
        lastName
        loggedIn
        phone
        role {
          permission
          value
        }
        updatedAt
      }
      token
      status {
        code
        description
        header
        moreInfo
      }
    }
  }
`;

export const CUSTOMER_INVITE_SET_PASSWORD = gql`
  mutation CustomerInviteByEmail(
    $inviteEmail: String!
    $input: SetInviteUserInput!
  ) {
    customerInviteByEmail(inviteEmail: $inviteEmail, input: $input) {
      data {
        _id
      }
      status {
        code
        description
        header
        moreInfo
      }
    }
  }
`;

export const CUSTOMER_RESET_PASSWORD = gql`
  mutation CustomerResetPassword($newPassword: String!, $resetToken: String!) {
    customerResetPassword(newPassword: $newPassword, resetToken: $resetToken) {
      data {
        _id
        createdAt
        email
        firstName
        lastName
        loggedIn
        phone
        resetPasswordExpire
        resetPasswordToken
        role {
          value
          permission
        }
        updatedAt
      }
      status {
        code
        description
        header
        moreInfo
      }
      token
    }
  }
`;

export const BLOG_POST_DATA = gql`
  mutation BlogCreate($input: CreateBlogInput!) {
    blogCreate(input: $input) {
      data {
        _id
        category
        createdAt
        createdAtBy {
          _id
          avatar {
            bucketname
            public_id
            url
          }
          createdAt
          email
          firstName
          lastName
          loggedIn
        }
        description
        image {
          bucketname
          url
        }
        publishedAtBy {
          _id
        }
        readCount
        slug
        status
        tags
        title
        updatedAt
        updatedAtBy {
          _id
        }
      }
      status {
        code
        description
        header
        moreInfo
      }
    }
  }
`;

export const BLOG_BY_ID = gql`
  query BlogById($blogId: String!) {
    blogById(blogId: $blogId) {
      status {
        code
        description
        header
        moreInfo
      }
      data {
        _id
        category
        createdAt
        createdAtBy {
          _id
        }
        description
        image {
          bucketname
          public_id
          url
        }
        publishedAtBy {
          _id
        }
        readCount
        slug
        status
        tags
        title
        updatedAt
        updatedAtBy {
          _id
        }
      }
    }
  }
`;

export const BLOG_POST_UPDATE = gql`
  mutation BlogUpdate($blogId: String!, $input: UpdateBlogInput!) {
    blogUpdate(blogId: $blogId, input: $input) {
      data {
        _id
        category
        createdAt
        createdAtBy {
          _id
        }
        description
        image {
          bucketname
          public_id
          url
        }
        publishedAtBy {
          _id
        }
        readCount
        slug
        status
        tags
        title
        updatedAt
        updatedAtBy {
          _id
        }
      }
      status {
        code
        description
        header
        moreInfo
      }
    }
  }
`;

export const COMPANY_SEND_INVOICE = gql`
  mutation Mutation {
    companyCheckNotification
  }
`;

export const BLOG_BULK_UPDATE = gql`
  mutation BlogBulkUpdate($actionType: Boolean!, $blogIds: [String!]!) {
    blogBulkUpdate(actionType: $actionType, blogIds: $blogIds)
  }
`;

export const BLOG_BULK_DELETE = gql`
  mutation BlogBulkDelete($blogIds: [String!]!) {
    blogBulkDelete(blogIds: $blogIds)
  }
`;

export const PRODUCT_CATALOG_CREATE = gql`
  mutation ProductCatalogCreate($input: CreateProductInput!) {
    productCatalogCreate(input: $input) {
      data {
        _id
        category
        company {
          _id
        }
        createdAt
        createdAtBy {
          _id
        }
        description
        tags
        image {
          bucketname
          public_id
          url
        }
        name
        price
        publishedAtBy {
          _id
        }
        quantity
        updatedAt
      }
      status {
        code
        description
        header
        moreInfo
      }
    }
  }
`;

export const PRODUCT_CATALOG_UPDATE = gql`
  mutation ProductCatalogUpdate(
    $productId: String!
    $input: UpdateProductInput!
  ) {
    productCatalogUpdate(productId: $productId, input: $input) {
      data {
        _id
        category
        company {
          _id
        }
        createdAt
        createdAtBy {
          _id
        }
        description
        tags
        image {
          bucketname
          public_id
          url
        }
        name
        price
        publishedAtBy {
          _id
        }
        quantity
        status
        updatedAt
      }
      status {
        code
        description
        header
        moreInfo
      }
    }
  }
`;

export const PRODUCT_CATALOG_BY_ID = gql`
  query ProductCatalogById($productId: String!) {
    productCatalogById(productId: $productId) {
      data {
        _id
        category
        company {
          _id
        }
        createdAt
        createdAtBy {
          _id
        }
        description
        image {
          bucketname
          public_id
          url
        }
        name
        price
        publishedAtBy {
          _id
        }
        tags
        quantity
        status
        updatedAt
      }
      status {
        code
        description
        header
        moreInfo
      }
    }
  }
`;

export const PRODUCT_CATALOG_BULK_UPDATE = gql`
  mutation ProductCatalogsBulkUpdate(
    $actionType: Boolean!
    $productCatalogIds: [String!]!
  ) {
    productCatalogsBulkUpdate(
      actionType: $actionType
      productCatalogIds: $productCatalogIds
    )
  }
`;

export const PRODUCT_CATALOG_BULK_DELETE = gql`
  mutation Mutation($productCatalogIds: [String!]!) {
    productCatalogBulkDelete(productCatalogIds: $productCatalogIds)
  }
`;

export const MESSAGE_BULK_UPDATE = gql`
  mutation MessageBulkUpdate($actionType: Boolean!, $messageIds: [String!]!) {
    messageBulkUpdate(actionType: $actionType, messageIds: $messageIds)
  }
`;

export const MESSAGE_BULK_DELETE = gql`
  mutation MessageBulkDelete($messageIds: [String!]!) {
    messageBulkDelete(messageIds: $messageIds)
  }
`;

export const CUSTOMER_CHANGE_PASSWORD = gql`
  mutation CustomerChangePassword(
    $newPassword: String!
    $password: String!
    $userId: String!
  ) {
    customerChangePassword(
      newPassword: $newPassword
      password: $password
      userId: $userId
    ) {
      data {
        _id
        avatar {
          avatarImage
          bucketname
          isAvatarImageSet
          public_id
          url
        }
        createdAt
        email
        firstName
        lastName
        loggedIn
        phone
        role {
          permission
          value
        }
        updatedAt
      }
      status {
        code
        description
        moreInfo
        header
      }
      token
    }
  }
`;

export const UPDATE_BY_TEXT_TEXTURE = gql`
  mutation TextureBulkUpdateByText(
    $value: String!
    $key: String!
    $textureId: String!
  ) {
    textureBulkUpdateByText(value: $value, key: $key, textureId: $textureId)
  }
`;

export const CREATE_COMPANY = gql`
  mutation CompanyCreate($input: CreateCompanyInput!) {
    companyCreate(input: $input) {
      data {
        _id
        address {
          address
          address1
          city
          country
        }
        companyId
        companyRegister
        createdAt
        domain
        email
        name
        phone
        registerNumber
        updatedAt
        workers {
          _id
        }
      }
      status {
        code
        description
        header
        moreInfo
      }
    }
  }
`;

export const COMPANY_CREATE = gql`
  mutation CompanyCreate($input: CreateCompanyInput!) {
    companyCreate(input: $input) {
      data {
        _id
        address {
          address
          address1
          city
          country
        }
        companyId
        companyRegister
        companyType
        logo {
          bucketname
          public_id
          url
        }
        createdAt
        domain
        email
        name
        phone
        registerNumber
        updatedAt
        workers {
          _id
        }
      }
      status {
        code
        description
        header
        moreInfo
      }
    }
  }
`;

export const COMPANY_UPDATE = gql`
  mutation CompanyUpdate($companyId: String!, $input: UpdateCompanyInput!) {
    companyUpdate(companyId: $companyId, input: $input) {
      data {
        _id
        address {
          address
          address1
          city
          country
        }
        companyRegister
        companyType
        createdAt
        domain
        email
        logo {
          bucketname
          public_id
          url
        }
        name
        phone
        registerNumber
        tags
        updatedAt
        workers {
          _id
        }
      }
      status {
        code
        description
        header
        moreInfo
      }
    }
  }
`;

export const CONTENT_BULK_CREATE = gql`
  mutation TextureCreateBulk($input: [CreateTextureInput!]!) {
    textureCreateBulk(input: $input)
  }
`;

export const COMPANY_UPDATE_PROFILE = gql`
  mutation CompanyUpdate($companyId: String!, $input: UpdateCompanyInput!) {
    companyUpdate(companyId: $companyId, input: $input) {
      data {
        _id
        address {
          address
          address1
          city
          country
        }
        companyId
        companyRegister
        companyType
        createdAt
        createdAtBy {
          _id
        }
        domain
        email
        logo {
          bucketname
          public_id
          url
        }
        name
        phone
        registerNumber
        tags
        updatedAt
        updatedAtBy {
          _id
        }
        workers {
          _id
        }
      }
      status {
        code
        description
        header
        moreInfo
      }
    }
  }
`;

export const COMPANY_UPDATE_WITHOUT_PAYMENT = gql`
  mutation Mutation(
    $companyId: String!
    $input: UpdateCompanyWithoutPaymentInput!
  ) {
    companyUpdateWithoutPayment(companyId: $companyId, input: $input) {
      status {
        code
        description
        header
        moreInfo
      }
      data {
        _id
        accessToken
        accessTokenExpire
        address {
          address
          address1
          city
          country
        }
        companyId
        companyRegister
        companyType
        createdAt
        createdAtBy {
          _id
        }
        domain
        email
        handle
        languages
        logo {
          bucketname
          public_id
          url
        }
        name
        payment {
          conditionPeriod
          payment
          paymently {
            description
            fromBankAccount
            isPaidMoney
            notificationPeriod
            paidmoney
            paymentActivationAt
            paymentActivationAtBy {
              _id
            }
            paymentAt
            paymentAtBy {
              _id
            }
            untilPeriod
          }
          status
        }
        phone
        policy
        registerNumber
        status
        tags
        updatedAt
        updatedAtBy {
          _id
        }
        workers {
          _id
        }
      }
    }
  }
`;

export const COMPANY_CREATE_TOKEN = gql`
  mutation CompanyCreateToken {
    companyCreateToken {
      status {
        code
        description
        header
        moreInfo
      }
      data {
        _id
        accessToken
      }
    }
  }
`;

export const COMPANY_DOMAIN_ADD = gql`
  mutation CompanyDomainAdd($input: UpdateCompanyDomainAddInput!) {
    companyDomainAdd(input: $input) {
      data {
        _id
        address {
          address
          address1
          city
          country
        }
        companyId
        companyRegister
        companyType
        createdAt
        createdAtBy {
          _id
        }
        domain
        email
        logo {
          bucketname
          public_id
          url
        }
        name
        phone
        registerNumber
        tags
        updatedAt
        updatedAtBy {
          _id
        }
        workers {
          _id
        }
      }
      status {
        code
        description
        header
        moreInfo
      }
    }
  }
`;

export const CUSTOMER_BULK_UPDATE = gql`
  mutation UsersBulkUpdate($actionType: Boolean!, $usersIds: [String!]!) {
    usersBulkUpdate(actionType: $actionType, usersIds: $usersIds)
  }
`;

export const CUSTOMER_BULK_DELETE = gql`
  mutation UsersBulkDelete($usersIds: [String!]!) {
    usersBulkDelete(usersIds: $usersIds)
  }
`;

export const COMPANY_ADD_POLICY = gql`
  mutation CompanyAddPolicy($companyId: String!, $policy: String!) {
    companyAddPolicy(companyId: $companyId, policy: $policy) {
      data {
        _id
      }
      status {
        header
        code
        description
        moreInfo
      }
    }
  }
`;

export const CUSTOMER_UPDATE_ROLE_AND_PERMISSION = gql`
  mutation CustomerUpdateRoleAndPermission(
    $input: UpdateRoleAndPermissionInput!
    $userId: String!
  ) {
    customerUpdateRoleAndPermission(input: $input, userId: $userId) {
      data {
        _id
        avatar {
          avatarImage
          bucketname
          isAvatarImageSet
          public_id
          url
        }
        companyId
        createdAt
        email
        firstName
        lastName
        role {
          owner
          permission
          value
        }
        status
        updatedAt
      }
      status {
        code
        description
        header
        moreInfo
      }
    }
  }
`;

export const NOTIFICATION_BULK_UPDATE = gql`
  mutation Mutation($notificationIds: [String!]!) {
    notificationBulkUpdate(notificationIds: $notificationIds)
  }
`;

export const COMPANY_BULK_UPDATE = gql`
  mutation Mutation($actionType: Boolean!, $companyIds: [String!]!) {
    companyBulkUpdate(actionType: $actionType, companyIds: $companyIds)
  }
`;

export const COMPANY_BULK_DELETE = gql`
  mutation CompanyBulkDelete($companyIds: [String!]!) {
    companyBulkDelete(companyIds: $companyIds)
  }
`;

export const COMPANY_PAYMENT_UPDATE = gql`
  mutation Mutation($companyId: String!, $input: SetPaymentInput!) {
    companySetPayment(companyId: $companyId, input: $input) {
      status {
        code
        description
        header
        moreInfo
      }
      data {
        _id
        accessToken
        accessTokenExpire
        address {
          address
          address1
          city
          country
        }
        companyId
        companyRegister
        companyType
        createdAt
        createdAtBy {
          _id
        }
        domain
        email
        handle
        languages
        logo {
          bucketname
          public_id
          url
        }
        name
        payment {
          conditionPeriod
          payment
          paymently {
            description
            fromBankAccount
            isPaidMoney
            notificationPeriod
            paidmoney
            paymentActivationAt
            paymentActivationAtBy {
              _id
            }
            paymentAt
            paymentAtBy {
              _id
            }
            untilPeriod
          }
          status
        }
        phone
        policy
        registerNumber
        status
        tags
        updatedAt
        updatedAtBy {
          _id
        }
        workers {
          _id
        }
      }
    }
  }
`;

export const COMPANY_COMPLETE_PAYMENT = gql`
  mutation CompanyPaymentCompleted(
    $companyId: String!
    $input: CompletedPaymentInput!
  ) {
    companyPaymentCompleted(companyId: $companyId, input: $input) {
      data {
        _id
        accessToken
        accessTokenExpire
        address {
          address
          address1
          city
          country
        }
        companyId
        companyRegister
        companyType
        createdAt
        createdAtBy {
          _id
        }
        domain
        email
        handle
        languages
        logo {
          bucketname
          public_id
          url
        }
        name
        payment {
          conditionPeriod
          payment
          paymently {
            description
            fromBankAccount
            isPaidMoney
            notificationPeriod
            paidmoney
            paymentActivationAt
            paymentActivationAtBy {
              _id
            }
            paymentAt
            paymentAtBy {
              _id
            }
            untilPeriod
          }
          status
        }
        phone
        policy
        registerNumber
        status
        tags
        updatedAt
        updatedAtBy {
          _id
        }
        workers {
          _id
        }
      }
      status {
        code
        description
        header
        moreInfo
      }
    }
  }
`;
