import React from 'react';
import { Card, Col, Button, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import usePermission from 'components/common/usePermission';

const ProductCatalogHeader = () => {
  const { authorized, styles } = usePermission('P11');

  return (
    <Card>
      <Card.Body>
        <Row className="flex-between-center">
          <Col md>
            <h5 className="mb-2 mb-md-0">Бүтээгдэхүүний каталоги</h5>
          </Col>
          <Col xs="auto">
            <Button
              variant="link"
              className="text-secondary fw-medium p-0 me-3"
              type="button"
            >
              цуцлах
            </Button>
            <Button
              as={Link}
              to="/product-catalog/new-product"
              variant="primary"
              size="sm"
              className="product_nemeh"
              style={styles}
              disabled={authorized}
            >
              Каталог нэмэх
            </Button>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default ProductCatalogHeader;
