import { useContext, useEffect, useState } from 'react';
import { AuthWizardContext } from '../../context/Context';

const usePermission = target => {
  const { user: getUser } = useContext(AuthWizardContext);
  const [user, setUser] = useState();

  useEffect(() => setUser(getUser), [getUser]);

  if (!user || !user.role) {
    return {
      isResult: false,
      styles: { cursor: 'not-allowed', opacity: '0.5' }
    };
  }
  const permission = user.role.permission;
  const one = target.charAt(0);
  const two = target.charAt(1);
  const three = target.charAt(2);
  const firstCharacters = permission
    .filter(str => str && str.startsWith(one) && str.includes('W'))
    .map(str => str && str.split('W')[1])[0];
  const styles = { cursor: 'not-allowed', opacity: '0.5' };

  if (!firstCharacters) {
    return { authorized: false, styles };
  }
  const result = firstCharacters.charAt(two) === three;
  return { authorized: !result, styles: !result ? styles : undefined };
};

export default usePermission;
