import React, { useEffect, useState } from 'react';
import WeeklySales from './WeeklySales';
import { Row, Col } from 'react-bootstrap';
// import { AuthWizardContext } from '../../../context/Context';
import TotalOrder from './TotalOrder';
import MarketShare from './MarketShare';
import DeviceAnalytics from './DeviceAnalytics';
import RunningProjects from './RunningProjects';
import UseragentProjects from './UseragentProjects';
import { USER_LOG_ALL, USER_AGENT_ALL } from '../../../lib/query';
import { useQuery } from '@apollo/client';

const Dashboard = () => {
  // /const { user } = useContext(AuthWizardContext);
  const { data: getAgents } = useQuery(USER_AGENT_ALL);
  const [filterAction, setFilterAction] = useState('all');
  const { data: getUserLogs, refetch: reCallUserData } = useQuery(
    USER_LOG_ALL,
    {
      variables: {
        action: filterAction
      },
      fetchPolicy: 'no-cache'
    }
  );
  const [visitorAgent, setVisitorAgent] = useState({});
  const [userLogs, setUserLogs] = useState([]);

  useEffect(() => {
    if (getUserLogs && getUserLogs?.userlogAll.length > 0) {
      setUserLogs(getUserLogs?.userlogAll);
    } else {
      setUserLogs([]);
    }

    if (getAgents && getAgents?.visitorAll) {
      setVisitorAgent(getAgents?.visitorAll);
    } else {
      setVisitorAgent([]);
    }
  }, [getUserLogs, getAgents]);

  useEffect(() => {
    reCallUserData();
  }, [filterAction]);

  return (
    <>
      <Row className="g-3 mb-3">
        <Col md={6} xxl={3}>
          <WeeklySales totalVisitors={visitorAgent?.totalVisitors} />
        </Col>
        <Col md={6} xxl={3}>
          <TotalOrder todayVisitors={visitorAgent?.todayVisitors} />
        </Col>
        <Col md={6} xxl={3}>
          <MarketShare
            browserAnalytics={visitorAgent?.browserAnalytics}
            radius={['100%', '87%']}
          />
        </Col>
        <Col md={6} xxl={3}>
          <DeviceAnalytics
            deviceAnalytics={visitorAgent && visitorAgent?.deviceAnalytics}
            radius={['100%', '87%']}
          />
        </Col>
      </Row>

      <Row className="g-3 mb-3">
        <Col lg={6}>
          <RunningProjects
            userLogs={userLogs}
            setFilterAction={setFilterAction}
          />
        </Col>
        <Col lg={6}>
          <UseragentProjects visitors={visitorAgent?.visitors} />
        </Col>
      </Row>

      {/* <Row className="g-3 mb-3">
        <Col lg={6} xl={7} xxl={8}>
          <StorageStatus className="h-lg-100" data={storageStatus} />
        </Col>
        <Col lg={6} xl={5} xxl={4}>
          <SpaceWarning />
        </Col>
      </Row> */}
    </>
  );
};

export default Dashboard;
