import React from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';

const CompanyAddress = () => {
  const {
    register,
    formState: { errors }
  } = useFormContext();

  return (
    <Card className="mb-3">
      <Card.Header as="h6" className="bg-light">
        Байгууллагын хаяг
      </Card.Header>
      <Card.Body>
        <Row className="gx-2 gy-3">
          <Col md="12">
            <Form.Group>
              <Form.Label>Улс:</Form.Label>
              <Form.Control
                type="text"
                isInvalid={!!errors.country}
                {...register('country')}
              />
              <Form.Control.Feedback type="invalid">
                {errors.country?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col md="12">
            <Form.Group>
              <Form.Label>Хот:</Form.Label>
              <Form.Control
                type="text"
                isInvalid={!!errors.city}
                {...register('city')}
              />
              <Form.Control.Feedback type="invalid">
                {errors.city?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col md="12">
            <Form.Group>
              <Form.Label>Дүүрэг, Хороо, Сум</Form.Label>
              <Form.Control
                type="text"
                isInvalid={!!errors.address}
                {...register('address')}
              />
              <Form.Control.Feedback type="invalid">
                {errors.address?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col md="12">
            <Form.Group>
              <Form.Label>Хаяг</Form.Label>
              <Form.Control
                type="text"
                isInvalid={!!errors.address1}
                {...register('address1')}
              />
              <Form.Control.Feedback type="invalid">
                {errors.address1?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default CompanyAddress;
