import React from 'react';
import { Col, Row } from 'react-bootstrap';
import ProfileSettings from './ProfileSettings';
import ChangePassword from './ChangePassword';

const Settings = () => {
  return (
    <>
      <Row className="g-3">
        <Col lg={8}>
          <ProfileSettings />
        </Col>
        <Col lg={4}>
          <div className="sticky-sidebar mb-lg-4" style={{ zIndex: '0' }}>
            <ChangePassword />
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Settings;
