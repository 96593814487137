export const courseFilters = [
  {
    label: 'Category',
    options: [
      {
        label: 'Байгууллагын мэдээлэл',
        icon: 'file-alt',
        type: 'checkbox',
        value: 'free course',
        name: 'free course',
        to: '/settings/general'
      },
      {
        label: 'Хэрэглэгчид ба зөвшөөрөл',
        icon: 'paperclip',
        type: 'checkbox',
        value: 'paid course',
        name: 'paid course',
        to: '/settings/account'
      },
      {
        label: 'Нууцлал, Аюулгүй байдал',
        icon: 'tags',
        iconShrink: true,
        type: 'checkbox',
        value: 'on sale',
        name: 'on sale',
        to: '/settings/customer_accounts'
      },
      {
        label: 'Домэйн',
        icon: 'globe',
        iconShrink: true,
        type: 'checkbox',
        value: 'on sale',
        name: 'on sale',
        to: '/settings/domains'
      },
      {
        label: 'Төлбөр',
        icon: 'file-invoice-dollar',
        iconShrink: true,
        type: 'checkbox',
        value: 'on sale',
        name: 'on sale',
        to: '/settings/payment'
      },
      {
        label: 'Хэлнүүд',
        icon: 'book',
        iconShrink: true,
        type: 'checkbox',
        value: 'on sale',
        name: 'on sale',
        to: '/settings/languages'
      },
      {
        label: 'Бодлого',
        icon: 'brush',
        iconShrink: true,
        type: 'checkbox',
        value: 'on sale',
        name: 'on sale',
        to: '/settings/legal'
      }
    ]
  }
];
