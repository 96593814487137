import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Form } from 'react-bootstrap';
import { useMutation } from '@apollo/client';
import { CUSTOMER_FORGOT_PASSWORD } from '../../lib/mutations';

const ForgetPasswordForm = () => {
  const navigage = useNavigate();
  const [forgetPassword, { data }] = useMutation(CUSTOMER_FORGOT_PASSWORD);
  // State
  const [email, setEmail] = useState('');

  useEffect(() => {
    if (data) {
      if (data?.customerForgotPassword?.status?.code === 200) {
        toast.success(
          `Амжиллтай илгээлээ та өөрийн бүртгэлтэй ${email} имэйл хаягаа шалгана уу`,
          {
            theme: 'colored'
          }
        );
        setTimeout(() => {
          navigage('/authentication/card/login');
        }, 3000);
      } else {
        toast.warning(`${data?.customerLogin?.status?.moreInfo}`, {
          theme: 'colored'
        });
      }
    }
  }, [data]);

  // Handler
  const handleSubmit = e => {
    e.preventDefault();

    forgetPassword({
      variables: {
        email: email.toString()
      }
    });
  };

  return (
    <Form className="mt-4" onSubmit={handleSubmit}>
      <Form.Group className="mb-3">
        <Form.Control
          placeholder={'Имэйл хаяг'}
          value={email}
          name="email"
          onChange={({ target }) => setEmail(target.value)}
          type="email"
        />
      </Form.Group>

      <Form.Group className="mb-3">
        <Button className="w-100" type="submit" disabled={!email}>
          Илгээх
        </Button>
      </Form.Group>

      <Link className="fs--1 text-600" to="/authentication/card/login">
        Хэрэв нууц үгээ санасан бол
        <span className="d-inline-block ms-1"> &rarr;</span>
      </Link>
    </Form>
  );
};

ForgetPasswordForm.propTypes = {
  layout: PropTypes.string
};

ForgetPasswordForm.defaultProps = { layout: 'simple' };

export default ForgetPasswordForm;
