// import FalconCardFooterLink from 'components/common/FalconCardFooterLink';
import FalconCardHeader from 'components/common/FalconCardHeader';
import CampaignChart from './CampaignChart';
import PropTypes from 'prop-types';
import PerfomanceTable from './PerfomanceTable';
import React from 'react';
import { getColor } from 'helpers/utils';
import { Card, Col, Form, Row } from 'react-bootstrap';

function allCountActionCollectByDate(obj, property) {
  const resultArray = Object.entries(obj).map(([date, actions]) => ({
    date,
    ...actions
  }));
  const getPropertyValues = resultArray.map(item => item[property]);
  return getPropertyValues;
}

function allCountAction(obj) {
  const resultArray = Object.entries(obj).map(([date, actions]) => ({
    date,
    ...actions
  }));
  const totalCounts = resultArray.reduce(
    (counts, obj) => {
      counts.createdAllCount += obj.created;
      counts.updatedAllCount += obj.updated;
      counts.deletedAllCount += obj.deleted;
      // Add other properties here if needed
      return counts;
    },
    { createdAllCount: 0, updatedAllCount: 0, deletedAllCount: 0 }
  );
  return totalCounts;
}

function runningProjectState(data) {
  let actionCounts = {};

  const currentDate = new Date();
  const fiveDaysAgo = new Date();
  fiveDaysAgo.setDate(currentDate.getDate() - 4); // Subtract 4 to account for the current day

  data &&
    data.forEach(item => {
      const createdAt = new Date(item.createdAt).toDateString();

      if (
        new Date(item.createdAt) >= fiveDaysAgo &&
        new Date(item.createdAt) <= currentDate
      ) {
        if (!Object.prototype.hasOwnProperty.call(actionCounts, createdAt)) {
          actionCounts[createdAt] = { created: 0, updated: 0, deleted: 0 };
        }

        if (item.action === 'created') {
          actionCounts[createdAt].created++;
        }
        if (item.action === 'updated') {
          actionCounts[createdAt].updated++;
        }
        if (item.action === 'deleted') {
          actionCounts[createdAt].deleted++;
        }
      }
    });
  return actionCounts;
}

const RunningProjects = ({ userLogs, setFilterAction }) => {
  return (
    <Card>
      <Card className="h-100">
        <FalconCardHeader
          title="Хэрэглэгчийн үйлдэл"
          light
          titleTag="h6"
          endEl={
            <Form.Select
              size="sm"
              className="me-2"
              onChange={e => setFilterAction(e.target.value)}
            >
              <option value="all">Шүүлтүүр</option>
              <option value="created">Үүсгэсэн</option>
              <option value="updated">Засварласан</option>
              <option value="deleted">Устгасан</option>
            </Form.Select>
          }
        />
        <Card.Body className="pb-0">
          <Row>
            <Col xs={4}>
              <h6 className="text-700">Үүсгэсэн</h6>
              <h3 className="fw-normal text-700">
                {allCountAction(runningProjectState(userLogs)).createdAllCount}
              </h3>
              <CampaignChart
                color={getColor('success')}
                data={allCountActionCollectByDate(
                  runningProjectState(userLogs),
                  'created'
                )}
              />
            </Col>
            <Col xs={4}>
              <h6 className="text-700">Засварласан</h6>
              <h3 className="fw-normal text-700">
                {' '}
                {allCountAction(runningProjectState(userLogs)).updatedAllCount}
              </h3>
              <CampaignChart
                color={getColor('primary')}
                data={allCountActionCollectByDate(
                  runningProjectState(userLogs),
                  'updated'
                )}
              />
            </Col>
            <Col xs={4}>
              <h6 className="text-700">Устгасан</h6>
              <h3 className="fw-normal text-700">
                {allCountAction(runningProjectState(userLogs)).deletedAllCount}
              </h3>
              <CampaignChart
                color={getColor('danger')}
                data={allCountActionCollectByDate(
                  runningProjectState(userLogs),
                  'deleted'
                )}
              />
            </Col>
          </Row>
          <div className="mx-ncard">
            <PerfomanceTable data={userLogs} />
          </div>
        </Card.Body>
      </Card>
      {/* 
      <FalconCardFooterLink title="Цааш үзэх" size="sm" /> */}
    </Card>
  );
};

RunningProjects.propTypes = {
  userLogs: PropTypes.array,
  setFilterAction: PropTypes.func
};

export default RunningProjects;
