import { useQuery } from '@apollo/client';
import { COMPANY_TYPE } from '../../../../lib/query';
import React, { useState, useEffect } from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { Link } from 'react-router-dom';

const CompanyType = () => {
  const { loading, error, data: getCategories } = useQuery(COMPANY_TYPE);
  const [categories, setCategories] = useState([]);
  const {
    register,
    formState: { errors }
  } = useFormContext();

  const [addCategory, setAddCategory] = useState(false);
  const [newCategory, setNewCategory] = useState('');

  useEffect(() => {
    if (getCategories && getCategories.companyTypes) {
      setCategories(getCategories?.companyTypes);
    }
  }, [getCategories]);

  const handleFieldChange = e => {
    setNewCategory(e.target.value);
  };

  const handleAddCategory = () => {
    if (newCategory) {
      setCategories([...categories, newCategory]);
      setNewCategory('');
    }
  };

  if (loading) return 'Loading...';
  if (error) return `Error! ${error.message}`;

  return (
    <Card className="mb-3">
      <Card.Header as="h6" className="bg-light">
        Байгууллагын төрөл
      </Card.Header>
      <Card.Body>
        <Row className="gx-2 gy-3">
          <Col md="12">
            <Form.Group>
              <Form.Label>Төрөл сонгох</Form.Label>
              <Form.Select
                {...register(`companyType`)}
                isInvalid={!!errors.companyType}
              >
                <option value="">сонголт</option>
                {categories.map((category, i) => (
                  <option key={i} value={category}>
                    {category}
                  </option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errors.companyType?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md="12" style={{ textAlign: 'right' }}>
            <Link onClick={() => setAddCategory(!addCategory)}>
              <h6 className="text-primary">Төрөл нэмэх</h6>
            </Link>
            {addCategory && (
              <Form.Group>
                <Form.Control
                  value={newCategory}
                  name="newCategory"
                  onChange={handleFieldChange}
                  type="text"
                  placeholder="Төрөл нэмэх"
                  onBlur={handleAddCategory}
                />
                {errors.additionalCategory && (
                  <Form.Control.Feedback type="invalid">
                    {errors.additionalCategory.message}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            )}
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default CompanyType;
