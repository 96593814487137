import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import { PieChart } from 'echarts/charts';
import * as echarts from 'echarts/core';
import { getColor } from 'helpers/utils';
import PropTypes from 'prop-types';
import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';

import BasicECharts from 'components/common/BasicEChart';
import {
  GridComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  PieChart,
  CanvasRenderer
]);

const getOptions = (data, radius, total) => {
  const color = data ? data.map(item => getColor(item.color)) : [];

  return {
    color,
    tooltip: {
      padding: [7, 10],
      transitionDuration: 0,
      backgroundColor: getColor('gray-100'),
      borderColor: getColor('gray-300'),
      textStyle: { color: getColor('dark') },
      borderWidth: 1,
      formatter: params =>
        `<strong>${params.data.name}:</strong> ${params.percent}%`
    },

    series: [
      {
        name: total + ' хөтөч',
        type: 'pie',
        radius,
        avoidLabelOverlap: false,
        emphasis: {
          scale: false
        },
        itemStyle: {
          borderWidth: 2,
          borderColor: getColor('gray-100')
        },
        label: {
          show: true,
          position: 'center',
          formatter: '{a}',
          fontSize: 14,
          color: getColor('dark')
        },
        data
      }
    ]
  };
};

function selectColor(browser) {
  switch (browser) {
    case 'Windows':
      return 'success';
    case 'Apple':
      return 'info';
    case 'Apple/Macintosh':
      return 'danger';
    case 'Android':
      return 'primary';
    case 'Linux':
      return 'warning';
    case 'LG':
      return '700';
    default:
      return 'secondary';
  }
}

const DeviceAnalyticItem = ({ item, index, total }) => {
  const { device } = item;
  return (
    <Flex
      alignItems="center"
      justifyContent="between"
      className={`fw-semi-bold fs--2 ${index == 0 && 'mt-3'}`}
    >
      <p className="mb-1">
        <FontAwesomeIcon
          icon="circle"
          className={`me-2 text-${selectColor(device)}`}
        />
        {device}
      </p>
      <div className="d-xxl-none">{total}%</div>
    </Flex>
  );
};

const DeviceAnalytics = ({ deviceAnalytics, radius }) => {
  const newData = deviceAnalytics && deviceAnalytics;
  const total = newData && newData.reduce((acc, val) => val?.count + acc, 0);
  const basicChartData =
    newData &&
    newData.map((value, index) => {
      const converted = value?.device;
      return {
        id: index,
        value: value?.count,
        name: converted,
        color: selectColor(converted)
      };
    });

  const uniqueData =
    basicChartData &&
    basicChartData.reduce(
      (acc, current) => {
        if (
          current.name &&
          current.name !== 'undefined' &&
          !acc.names.includes(current.name)
        ) {
          acc.names.push(current.name);
          acc.result.push(current);
        }
        return acc;
      },
      { names: [], result: [] }
    ).result;

  return (
    <Card className="h-md-100">
      <Card.Body>
        <Row className="justify-content-between g-0">
          <Col xs={5} sm={6} xxl className="pe-2">
            <h6 className="mt-1">Нийт төхөөрөмж</h6>
            {newData &&
              newData.map((item, index) => (
                <DeviceAnalyticItem
                  item={item}
                  index={index}
                  key={index}
                  total={total}
                />
              ))}
          </Col>
          <Col xs="auto">
            <div className="ps-0">
              <BasicECharts
                echarts={echarts}
                options={getOptions(uniqueData, radius, total)}
                style={{ width: '6.625rem', height: '6.625rem' }}
              />
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

DeviceAnalyticItem.propTypes = {
  item: PropTypes.shape({
    device: PropTypes.string.isRequired,
    count: PropTypes.number.isRequired
  }),
  index: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired
};

DeviceAnalytics.propTypes = {
  deviceAnalytics: PropTypes.array,
  radius: PropTypes.array.isRequired
};

export default DeviceAnalytics;
