import { gql } from '@apollo/client';

export const BLOG_CATEGORIES = gql`
  query Query {
    categories
  }
`;

export const BLOG_TAGS = gql`
  query Query {
    blogTags
  }
`;

export const CATALOG_TAGS = gql`
  query Query {
    catalogTags
  }
`;

export const BLOG_LIST = gql`
  query Blogs {
    blogs {
      data {
        _id
        category
        createdAt
        createdAtBy {
          _id
          email
        }
        description
        image {
          bucketname
          public_id
          url
        }
        publishedAtBy {
          _id
          firstName
        }
        readCount
        slug
        status
        tags
        title
        updatedAt
        updatedAtBy {
          _id
          firstName
        }
      }
      status {
        code
        description
        header
        moreInfo
      }
    }
  }
`;

export const PRODUCT_CATALOG_LIST = gql`
  query Query {
    productCatalogs {
      data {
        _id
        category
        company {
          _id
        }
        createdAt
        status
        description
        image {
          bucketname
          public_id
          url
        }
        name
        price
        publishedAtBy {
          _id
          email
        }
        quantity
        updatedAt
        createdAtBy {
          _id
          email
        }
      }
      status {
        code
        description
        header
        moreInfo
      }
    }
  }
`;

export const PRODUCT_CATALOG_CATEGORIES = gql`
  query Query {
    productCatalogCategories
  }
`;

export const CONTENT_LIST = gql`
  query Query($locale: String!) {
    textureAll(locale: $locale) {
      _id
      content {
        key
        value
      }
      locale
      name
      createdAt
    }
  }
`;

export const MESSAGE_LIST = gql`
  query Messages {
    messages {
      data {
        _id
        status
        content
        createdAt
        email
        name
        phone
        updatedAt
      }
      status {
        code
        description
        header
        moreInfo
      }
    }
  }
`;

export const MESSAGE_BY_ID = gql`
  query MessageById($messageId: String!) {
    messageById(messageId: $messageId) {
      data {
        _id
        content
        createdAt
        email
        name
        phone
        status
        updatedAt
      }
      status {
        code
        description
        header
        moreInfo
      }
    }
  }
`;

export const CUSTOMER_LIST = gql`
  query Users {
    users {
      data {
        _id
        avatar {
          bucketname
          public_id
          url
        }
        createdAt
        email
        firstName
        lastName
        loggedIn
        phone
        role {
          permission
          value
        }
        updatedAt
      }
      status {
        code
        description
        header
        moreInfo
      }
    }
  }
`;

export const USER_BY_ID = gql`
  query UserById($userId: String!) {
    userById(userId: $userId) {
      data {
        _id
        avatar {
          bucketname
          public_id
          url
        }
        createdAt
        email
        firstName
        lastName
        loggedIn
        phone
        role {
          permission
          value
        }
        updatedAt
      }
      status {
        code
        description
        header
        moreInfo
      }
    }
  }
`;

export const LOCALE_TYPE = gql`
  query Query {
    getLocaleType
  }
`;

export const COMPANY_LIST = gql`
  query Companies {
    companies {
      data {
        _id
        status
        address {
          address
          address1
          city
          country
        }
        payment {
          conditionPeriod
          payment
          paymently {
            description
            fromBankAccount
            isPaidMoney
            notificationPeriod
            paidmoney
            paymentActivationAt
            paymentActivationAtBy {
              _id
            }
            paymentAt
            paymentAtBy {
              _id
            }
            untilPeriod
          }
          status
        }
        companyRegister
        createdAt
        domain
        email
        name
        phone
        registerNumber
        createdAtBy {
          email
        }
        updatedAt
        workers {
          _id
        }
      }
      status {
        code
        description
        moreInfo
        header
      }
    }
  }
`;

export const COMPANY_TYPE = gql`
  query Query {
    companyTypes
  }
`;

export const COMPANY_TAGS = gql`
  query Query {
    companyTags
  }
`;

export const COMPANY_BY_ID = gql`
  query CompanyById($companyId: String!) {
    companyById(companyId: $companyId) {
      status {
        code
        description
        header
        moreInfo
      }
      data {
        _id
        address {
          address
          address1
          city
          country
        }
        payment {
          conditionPeriod
          payment
          paymently {
            description
            fromBankAccount
            isPaidMoney
            notificationPeriod
            paidmoney
            paymentActivationAt
            paymentActivationAtBy {
              _id
            }
            paymentAt
            paymentAtBy {
              _id
            }
            untilPeriod
          }
          status
        }
        companyRegister
        companyType
        createdAt
        domain
        email
        logo {
          bucketname
          public_id
          url
        }
        name
        phone
        registerNumber
        tags
        updatedAt
        workers {
          _id
        }
      }
    }
  }
`;

export const COMPANY_BY_ID_ACCESS_TOKEN = gql`
  query CompanyById($companyId: String!) {
    companyById(companyId: $companyId) {
      status {
        code
        description
        header
        moreInfo
      }
      data {
        _id
        accessToken
        accessTokenExpire
        handle
      }
    }
  }
`;

export const COMPANY_BY_ID_ONLY_ADMIN = gql`
  query Query($companyId: String!) {
    companyByIdAdminUsers(companyId: $companyId) {
      data {
        _id
        avatar {
          avatarImage
          bucketname
          isAvatarImageSet
          public_id
          url
        }
        companyId
        createdAt
        email
        firstName
        lastName
        loggedIn
        phone
        role {
          permission
          value
        }
        updatedAt
      }
      status {
        code
        description
        header
        moreInfo
      }
    }
  }
`;

export const COMPANY_BY_ID_ONLY_USERS = gql`
  query Query($companyId: String!) {
    companyByIdUsers(companyId: $companyId) {
      data {
        _id
        avatar {
          avatarImage
          bucketname
          isAvatarImageSet
          public_id
          url
        }
        companyId
        createdAt
        email
        firstName
        lastName
        loggedIn
        phone
        role {
          permission
          value
        }
        updatedAt
      }
      status {
        code
        description
        header
        moreInfo
      }
    }
  }
`;

export const USER_AGENT_ALL = gql`
  query VisitorAll {
    visitorAll {
      todayVisitors
      totalVisitors
      deviceAnalytics {
        count
        device
      }
      browserAnalytics {
        browser
        count
      }
      visitors {
        companyId
        viewPage
        pageVisited
        ipAddress
        userAgent {
          browser
          device
          engine
          os
        }
      }
    }
  }
`;

export const USER_LOG_ALL = gql`
  query UserlogAll($action: String!) {
    userlogAll(action: $action) {
      _id
      action
      companyId
      createdAt
      model
      targets
      userId {
        _id
        email
      }
      updatedAt
    }
  }
`;
export const COMPANY_BY_ID_ACCESS_DOMAIN = gql`
  query CompanyById($companyId: String!) {
    companyById(companyId: $companyId) {
      status {
        code
        description
        header
        moreInfo
      }
      data {
        _id
        domain
      }
    }
  }
`;

export const COMPANY_BY_ID_ACCESS_LOCALES = gql`
  query CompanyById($companyId: String!) {
    companyById(companyId: $companyId) {
      status {
        code
        description
        header
        moreInfo
      }
      data {
        _id
        languages
        policy
      }
    }
  }
`;

export const COMPANY_BY_ID_USERS = gql`
  query CompanyByIdUsers($companyId: String!) {
    companyByIdUsers(companyId: $companyId) {
      status {
        code
        description
        header
        moreInfo
      }
      data {
        _id
        avatar {
          avatarImage
          bucketname
          isAvatarImageSet
          public_id
          url
        }
        companyId
        createdAt
        role {
          owner
          permission
          value
        }
        email
        firstName
        lastName
      }
    }
  }
`;

export const NOTIFICATION_ALL = gql`
  query NotificationAll {
    notificationAll {
      data {
        _id
        companyId
        createdAt
        message
        modelUrl
        status
        title
        updatedAt
      }
      status {
        code
        description
        header
        moreInfo
      }
    }
  }
`;

export const NEW_SUBSCRIBITION = gql`
  subscription Subscription {
    newMessageComing {
      _id
      companyId
      createdAt
      message
      modelUrl
      status
      title
      updatedAt
    }
  }
`;
