import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';

const EventDetailAside = ({ getMessage }) => {
  const date = new Date(getMessage?.createdAt);
  // Extract year, month, and date
  const year = date.getFullYear();
  const month = date.getMonth() + 1; // Months are zero-based, so add 1
  const day = date.getDate();

  // Extract time with minutes and seconds
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();
  return (
    <div className="sticky-sidebar">
      <Card className="mb-3 fs--1">
        <Card.Body>
          <h6>Огноо</h6>
          <p className="mb-1">
            {year}-{month}-{day} өдөр – <br />
            {hours}:{minutes}:{seconds} минут
          </p>
          {/* <Link to="#!">Add to Calendar</Link> */}
          <h6 className="mt-4">Утасны дугаар</h6>
          <div className="mb-1">{getMessage?.phone}</div>

          <h6 className="mt-4">Имэйл</h6>
          <p className="fs--1 mb-0">{getMessage?.email}</p>
        </Card.Body>
      </Card>
      {/* <Events cardTitle="Events you may like" events={events.slice(2, 5)} /> */}
    </div>
  );
};
EventDetailAside.propTypes = {
  getMessage: PropTypes.object
};
export default EventDetailAside;
