import React from 'react';
import ForgetPasswordForm from 'components/authentication/ForgetPasswordForm';
import AuthCardLayout from 'layouts/AuthCardLayout';

const ForgetPassword = () => {
  return (
    <AuthCardLayout>
      <h4 className="mb-0">Нууц үгээ сэргээх үү?</h4>
      <p className="mb-0">
        Имэйлээ оруулна уу, бид танд дахин тохируулах холбоосыг илгээх болно.
      </p>
      <ForgetPasswordForm layout="card" />
    </AuthCardLayout>
  );
};

export default ForgetPassword;
