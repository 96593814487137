import React from 'react';
// import { Link } from 'react-router-dom';
import LoginForm from 'components/authentication/LoginForm';
import AuthCardLayout from 'layouts/AuthCardLayout';

const Login = () => {
  return (
    <AuthCardLayout
      leftSideContent={
        <p className="text-white">
          Системийн загвар үзэх
          <br />
          <div className="d-flex flex-column">
            <span className="fs--1">Имэйл хаяг: batdemberel_sh@yahoo.com</span>
            <span className="fs--1">Нууц үг: 123123</span>
          </div>
        </p>
      }
    >
      <h3>Нэвтрэх хэсэг</h3>
      <LoginForm layout="card" hasLabel />
    </AuthCardLayout>
    // <AuthCardLayout
    //   leftSideContent={
    //     <p className="text-white">
    //       Танд асуулт байна уу
    //       <br />
    //       <Link
    //         className="text-white text-decoration-underline"
    //         to="https://www.gegeensoft.com"
    //         target="_blank"
    //       >
    //         Админтай холбогдох
    //       </Link>
    //     </p>
    //   }
    // >
    //   <h3>Нэвтрэх хэсэг</h3>
    //   <LoginForm layout="card" hasLabel />
    // </AuthCardLayout>
  );
};

export default Login;
