import React from 'react';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { createRoot } from 'react-dom/client';
import { getMainDefinition } from '@apollo/client/utilities';
import App from './App';
import Main from './Main';
import 'helpers/initFA';
import {
  HttpLink,
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  split
} from '@apollo/client';
import Cookies from 'js-cookie';
import { setContext } from '@apollo/client/link/context';

const container = document.getElementById('main');
const root = createRoot(container);

const wsLink = new GraphQLWsLink(
  createClient({
    url:
      process.env.REACT_APP_ENVIRONMENT === 'production'
        ? process.env.REACT_APP_WEBSOCKET_API_ENDPOINT
        : process.env.REACT_APP_WEBSOCKET_API_ENDPOINT_LOCAL
  })
);

const httpLink = new HttpLink({
  uri:
    process.env.REACT_APP_ENVIRONMENT === 'production'
      ? process.env.REACT_APP_API_ENDPOINT
      : process.env.REACT_APP_API_ENDPOINT_LOCAL
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink
);

const authLink = setContext((_, { headers }) => {
  const accessToken = Cookies.get('accessToken');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: accessToken ? `Bearer ${accessToken}` : ''
    }
  };
});

const client = new ApolloClient({
  link: authLink.concat(splitLink),
  cache: new InMemoryCache()
});

root.render(
  <React.StrictMode>
    <Main>
      <ApolloProvider client={client}>
        <App />
      </ApolloProvider>
    </Main>
  </React.StrictMode>
);
