import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import PrivateRoute from './privateRoutes';
import MainLayout from '../layouts/MainLayout';
import ErrorLayout from '../layouts/ErrorLayout';

import Error404 from 'components/errors/Error404';
import Error500 from 'components/errors/Error500';

import CardLogin from 'components/authentication/card/Login';
import Logout from 'components/authentication/card/Logout';
import CardForgetPassword from 'components/authentication/card/ForgetPassword';
import CardPasswordReset from 'components/authentication/card/PasswordReset';

import Dashboard from 'components/dashboards/default';
// Company Create
import CompanyList from 'components/dashboards/company/companyList';
import CompanyAdd from 'components/dashboards/company/CompanyAdd';
import CompanyUpdate from 'components/dashboards/company/CompanyUpdate';
import CompanyPayment from 'components/dashboards/company/CompanyPayment';
//company blog list
import Blogs from 'components/dashboards/blogs/blogList';
import BlogAdd from 'components/dashboards/blogs/BlogAdd';
import BlogUpdate from 'components/dashboards/blogs/BlogUpdate';
// ProductCatalog
import ProductCatalogList from 'components/dashboards/product/ProductCatalogList';
import ProductCatalogAdd from 'components/dashboards/product/ProductCatalogAdd';
import ProductCatalogUpdate from 'components/dashboards/product/ProductCatalogUpdate';
// message
import MessageList from 'components/dashboards/message/MessageList';
import MessageDetail from 'components/dashboards/message/MessageDetail';
// customer
import CustomerList from 'components/dashboards/customer/Customers';
import CustomerSettings from 'components/dashboards/customer/settings/Settings';
import CustomerInvite from 'components/dashboards/customer/CustomerInvite';
import InvitedUser from 'components/authentication/card/InvitedUser';
// message
import ContentList from 'components/dashboards/content/ContentList';
//settings
import SettingsGemeral from 'components/dashboards/settings/SettingsGemeral';
import SettingsAccount from 'components/dashboards/settings/SettingsAccount';
import SettingsCustomerAccounts from 'components/dashboards/settings/SettingsCustomerAccounts';
import SettingsDomains from 'components/dashboards/settings/SettingsDomains';
import SettingsPayment from 'components/dashboards/settings/SettingsPayment';
import SettingsLanguages from 'components/dashboards/settings/SettingsLanguages';
import SettingsLegal from 'components/dashboards/settings/SettingsLegal';

const FalconRoutes = () => {
  return (
    <Routes>
      <Route element={<ErrorLayout />}>
        <Route path="errors/404" element={<Error404 />} />
        <Route path="errors/500" element={<Error500 />} />
      </Route>
      {/*- ------------- Authentication ---------------------------  */}

      {/*- ------------- Card ---------------------------  */}
      <Route path="/authentication/card/login" element={<CardLogin />} />
      <Route path="/authentication/card/logout" element={<Logout />} />
      <Route
        path="/authentication/card/forgot-password"
        element={<CardForgetPassword />}
      />
      <Route
        path="/authentication/card/reset-password/:token"
        element={<CardPasswordReset />}
      />

      <Route
        path="/authentication/card/invite-user/:token"
        element={<InvitedUser />}
      />
      {/* //--- MainLayout Starts  */}

      <Route element={<MainLayout />}>
        {/*Dashboard*/}
        <Route
          path="/"
          element={
            <PrivateRoute
              requiredRoles={['Root', 'Admin', 'Publisher', 'Worker']}
            >
              <Dashboard />
            </PrivateRoute>
          }
        />
        {/* company */}
        <Route
          path="/company/company-list"
          element={
            <PrivateRoute requiredRoles={['Root']}>
              <CompanyList />
            </PrivateRoute>
          }
        />
        <Route
          path="/company/company-create"
          element={
            <PrivateRoute requiredRoles={['Root']}>
              <CompanyAdd />
            </PrivateRoute>
          }
        />
        <Route
          path="/company/company-update/:companyId"
          element={
            <PrivateRoute requiredRoles={['Root']}>
              <CompanyUpdate />
            </PrivateRoute>
          }
        />
        <Route
          path="/company/company-payment/:companyId"
          element={
            <PrivateRoute requiredRoles={['Root']}>
              <CompanyPayment />
            </PrivateRoute>
          }
        />
        {/* blog */}
        <Route
          path="/blogs/blog-list"
          element={
            <PrivateRoute requiredRoles={['Root', 'Admin', 'Publisher']}>
              <Blogs />
            </PrivateRoute>
          }
        />
        <Route
          path="/blogs/new-blog"
          element={
            <PrivateRoute requiredRoles={['Root', 'Admin', 'Publisher']}>
              <BlogAdd />
            </PrivateRoute>
          }
        />
        <Route
          path="/blogs/blog-update/:blogId"
          element={
            <PrivateRoute requiredRoles={['Root', 'Admin', 'Publisher']}>
              <BlogUpdate />
            </PrivateRoute>
          }
        />
        {/* Catalog product */}
        <Route
          path="/product-catalog/product-list"
          element={
            <PrivateRoute
              requiredRoles={['Root', 'Admin', 'Publisher', 'Worker']}
            >
              <ProductCatalogList />
            </PrivateRoute>
          }
        />
        <Route
          path="/product-catalog/new-product"
          element={
            <PrivateRoute
              requiredRoles={['Root', 'Admin', 'Publisher', 'Worker']}
            >
              <ProductCatalogAdd />
            </PrivateRoute>
          }
        />
        <Route
          path="/product-catalog/product-update/:productId"
          element={
            <PrivateRoute
              requiredRoles={['Root', 'Admin', 'Publisher', 'Worker']}
            >
              <ProductCatalogUpdate />
            </PrivateRoute>
          }
        />
        {/* Message list */}
        <Route
          path="/message/message-list"
          element={
            <PrivateRoute
              requiredRoles={['Root', 'Admin', 'Publisher', 'Worker']}
            >
              <MessageList />
            </PrivateRoute>
          }
        />
        <Route
          path="/message/message-detail/:messageId"
          element={
            <PrivateRoute
              requiredRoles={['Root', 'Admin', 'Publisher', 'Worker']}
            >
              <MessageDetail />
            </PrivateRoute>
          }
        />
        {/* Customer */}
        <Route
          path="/customers/customer-list"
          element={
            <PrivateRoute requiredRoles={['Root', 'Admin']}>
              <CustomerList />
            </PrivateRoute>
          }
        />

        <Route
          path="/customers/customer-settings"
          element={
            <PrivateRoute
              requiredRoles={['Root', 'Admin', 'Publisher', 'Worker']}
            >
              <CustomerSettings />
            </PrivateRoute>
          }
        />

        <Route
          path="/customers/customer-invite"
          element={
            <PrivateRoute requiredRoles={['Root', 'Admin']}>
              <CustomerInvite />
            </PrivateRoute>
          }
        />

        {/* Content list */}
        <Route
          path="/content/content-list"
          element={
            <PrivateRoute
              requiredRoles={['Root', 'Admin', 'Publisher', 'Worker']}
            >
              <ContentList />
            </PrivateRoute>
          }
        />
        {/* Settings  page */}
        <Route
          path="/settings/general"
          element={
            <PrivateRoute
              requiredRoles={['Root', 'Admin', 'Publisher', 'Worker']}
            >
              <SettingsGemeral />
            </PrivateRoute>
          }
        />
        <Route
          path="/settings/account"
          element={
            <PrivateRoute
              requiredRoles={['Root', 'Admin', 'Publisher', 'Worker']}
            >
              <SettingsAccount />
            </PrivateRoute>
          }
        />

        <Route
          path="/settings/customer_accounts"
          element={
            <PrivateRoute
              requiredRoles={['Root', 'Admin', 'Publisher', 'Worker']}
            >
              <SettingsCustomerAccounts />
            </PrivateRoute>
          }
        />
        <Route
          path="/settings/payment"
          element={
            <PrivateRoute
              requiredRoles={['Root', 'Admin', 'Publisher', 'Worker']}
            >
              <SettingsPayment />
            </PrivateRoute>
          }
        />
        <Route
          path="/settings/domains"
          element={
            <PrivateRoute
              requiredRoles={['Root', 'Admin', 'Publisher', 'Worker']}
            >
              <SettingsDomains />
            </PrivateRoute>
          }
        />
        <Route
          path="/settings/languages"
          element={
            <PrivateRoute
              requiredRoles={['Root', 'Admin', 'Publisher', 'Worker']}
            >
              <SettingsLanguages />
            </PrivateRoute>
          }
        />
        <Route
          path="/settings/legal"
          element={
            <PrivateRoute
              requiredRoles={['Root', 'Admin', 'Publisher', 'Worker']}
            >
              <SettingsLegal />
            </PrivateRoute>
          }
        />
      </Route>

      {/* <Navigate to="/errors/404" /> */}
      <Route path="*" element={<Navigate to="/errors/404" replace />} />
    </Routes>
  );
};

export default FalconRoutes;
