import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Button, Form } from 'react-bootstrap';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { useMutation } from '@apollo/client';
import { CUSTOMER_INVITE_ACTIVATION } from '../../lib/mutations';
import Cookies from 'js-cookie';
import { AuthWizardContext } from '../../context/Context';

const PasswordResetFormInvited = ({ hasLabel }) => {
  const navigage = useNavigate();
  const [inviteActivation, { data }] = useMutation(CUSTOMER_INVITE_ACTIVATION);
  const { token } = useParams();
  const { setUser } = useContext(AuthWizardContext);

  const location = useLocation();
  // State
  const [formData, setFormData] = useState({
    password: '',
    confirmPassword: ''
  });

  useEffect(() => {
    if (data) {
      if (data?.customerInviteUserActivation?.status?.code === 200) {
        toast.success(`Нууц үг амжилттай шинэчлэгдлээ та нэвтэрч орно уу`, {
          theme: 'colored'
        });
        if (data?.customerInviteUserActivation?.token) {
          Cookies.set(
            'accessToken',
            data?.customerInviteUserActivation?.token,
            {
              expires: 7
            }
          );
        }

        if (data?.customerInviteUserActivation?.data) {
          Cookies.set(
            'user',
            JSON.stringify(data?.customerInviteUserActivation?.data),
            {
              expires: 7
            }
          );
          setUser(data?.customerInviteUserActivation?.data);
        }

        navigage('/');
      } else {
        toast.warning(
          `${data?.customerInviteUserActivation?.status?.moreInfo}`,
          {
            theme: 'colored'
          }
        );
      }
    }
  }, [data]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const password = searchParams.get('password');
    const confirmPassword = searchParams.get('confirmPassword');

    if (password && confirmPassword) {
      setFormData({
        password,
        confirmPassword
      });
    }
  }, [location.search]);

  // Handler
  const handleSubmit = e => {
    e.preventDefault();
    if (formData.password === formData.confirmPassword) {
      inviteActivation({
        variables: {
          input: {
            newPassword: formData.password,
            token: token
          }
        }
      });
    } else {
      toast.warning(`Батлах нууц үг таарахгүй байна`, { theme: 'colored' });
    }
  };

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <Form
      className={classNames('mt-3', { 'text-left': hasLabel })}
      onSubmit={handleSubmit}
    >
      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Шинэ нууц үг</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'New Password' : ''}
          value={formData.password}
          name="password"
          onChange={handleFieldChange}
          type="password"
        />
      </Form.Group>

      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Хуучин нууц үг</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Confirm Password' : ''}
          value={formData.confirmPassword}
          name="confirmPassword"
          onChange={handleFieldChange}
          type="password"
        />
      </Form.Group>

      <Button
        type="submit"
        className="w-100"
        disabled={!formData.password || !formData.confirmPassword}
      >
        Нууц үг солих
      </Button>
    </Form>
  );
};

PasswordResetFormInvited.propTypes = {
  hasLabel: PropTypes.bool
};

export default PasswordResetFormInvited;
