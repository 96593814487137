import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthWizardContext } from '../context/Context';
import Cookies from 'js-cookie';
import PropTypes from 'prop-types';

const PrivateRoute = ({ children, requiredRoles }) => {
  const token = Cookies.get('accessToken');
  const getUser = Cookies.get('user');
  const cookieUser = getUser ? JSON.parse(getUser) : null;
  const { user } = useContext(AuthWizardContext);
  const userRole = user?.role?.value
    ? user?.role?.value
    : cookieUser?.role?.value;

  if (!token || !user) {
    return <Navigate to="/authentication/card/login" />;
  }

  if (userRole) {
    const hasRequiredRoles =
      requiredRoles.length > 0 &&
      requiredRoles?.some(role => {
        return [userRole].includes(role);
      });

    if (!hasRequiredRoles) {
      return <Navigate to="/authentication/card/login" />;
    }
  } else {
    return <Navigate to="/authentication/card/login" />;
  }

  return children;
};

PrivateRoute.propTypes = {
  children: PropTypes.element.isRequired,
  requiredRoles: PropTypes.array
};

export default PrivateRoute;
