import React, { useState, useEffect, useContext } from 'react';
import {
  Button,
  Card,
  Form,
  Col,
  Row,
  Modal,
  Alert,
  ProgressBar
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import TooltipBadge from 'components/common/TooltipBadge';
import Flex from 'components/common/Flex';
import { v4 as uuid } from 'uuid';
import { AuthWizardContext } from '../../../context/Context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SettingsSideBar from './SettingsSideBar';
import SettingsHeader from './SettingsHeader';
import { Link } from 'react-router-dom';
import { CUSTOMER_INVITE_SET_PASSWORD } from '../../../lib/mutations';
import {
  COMPANY_BY_ID_ONLY_ADMIN,
  COMPANY_BY_ID_ONLY_USERS
} from '../../../lib/query';
import { useQuery, useMutation } from '@apollo/client';
import Cookies from 'js-cookie';
import usePermission from 'components/common/usePermission';

function checkValue(value, item) {
  const newArr = item.split('W')[1];
  const first = value.split('')[0];
  const two = value.split('')[1];
  const foo = newArr[first] === two;
  return foo;
}

function calculateRoleName(array) {
  const newArray = array.map(item => parseInt(item.split('W')[1]));
  const sum = newArray.reduce(
    (accumulator, currentValue) => accumulator + currentValue,
    0
  );
  return findRoleName(sum);
}

function calculateProcent(array) {
  const full = 6666;
  let result = 0;
  const newArray = array.map(item => parseInt(item.split('W')[1]));
  const sum = newArray.reduce(
    (accumulator, currentValue) => accumulator + currentValue,
    0
  );
  result = Number((100 * sum) / full);
  return result;
}

function findRoleName(sum) {
  if (sum > 0 && sum <= 3333) {
    return 'Ажилчин';
  } else if (sum >= 3333 && sum <= 4444) {
    return 'Нийтлэгч';
  } else if (sum >= 4444 && sum <= 6666) {
    return 'Админ';
  }
}

function findSendRoleName(roleName) {
  switch (roleName) {
    case 'Ажилчин':
      return 'Worker';
    case 'Нийтлэгч':
      return 'Publisher';
    case 'Админ':
      return 'Admin';
  }
}

const SettingsAccount = () => {
  const { authorized, styles } = usePermission('S11');
  const getCompany = Cookies.get('company');
  const cookieCompany = getCompany ? JSON.parse(getCompany) : null;
  const getUser = Cookies.get('user');
  const user = getUser ? JSON.parse(getUser) : null;
  const { company, user: contextUser } = useContext(AuthWizardContext);
  const [observer, setObserver] = useState(false);
  const [inviteSetPassword, { data }] = useMutation(
    CUSTOMER_INVITE_SET_PASSWORD
  );
  const { data: getAdmins } = useQuery(COMPANY_BY_ID_ONLY_ADMIN, {
    variables: { companyId: company._id },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first'
  });
  const { data: getUsers } = useQuery(COMPANY_BY_ID_ONLY_USERS, {
    variables: { companyId: company._id },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first'
  });
  const [formData, setFormData] = useState({
    email: '',
    firstName: '',
    lastName: '',
    password: '123123'
  });
  const [roleName, setRoleName] = useState();
  const [admin, setAdmin] = useState([]);
  const [users, setUsers] = useState([]);
  const [modalJob, setModalJob] = useState(false);
  const [permissionArray, setPermissionArray] = useState([
    'MW1000',
    'PW1000',
    'CW1000',
    'BW0000',
    'SW0000',
    'UW0000'
  ]);

  useEffect(() => {
    const newName = calculateRoleName(permissionArray);
    setRoleName(newName);
  }, [permissionArray]);

  useEffect(() => {
    if (getAdmins?.companyByIdAdminUsers?.status?.code === 200) {
      setAdmin(getAdmins?.companyByIdAdminUsers?.data);
    }

    if (getUsers?.companyByIdUsers?.status?.code === 200) {
      setUsers(getUsers?.companyByIdUsers?.data);
    }

    if (data) {
      if (data?.customerInviteByEmail?.status?.code === 200) {
        toast.success(
          `Таны хүсэлт тус ${formData.email} имэйл рүү амжилттай илгээгдлээ`,
          {
            theme: 'colored'
          }
        );
      } else {
        toast.warning(`${data?.customerInviteByEmail?.status?.moreInfo}`, {
          theme: 'colored'
        });
      }
    }
  }, [getAdmins, getUsers, data]);

  const convertToTitle = content => {
    switch (content.charAt(0)) {
      case 'M':
        return 'Зурвас';
      case 'P':
        return 'Каталог';
      case 'C':
        return 'Контент';
      case 'B':
        return 'Мэдээлэл';
      case 'S':
        return 'Тохиргоо';
      case 'U':
        return 'Хэрэглэгч';
      default:
        return '';
    }
  };

  const handleChange = e => {
    const filteredArray = permissionArray.filter(item =>
      item.includes(e.target.value)
    );
    const newArray = filteredArray[0].split('W');
    const newToo = newArray[1].split('');
    if (!checkValue(e.target.name, filteredArray[0])) {
      newToo[e.target.name.split('')[0]] = e.target.name.split('')[1];
    } else {
      newToo[e.target.name.split('')[0]] = 0;
    }

    const newCipher = e.target.value + 'W' + newToo.join('');

    const convertedArray = permissionArray.map(item => {
      if (item.startsWith(e.target.value.charAt(0))) {
        return newCipher;
      }
      return item;
    });

    setPermissionArray(convertedArray);
  };

  const handleSubmit = e => {
    e.preventDefault();
    if (formData?.email && formData.firstName && formData.lastName) {
      inviteSetPassword({
        variables: {
          input: {
            email: formData.email,
            firstName: formData.firstName,
            lastName: formData.lastName,
            password: formData.password,
            role: {
              value: findSendRoleName(roleName),
              permission: permissionArray
            },
            companyId: company?._id ? company._id : cookieCompany._id
          },
          inviteEmail: contextUser ? contextUser.email : user.email
        }
      });
      setFormData({
        email: '',
        firstName: '',
        lastName: '',
        password: '123123'
      });
      setObserver(false);
      setPermissionArray([
        'MW1000',
        'PW1000',
        'CW1000',
        'BW0000',
        'SW0000',
        'UW0000'
      ]);
    } else {
      toast.warning(`Мэдээллээ гүйцэт оруулах ёстой`, {
        theme: 'colored'
      });
    }
  };

  const handleChangeEmail = e => {
    setObserver(true);
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <Row className="g-3">
      <Col xl={4} xxl={3}>
        <SettingsSideBar />
      </Col>
      <Col xl={8} xxl={9}>
        <SettingsHeader data={'Хэрэглэгчид ба зөвшөөрөл'} />

        <Card>
          <Card.Header className="bg-light d-flex justify-content-between align-items-md-center">
            <div>
              <h6 className="mb-0">Админ эрхтэй хэрэглэгч</h6>
            </div>
          </Card.Header>
          <Card.Body>
            <div className="my-x3 mt-2 m-lg-2 ">
              <h6 className="mb-0  fw-bold">Зөвшөөрөл</h6>
              <h6 className="mt-lg-2">
                Админ хэрэглэгч нь бүрэн гүйцэт эрхтэй вэбийг эзэмшигч эрх юм.
              </h6>
            </div>
            {admin &&
              admin.map(user => (
                <Flex alignItems="center" className="mb-2" key={user._id}>
                  <FontAwesomeIcon
                    icon="user-circle"
                    className="fs-3 me-2 text-700"
                  />
                  <Link to="#!">
                    <div className="flex-1">
                      <h6 className="mb-0 text-primary">{user?.firstName}</h6>
                    </div>
                  </Link>
                </Flex>
              ))}
          </Card.Body>
        </Card>
        <Card className="mt-4">
          <Card.Body>
            <h6 className="fw-bold">Ажилтан нэмэх </h6>
            <h6 className="">
              Та ажилтан нэмэхдээ ажилтаны эрх үүргийг өөрийн хүссэнээр
              тохируулах боломжтой юм.
            </h6>
            <Button
              className="btn btn-success mt-3"
              size="sm"
              onClick={() => setModalJob(!modalJob)}
              style={styles}
              disabled={authorized}
            >
              {' '}
              Ажилтан нэмэх
            </Button>
          </Card.Body>
        </Card>
        <Card className="mt-4">
          <Card.Body>
            <div className="my-x3 mt-2 m-lg-2 ">
              <h6 className="mb-0  fw-bold">Ажилтан</h6>
              <h6 className="mt-lg-2">
                Ажилтан нь Publisher, Worker гэсэн хоёр ажилтаны төлөвтэй бөгөөд
                Publisher нь мэдээлэлтэй холбоотой хэсгийг удирдах боломжтой
                ажилтан юм.
              </h6>
            </div>
            {users.length > 0 ? (
              users.map(user => (
                <Flex alignItems="center" className="mb-2" key={user._id}>
                  <FontAwesomeIcon
                    icon="user-circle"
                    className="fs-3 me-2 text-700"
                  />
                  <span className="text-primary">
                    <div className="flex-1">
                      <h6 className="mb-0 text-primary">{user?.firstName}</h6>
                    </div>
                  </span>
                </Flex>
              ))
            ) : (
              <div className="px-2">
                <Alert variant={'secondary'}>
                  <h6 className="fs--1 mb-0 text-600">
                    Одоогоор үүссэн ажилтан байхгүй байна
                  </h6>
                </Alert>
              </div>
            )}
          </Card.Body>
        </Card>
      </Col>

      <Modal
        show={modalJob}
        onHide={() => setModalJob(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Form onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter text-sm">
              Шинэ ажилтан
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="px-4 my-2">
            <label>Имэйл</label>
            <input
              className="form-control"
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChangeEmail}
            />
            <Row className="mt-4">
              <Col sm={12} md={6}>
                <label>Овог</label>
                <input
                  className="form-control"
                  type="text"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChangeEmail}
                />
              </Col>
              <Col sm={12} md={6}>
                <label>Нэр</label>
                <input
                  className="form-control"
                  type="text"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChangeEmail}
                />
              </Col>
              <Col className="my-4" sm={12} md={12}>
                <Form.Label>{roleName}</Form.Label>
                <ProgressBar
                  variant="success"
                  now={calculateProcent(permissionArray)}
                  label={parseInt(calculateProcent(permissionArray)) + '%'}
                />
              </Col>
            </Row>
            <Form.Group as={Row} className="mb-3">
              {permissionArray.length > 0 &&
                permissionArray.map((item, i) => (
                  <Col key={i} sm={{ span: 6 }}>
                    <div className="border-dashed border-bottom my-3 pe-2" />
                    <h6 className="fw-bold">
                      {convertToTitle(item)}
                      <TooltipBadge
                        tooltip="Only The group of selected people can see your profile"
                        icon="question-circle"
                      />
                    </h6>
                    <div className="ps-2">
                      <Form.Check
                        type="checkbox"
                        id={`invalidCheck-${uuid()}`}
                        label="Унших"
                        className="form-label-nogutter"
                        name="01"
                        value={item.split('W')[0]}
                        onChange={handleChange}
                        checked={checkValue('01', item)}
                      />
                      <Form.Check
                        type="checkbox"
                        id={`invalidCheck-${uuid()}`}
                        label="Үүсгэх"
                        className="form-label-nogutter"
                        name="11"
                        value={item.split('W')[0]}
                        onChange={handleChange}
                        checked={checkValue('11', item)}
                      />
                      <Form.Check
                        type="checkbox"
                        id={`invalidCheck-${uuid()}`}
                        label="Засварлах"
                        className="form-label-nogutter"
                        name="21"
                        value={item.split('W')[0]}
                        onChange={handleChange}
                        checked={checkValue('21', item)}
                      />
                      <Form.Check
                        type="checkbox"
                        id={`invalidCheck-${uuid()}`}
                        label="Устгах"
                        className="form-label-nogutter"
                        name="31"
                        value={item.split('W')[0]}
                        onChange={handleChange}
                        checked={checkValue('31', item)}
                      />
                    </div>
                  </Col>
                ))}
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => setModalJob(false)}>Хаах</Button>
            <Button
              type="submit"
              className="btn btn-success"
              disabled={!observer}
            >
              Хадгалах
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </Row>
  );
};

export default SettingsAccount;
