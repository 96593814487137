import React from 'react';
import PasswordResetFormInvited from 'components/authentication/PasswordResetFormInvited';
import AuthCardLayout from 'layouts/AuthCardLayout';

const InvitedUser = () => {
  return (
    <AuthCardLayout>
      <h3>Та нууц үгээ шинээр үүсгэн нэвтэрнэ үү</h3>
      <PasswordResetFormInvited layout="card" hasLabel />
    </AuthCardLayout>
  );
};

export default InvitedUser;
