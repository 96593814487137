import IconButton from 'components/common/IconButton';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { Col, Form, Row, Button } from 'react-bootstrap';
import {
  BLOG_BULK_UPDATE,
  BLOG_BULK_DELETE,
  COMPANY_SEND_INVOICE
} from '../../../lib/mutations';
import { useNavigate } from 'react-router-dom';
import * as XLSX from 'xlsx';

function convertArrayToExcel(array) {
  const worksheet = XLSX.utils.json_to_sheet(array);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
  const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

  // Create a Blob object with the Excel data
  const blob = new Blob([excelBuffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  });

  // Create a temporary anchor element
  const anchor = document.createElement('a');
  anchor.href = URL.createObjectURL(blob);
  anchor.download = 'data.xlsx'; // Change the filename as desired
  anchor.style.display = 'none';

  // Append the anchor to the document body and click it programmatically
  document.body.appendChild(anchor);
  anchor.click();

  // Clean up
  document.body.removeChild(anchor);
  URL.revokeObjectURL(anchor.href);
}

const CompanyTableHeader = ({ selectedRowIds, getBlogDatas, refetch }) => {
  const [blogBulkUpdate, { data }] = useMutation(BLOG_BULK_UPDATE);
  const [sendInvoiceToCompany, { data: sendInvoiceData }] =
    useMutation(COMPANY_SEND_INVOICE);
  const [blogBulkDelete, { data: deletedData }] = useMutation(BLOG_BULK_DELETE);
  const [actionType, setActionType] = useState('editable');
  const keyArray = Object.keys(selectedRowIds).map(Number);
  const newUpdateIds = keyArray.map(item => {
    return getBlogDatas[item]?._id;
  });

  let navigage = useNavigate();

  useEffect(() => {
    if (data) {
      if (data?.blogBulkUpdate) {
        refetch();

        toast.success(`Амжиллтай засварлалаа`, {
          theme: 'colored'
        });
      } else {
        toast.warning(`Ямар нэгэн алдаа гарлаа`, {
          theme: 'colored'
        });
      }
    }
    if (deletedData) {
      if (deletedData?.blogBulkDelete) {
        refetch();

        toast.success(`Амжиллтай устгагдлаа`, {
          theme: 'colored'
        });
      } else {
        toast.warning(`Ямар нэгэн алдаа гарлаа`, {
          theme: 'colored'
        });
      }
    }
    if (sendInvoiceData) {
      if (sendInvoiceData?.companyCheckNotification) {
        refetch();

        toast.success(`Амжиллтай засварлалаа`, {
          theme: 'colored'
        });
      } else {
        toast.warning(`Ямар нэгэн алдаа гарлаа`, {
          theme: 'colored'
        });
      }
    }
  }, [data, deletedData, sendInvoiceData]);

  const handleSubmit = e => {
    e.preventDefault();
    if (newUpdateIds.length > 0 && actionType !== 'editable') {
      if (actionType !== 'delete') {
        blogBulkUpdate({
          variables: {
            actionType: actionType === 'active' ? true : false,
            blogIds: newUpdateIds
          }
        });
      } else {
        blogBulkDelete({
          variables: {
            blogIds: newUpdateIds
          }
        });
      }
    }
  };

  const handleFieldChange = e => {
    setActionType(e.target.value);
  };
  const handleChangeFileFormat = () => {
    const newRawData = getBlogDatas.map(company => {
      return {
        name: company.name,
        phone: company.phone,
        email: company.email,
        companyRegister: company.companyRegister,
        registerNumber: company.registerNumber,
        companyType: company.companyType
      };
    });
    convertArrayToExcel(newRawData);
    // convertArrayToCSVDownload(newRawData);
  };

  const handleGoTo = () => {
    navigage('/company/company-create');
  };

  const handleSendInvoice = () => {
    sendInvoiceToCompany();
  };

  return (
    <Row className="flex-between-center">
      <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
        <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">Мэдээлэл</h5>
      </Col>
      <Col xs={8} sm="auto" className="ms-auto text-end ps-0">
        {Object.keys(selectedRowIds).length > 0 ? (
          <div className="d-flex">
            <Form.Select
              size="sm"
              aria-label="Bulk actions"
              onChange={handleFieldChange}
            >
              <option value="editable">Засварлах</option>
              <option value="active">Нээх</option>
              <option value="closed">Хаах</option>
              <option value="delete">Устгах</option>
              {/* <option value="archive">Archive</option> */}
            </Form.Select>
            <Button
              type="button"
              variant="falcon-default"
              size="sm"
              className="ms-2"
              onClick={handleSubmit}
            >
              Хадгалах
            </Button>
          </div>
        ) : (
          <div id="orders-actions">
            <IconButton
              variant="falcon-default"
              size="sm"
              icon="plus"
              transform="shrink-3"
              onClick={handleGoTo}
            >
              <span className="d-none d-sm-inline-block ms-1">
                Шинэ компани
              </span>
            </IconButton>
            <IconButton
              variant="falcon-default"
              size="sm"
              icon="filter"
              transform="shrink-3"
              className="mx-2"
              onClick={handleSendInvoice}
            >
              <span className="d-none d-sm-inline-block ms-1">
                Нэхэмжлэх илгээх
              </span>
            </IconButton>
            <IconButton
              variant="falcon-default"
              size="sm"
              icon="external-link-alt"
              transform="shrink-3"
              onClick={handleChangeFileFormat}
            >
              <span className="d-none d-sm-inline-block ms-1">Экспорт</span>
            </IconButton>
          </div>
        )}
      </Col>
    </Row>
  );
};

CompanyTableHeader.propTypes = {
  selectedRowIds: PropTypes.object,
  getBlogDatas: PropTypes.array,
  refetch: PropTypes.func
};

export default CompanyTableHeader;
