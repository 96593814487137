import React, { useEffect, useState } from 'react';
import { Card, Dropdown, Col } from 'react-bootstrap';
import { toast } from 'react-toastify';
import CardDropdown from 'components/common/CardDropdown';
import SoftBadge from 'components/common/SoftBadge';
import { Link } from 'react-router-dom';
import BlogTableHeader from './BlogTableHeader';
import BlogHeader from './BlogHeader';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import { BLOG_LIST } from '../../../lib/query';
import { BLOG_BULK_UPDATE, BLOG_BULK_DELETE } from '../../../lib/mutations';
import { useQuery, useMutation } from '@apollo/client';
import usePermission from 'components/common/usePermission';

const Blogs = () => {
  const { authorized } = usePermission('B21');
  const { authorized: deleteAuthorized, styles: deleteStyles } =
    usePermission('B31');
  const [blogBulkUpdate, { data }] = useMutation(BLOG_BULK_UPDATE);
  const [blogBulkDelete, { data: deletedData }] = useMutation(BLOG_BULK_DELETE);
  const { data: getBlogs, refetch } = useQuery(BLOG_LIST, {
    fetchPolicy: 'network-only'
  });
  const [getBlogDatas, setGetBlogDatas] = useState([]);

  useEffect(() => {
    if (data) {
      if (data?.blogBulkUpdate) {
        refetch();

        toast.success(`Амжиллтай засварлалаа`, {
          theme: 'colored'
        });
      } else {
        toast.warning(`Ямар нэгэн алдаа гарлаа`, {
          theme: 'colored'
        });
      }
    }
    if (deletedData) {
      if (deletedData?.blogBulkDelete) {
        refetch();

        toast.success(`Амжиллтай устгагдлаа`, {
          theme: 'colored'
        });
      } else {
        toast.warning(`Ямар нэгэн алдаа гарлаа`, {
          theme: 'colored'
        });
      }
    }
  }, [data, deletedData]);

  useEffect(() => {
    if (getBlogs?.blogs?.status?.code === 200) {
      setGetBlogDatas(getBlogs?.blogs?.data);
    }
  }, [getBlogs]);

  const handleFieldChange = (value, blogId) => {
    if (value === 'delete') {
      blogBulkDelete({
        variables: {
          blogIds: [blogId]
        }
      });
    } else {
      blogBulkUpdate({
        variables: {
          actionType: value === 'active' ? true : false,
          blogIds: [blogId]
        }
      });
    }
  };

  const columns = [
    {
      accessor: 'name',
      Header: 'Мэдээний дугаар',
      headerProps: { className: 'pe-5' },
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        const { _id } = rowData.row.original;
        return (
          <>
            {authorized ? (
              <strong className="text-primary">#{_id.slice(-6)}</strong>
            ) : (
              <Link to={`/blogs/blog-update/${_id}`}>
                <strong>#{_id.slice(-6)}</strong>
              </Link>
            )}
          </>
        );
      }
    },
    {
      accessor: 'date',
      Header: 'Огноо',
      headerProps: { className: 'pe-7' },
      Cell: rowData => {
        const { createdAt } = rowData.row.original;
        return <>{createdAt.split('T')[0]}</>;
      }
    },
    {
      accessor: 'address',
      Header: 'Гарчиг',
      Cell: rowData => {
        const { title } = rowData.row.original;
        return <>{title.substring(0, 40)}</>;
      }
    },
    {
      accessor: 'status',
      Header: 'Төлөв',
      headerProps: { className: 'pe-7' },
      Cell: rowData => {
        const { status } = rowData.row.original;
        return status ? (
          <SoftBadge pill bg="success" className="me-2">
            Нээлттэй
          </SoftBadge>
        ) : (
          <SoftBadge pill bg="secondary" className="me-2">
            Хаалттай
          </SoftBadge>
        );
      }
    },
    {
      accessor: 'user',
      Header: 'Нийтэлсэн',
      Cell: rowData => {
        const { createdAtBy } = rowData.row.original;
        return <>{createdAtBy?.email}</>;
      }
    },

    {
      accessor: 'none',
      Header: '',
      disableSortBy: true,
      cellProps: {
        className: 'text-end'
      },
      Cell: rowData => {
        const { _id: blogId } = rowData.row.original;
        return (
          <CardDropdown>
            <div className="py-2">
              <Dropdown.Item
                onClick={() => handleFieldChange('active', blogId)}
              >
                Нээх
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleFieldChange('close', blogId)}>
                Хаах
              </Dropdown.Item>
              <Dropdown.Divider as="div" />
              <Dropdown.Item
                onClick={() => handleFieldChange('delete', blogId)}
                className="text-danger"
                style={deleteStyles}
                disabled={deleteAuthorized}
              >
                Устгах
              </Dropdown.Item>
            </div>
          </CardDropdown>
        );
      }
    }
  ];

  return (
    <>
      <Col xs={12} className="mb-3">
        <BlogHeader />
      </Col>

      <AdvanceTableWrapper
        columns={columns}
        data={getBlogDatas}
        selection
        sortable
        pagination
        perPage={20}
      >
        <Card className="mb-3">
          <Card.Header>
            <BlogTableHeader
              table
              getBlogDatas={getBlogDatas}
              refetch={refetch}
            />
          </Card.Header>
          <Card.Body className="p-0">
            <AdvanceTable
              table
              headerClassName="bg-200 text-900 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                size: 'sm',
                striped: true,
                className: 'fs--1 mb-0 overflow-hidden'
              }}
            />
          </Card.Body>
          <Card.Footer>
            <AdvanceTablePagination table />
          </Card.Footer>
        </Card>
      </AdvanceTableWrapper>
    </>
  );
};

export default Blogs;
