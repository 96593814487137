import React, { useEffect, useRef, useState } from 'react';
import { Card, Col } from 'react-bootstrap';
import { toast } from 'react-toastify';
import ContentTableHeader from './ContentTableHeader';
import ContentHeader from './ContentHeader';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapperWithoutSelection';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import { CONTENT_LIST } from '../../../lib/query';
import { useQuery } from '@apollo/client';
import ContentEditable from 'react-contenteditable';
import { useMutation } from '@apollo/client';
import { UPDATE_BY_TEXT_TEXTURE } from '../../../lib/mutations';

const customStyles = {
  fontFamily: 'sans-serif',
  margin: '15px',
  minHeight: '50px',
  border: '1px dashed #aaa',
  padding: '1px 8px 1px 4px',
  width: '75%'
};

const paddingStyles = {
  padding: '1px 8px 1px 4px'
  //outline: 'none',
};

const ContentList = () => {
  const [updateTextureData, { data }] = useMutation(UPDATE_BY_TEXT_TEXTURE);
  const [getContentDatas, setGetContentDatas] = useState([]);
  // const [formData, setFormData] = useState('');
  const [defaultLocale, setDefaultLocale] = useState('mn');
  const { data: getContents, refetch } = useQuery(CONTENT_LIST, {
    variables: { locale: defaultLocale },
    fetchPolicy: 'network-only'
  });
  const text = useRef('');

  const updateCellData = evt => {
    const str = evt.target.value;
    const trimmedStr = str.trim();
    const plainText = trimmedStr.replace(/<[^>]*>/g, '');
    text.current = plainText;
  };

  const handleSubmit = (evt, _id, key) => {
    const str = text.current;
    const trimmedStr = str.trim();
    const plainText = trimmedStr.replace(/<[^>]*>/g, '');
    // const uniqueArray = formData.filter(
    //   (value, index, self) => self.indexOf(value) === index
    // );
    if (_id && text.current !== '') {
      updateTextureData({
        variables: {
          textureId: _id,
          key: key,
          value: plainText
        }
      });
    }
    text.current = '';
  };

  const columns = [
    {
      accessor: 'name',
      Header: 'Нэр',
      headerProps: { className: 'ml-5 pe-2' },
      Cell: rowData => {
        const { name } = rowData.row.original;
        return <>{name}</>;
      }
    },
    {
      accessor: 'locale',
      Header: 'Хэл',
      headerProps: { className: 'pe-2' },
      Cell: rowData => {
        const { locale } = rowData.row.original;
        return <>{locale}</>;
      }
    },
    {
      accessor: 'content',
      Header: 'Текст',
      headerProps: { className: 'pe-7' },
      style: { width: '200px' },
      Cell: rowData => {
        const { content } = rowData.row.original;
        return (
          <>
            {content.map((item, index) => (
              <div key={index} className="my-2">
                <span>{item.key}: </span>
              </div>
            ))}
          </>
        );
      }
    },
    {
      accessor: 'content1',
      Header: 'Текст1',
      headerProps: { className: 'pe-7' },
      style: { width: '200px' },
      Cell: rowData => {
        const { content, _id } = rowData.row.original;
        return (
          <>
            {content.map((item, index) => (
              <div key={index} className="my-2">
                <span style={customStyles}>
                  <ContentEditable
                    style={{ cursor: 'pointer', ...paddingStyles }}
                    html={item.value}
                    onChange={e => updateCellData(e)}
                    onBlur={e => handleSubmit(e, _id, item.key)}
                    tagName="span"
                  />
                </span>
              </div>
            ))}
          </>
        );
      }
    }
  ];

  useEffect(() => {
    if (getContents) {
      const data = getContents?.textureAll;

      if (data.length > 0) {
        setGetContentDatas(getContents.textureAll);
        // const newArray = getContents.textureAll
        //   .map(item => {
        //     return item.content.map(conne => conne.value);
        //   })
        //   .filter(Boolean);
        // const collectedArray = newArray.flat();
        // setFormData([...collectedArray.filter(item => item !== '')]);
      }
    }
  }, [getContents]);

  useEffect(() => {
    if (data) {
      if (data?.textureBulkUpdateByText) {
        toast.success(`Амжиллтай илгээлээ`, {
          theme: 'colored'
        });
        refetch();
      } else {
        toast.warning(`Ямар нэгэн алдаа гарлаа`, {
          theme: 'colored'
        });
      }
    }
  }, [data]);

  return (
    <>
      <Col xs={12} className="mb-3">
        <ContentHeader getMessageDatas={getContentDatas} />
      </Col>

      <AdvanceTableWrapper
        columns={columns}
        data={getContentDatas}
        selection
        sortable
        pagination
        perPage={20}
      >
        <Card className="mb-3">
          <Card.Header>
            <ContentTableHeader
              table
              getBlogDatas={getContentDatas}
              refetch={refetch}
              setDefaultLocale={setDefaultLocale}
              defaultLocale={defaultLocale}
            />
          </Card.Header>
          <Card.Body className="p-0">
            <AdvanceTable
              table
              headerClassName="bg-200 text-900 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                size: 'sm',
                striped: true,
                className: 'fs--1 mb-0 overflow-hidden'
              }}
            />
          </Card.Body>
          <Card.Footer>
            <AdvanceTablePagination table />
          </Card.Footer>
        </Card>
      </AdvanceTableWrapper>
    </>
  );
};

export default ContentList;
