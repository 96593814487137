import React, { useEffect, useState } from 'react';
import { Card, Dropdown, Col } from 'react-bootstrap';
import SoftBadge from 'components/common/SoftBadge';
import CardDropdown from 'components/common/CardDropdown';
import { Link } from 'react-router-dom';
import BlogTableHeader from './CompanyTableHeader';
import BlogHeader from './CompanyHeader';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import {
  COMPANY_BULK_UPDATE,
  COMPANY_BULK_DELETE,
  COMPANY_COMPLETE_PAYMENT
} from '../../../lib/mutations';
import { COMPANY_LIST } from '../../../lib/query';
import { useQuery, useMutation } from '@apollo/client';
import { toast } from 'react-toastify';

const CompanyList = () => {
  const [companyBulkUpdate, { data }] = useMutation(COMPANY_BULK_UPDATE);
  const [companyPaymentCompleted, { data: paymentData }] = useMutation(
    COMPANY_COMPLETE_PAYMENT
  );
  const [companyBulkDelete, { data: deletedData }] =
    useMutation(COMPANY_BULK_DELETE);
  const { data: getCompanies, refetch } = useQuery(COMPANY_LIST, {
    fetchPolicy: 'network-only'
  });
  const [getDatas, setGetDatas] = useState([]);

  useEffect(() => {
    if (data) {
      if (data?.companyBulkUpdate) {
        refetch();

        toast.success(`Амжиллтай засварлалаа`, {
          theme: 'colored'
        });
      } else {
        toast.warning(`Ямар нэгэн алдаа гарлаа`, {
          theme: 'colored'
        });
      }
    }

    if (paymentData) {
      if (paymentData?.companyPaymentCompleted.status.code === 200) {
        refetch();

        toast.success(`Төлбөр амжиллтай идэвхижлээ`, {
          theme: 'colored'
        });
      } else {
        toast.warning(paymentData?.companyPaymentCompleted.status.moreInfo, {
          theme: 'colored'
        });
      }
    }

    if (deletedData) {
      if (deletedData?.companyBulkDelete) {
        refetch();

        toast.success(`Амжиллтай устгагдлаа`, {
          theme: 'colored'
        });
      } else {
        toast.warning(`Ямар нэгэн алдаа гарлаа`, {
          theme: 'colored'
        });
      }
    }
  }, [data, deletedData, paymentData]);

  useEffect(() => {
    if (getCompanies?.companies?.status?.code === 200) {
      setGetDatas(getCompanies?.companies?.data);
    }
  }, [getCompanies]);

  const handleFieldChange = (value, companyId) => {
    if (value === 'delete') {
      companyBulkDelete({
        variables: {
          companyIds: [companyId]
        }
      });
    } else if (value === 'status') {
      companyPaymentCompleted({
        variables: {
          input: {
            status: true
          },
          companyId: companyId
        }
      });
    } else {
      companyBulkUpdate({
        variables: {
          actionType: value === 'active' ? true : false,
          companyIds: [companyId]
        }
      });
    }
  };

  const columns = [
    {
      accessor: 'name',
      Header: 'Мэдээний дугаар',
      headerProps: { className: 'pe-2' },
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        const { _id } = rowData.row.original;
        return (
          <>
            <Link to={`/company/company-update/${_id}`}>
              <strong>#{_id.slice(-6)}</strong>
            </Link>{' '}
          </>
        );
      }
    },
    // {
    //   accessor: 'domain',
    //   Header: 'Домайн',
    //   headerProps: { className: 'pe-7' },
    //   Cell: rowData => {
    //     const { domain } = rowData.row.original;
    //     return <>{domain}</>;
    //   }
    // },

    {
      accessor: 'address',
      Header: 'Гарчиг',
      Cell: rowData => {
        const { name } = rowData.row.original;
        return <>{name.substring(0, 40)}</>;
      }
    },
    {
      accessor: 'payment status',
      Header: 'Төлбөр идэвхжсэн',
      headerProps: { className: 'pe-3' },
      Cell: rowData => {
        const { status } = rowData.row.original.payment;
        return status ? (
          <SoftBadge pill bg="success" className="me-2">
            идэвхитэй
          </SoftBadge>
        ) : (
          <SoftBadge pill bg="secondary" className="me-2">
            идэвхигүй
          </SoftBadge>
        );
      }
    },
    {
      accessor: 'payment money',
      Header: 'Төлбөр',
      headerProps: { className: 'pe-3' },
      Cell: rowData => {
        const { paidmoney } =
          rowData.row.original.payment.paymently[
            rowData.row.original.payment.paymently.length - 1
          ];
        return <>{Math.abs(paidmoney) + ' төг'}</>;
      }
    },
    {
      accessor: 'payment until date',
      Header: 'Төлбөр хугацаа',
      headerProps: { className: 'pe-3' },
      Cell: rowData => {
        const { untilPeriod } =
          rowData.row.original.payment.paymently[
            rowData.row.original.payment.paymently.length - 1
          ];
        const { _id } = rowData.row.original;
        return (
          <Link to={`/company/company-payment/${_id}`}>
            <strong>{untilPeriod.split('T')[0]}</strong>
          </Link>
        );
      }
    },
    {
      accessor: 'status',
      Header: 'Төлөв',
      headerProps: { className: 'pe-3' },
      Cell: rowData => {
        const { status } = rowData.row.original;
        return status ? (
          <SoftBadge pill bg="success" className="me-2">
            Нээлттэй
          </SoftBadge>
        ) : (
          <SoftBadge pill bg="secondary" className="me-2">
            Хаалттай
          </SoftBadge>
        );
      }
    },
    {
      accessor: 'email',
      Header: 'Имэйл',
      Cell: rowData => {
        const { email } = rowData.row.original;
        return <>{email}</>;
      }
    },
    {
      accessor: 'amount',
      Header: 'Нийтэлсэн',
      headerProps: {
        className: 'text-end'
      },
      cellProps: {
        className: 'text-end fs--1 fw-medium py-2'
      },
      Cell: rowData => `${rowData.row.original?.createdAtBy?.email}`
    },
    {
      accessor: 'none',
      Header: '',
      disableSortBy: true,
      cellProps: {
        className: 'text-end'
      },
      Cell: rowData => {
        const { _id: companyId } = rowData.row.original;
        return (
          <CardDropdown>
            <div className="py-2">
              <Dropdown.Item
                onClick={() => handleFieldChange('status', companyId)}
              >
                Төлбөр идэвхижүүлэх
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => handleFieldChange('active', companyId)}
              >
                Нээх
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => handleFieldChange('close', companyId)}
              >
                Хаах
              </Dropdown.Item>
              <Dropdown.Divider as="div" />
              <Dropdown.Item
                onClick={() => handleFieldChange('delete', companyId)}
                className="text-danger"
              >
                Устгах
              </Dropdown.Item>
            </div>
          </CardDropdown>
        );
      }
    }
  ];

  return (
    <>
      <Col xs={12} className="mb-3">
        <BlogHeader />
      </Col>

      <AdvanceTableWrapper
        columns={columns}
        data={getDatas}
        selection
        sortable
        pagination
        perPage={20}
      >
        <Card className="mb-3">
          <Card.Header>
            <BlogTableHeader table getBlogDatas={getDatas} refetch={refetch} />
          </Card.Header>
          <Card.Body className="p-0">
            <AdvanceTable
              table
              headerClassName="bg-200 text-900 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                size: 'sm',
                striped: true,
                className: 'fs--1 mb-0 overflow-hidden'
              }}
            />
          </Card.Body>
          <Card.Footer>
            <AdvanceTablePagination table />
          </Card.Footer>
        </Card>
      </AdvanceTableWrapper>
    </>
  );
};

export default CompanyList;
