import React, { useEffect, useState } from 'react';
import { Card, Col } from 'react-bootstrap';
import SoftBadge from 'components/common/SoftBadge';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import MessageTableHeader from './MessageTableHeader';
import MessageHeader from './MessageHeader';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import { MESSAGE_LIST } from '../../../lib/query';
import { useQuery } from '@apollo/client';
import usePermission from 'components/common/usePermission';

const MessageList = () => {
  const { authorized } = usePermission('M21');
  const { data: getMessages, refetch } = useQuery(MESSAGE_LIST, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first'
  });

  const [getMessageDatas, setGetMessageDatas] = useState([]);

  useEffect(() => {
    if (getMessages?.messages?.status?.code === 200) {
      setGetMessageDatas(getMessages?.messages?.data);
    }
  }, [getMessages]);

  const columns = [
    {
      accessor: '_id',
      Header: 'Зурвас дугаар',
      headerProps: { className: 'pe-5' },
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        const { _id } = rowData.row.original;
        return (
          <>
            {authorized ? (
              <strong className="text-primary">#{_id.slice(-6)}</strong>
            ) : (
              <Link to={`/message/message-detail/${_id}`}>
                <strong>#{_id.slice(-6)}</strong>
              </Link>
            )}
          </>
        );
      }
    },
    {
      accessor: 'date',
      Header: 'Огноо',
      headerProps: { className: 'pe-7' },
      Cell: rowData => {
        const { createdAt } = rowData.row.original;
        return <>{createdAt.split('T')[0]}</>;
      }
    },
    {
      accessor: 'name',
      Header: 'Нэр',
      headerProps: { className: 'pe-7' },
      Cell: rowData => {
        const { name } = rowData.row.original;
        return <>{name}</>;
      }
    },
    {
      accessor: 'phone',
      Header: 'Утас',
      headerProps: { className: 'pe-7' },
      Cell: rowData => {
        const { phone } = rowData.row.original;
        return <>{phone}</>;
      }
    },
    {
      accessor: 'email',
      Header: 'Имэйл',
      headerProps: { className: 'pe-7' },
      Cell: rowData => {
        const { email } = rowData.row.original;
        return <>{email}</>;
      }
    },
    {
      accessor: 'content',
      Header: 'Контент',
      headerProps: { className: 'pe-7' },
      Cell: rowData => {
        const { content } = rowData.row.original;
        if (content === null) {
          return null;
        }
        return <>{content.substring(0, 40)}</>;
      }
    },
    {
      accessor: 'status',
      Header: 'Төлөв',
      headerProps: {
        className: 'text-center pe-2'
      },
      cellProps: {
        className: 'fs-0'
      },
      Cell: rowData => {
        const { status } = rowData.row.original;
        return (
          <SoftBadge
            pill
            bg={classNames({
              success: status === true,
              secondary: status === false
            })}
            className="d-flex flex-center"
          >
            <p className="mb-0">
              {status === true && 'шинэ'}
              {status === false && 'хуучин'}
            </p>
          </SoftBadge>
        );
      }
    }
  ];

  return (
    <>
      <Col xs={12} className="mb-3">
        <MessageHeader getMessageDatas={getMessageDatas} />
      </Col>

      <AdvanceTableWrapper
        columns={columns}
        data={getMessageDatas}
        selection
        sortable
        pagination
        perPage={20}
      >
        <Card className="mb-3">
          <Card.Header>
            <MessageTableHeader
              table
              getBlogDatas={getMessageDatas}
              refetch={refetch}
            />
          </Card.Header>
          <Card.Body className="p-0">
            <AdvanceTable
              table
              headerClassName="bg-200 text-900 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                size: 'sm',
                striped: true,
                className: 'fs--1 mb-0 overflow-hidden'
              }}
            />
          </Card.Body>
          <Card.Footer>
            <AdvanceTablePagination table />
          </Card.Footer>
        </Card>
      </AdvanceTableWrapper>
    </>
  );
};

export default MessageList;
