import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import SoftBadge from 'components/common/SoftBadge';

const EventDetailContent = ({ getMessage }) => {
  return (
    <Card>
      <Card.Body>
        <h5 className="fs-0 mb-3">Нэр</h5>
        <p>{getMessage?.name}</p>

        <h5 className="fs-0 mb-3">Бичвэр</h5>
        <p>{getMessage?.content}</p>

        <h5 className="fs-0 mt-5 mb-2 ">Төлөв </h5>
        {getMessage?.status ? (
          <SoftBadge bg="success" className="me-2">
            Шинэ
          </SoftBadge>
        ) : (
          <SoftBadge bg="secondary" className="me-2">
            Үзсэн
          </SoftBadge>
        )}

        {/* <h5 className="fs-0 mt-5 mb-2 ">Share with friends </h5>
        <IconGroup icons={socialIcons} />
        <EventDetailsGmap /> */}
      </Card.Body>
    </Card>
  );
};

EventDetailContent.propTypes = {
  getMessage: PropTypes.object
};

export default EventDetailContent;
