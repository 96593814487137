import React, { useEffect, useState, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { Card, Dropdown, ListGroup } from 'react-bootstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import { NOTIFICATION_ALL, NEW_SUBSCRIBITION } from '../../../lib/query';
import { NOTIFICATION_BULK_UPDATE } from '../../../lib/mutations';
import { useQuery, useSubscription, useMutation } from '@apollo/client';
import Notification from './Notification';
import { toast } from 'react-toastify';
import { AuthWizardContext } from '../../../context/Context';

const NotificationDropdown = () => {
  const [notificationBulkUpdateData, { data: updatedNotificationDatas }] =
    useMutation(NOTIFICATION_BULK_UPDATE);
  const { data: subsciberNotifications } = useSubscription(NEW_SUBSCRIBITION);
  const { data: getNotificationAll, refetch } = useQuery(NOTIFICATION_ALL);
  const { company } = useContext(AuthWizardContext);
  const [isOpen, setIsOpen] = useState(false);
  const [isAllRead, setIsAllRead] = useState(false);
  const [getNotifications, setGetNotifications] = useState([]);
  const [newNotifications, setNewNotifications] = useState([]);
  const [newNumber, setNewNumber] = useState(0);
  // Handler
  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (getNotificationAll?.notificationAll?.status?.code === 200) {
      const oldNotifications = getNotificationAll?.notificationAll?.data.filter(
        item => item.status === false
      );
      const newNotifications = getNotificationAll?.notificationAll?.data.filter(
        item => item.status === true
      );
      newNotifications.length > 0 && setIsAllRead(true);
      setNewNumber(newNotifications.length);
      setGetNotifications(oldNotifications);
      setNewNotifications(newNotifications);
    }
  }, [getNotificationAll]);

  useEffect(() => {
    if (updatedNotificationDatas) {
      if (updatedNotificationDatas?.notificationBulkUpdate) {
        refetch();
        toast.success(`Үйлдэл амжиллтай`, {
          theme: 'colored'
        });
      } else {
        toast.warning(`Ямар нэгэн алдаа гарлаа`, {
          theme: 'colored'
        });
      }
    }
  }, [updatedNotificationDatas]);

  useEffect(() => {
    if (
      subsciberNotifications?.newMessageComing &&
      subsciberNotifications?.newMessageComing?.companyId === company?._id
    ) {
      refetch();
      toast.success(`Шинэ зурвас ирлээ`, {
        theme: 'colored'
      });
      setIsAllRead(false);
    }
  }, [subsciberNotifications]);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      window.innerWidth < 1200 && setIsOpen(false);
    });
  }, []);

  const markAsRead = e => {
    e.preventDefault();
    if (newNotifications.length > 0) {
      const newIds = newNotifications.map(item => item._id);
      notificationBulkUpdateData({
        variables: {
          notificationIds: newIds
        }
      });
    }
    newNotifications.length === 0 && setIsAllRead(false);
  };

  const handMarkAsRead = notificationId => {
    if (newNotifications.length > 0) {
      const newIds = [notificationId];
      notificationBulkUpdateData({
        variables: {
          notificationIds: newIds
        }
      });
    }
  };

  const handleGoTo = url => {
    window.location.assign(url);
  };

  return (
    <Dropdown navbar={true} as="li" show={isOpen} onToggle={handleToggle}>
      <Dropdown.Toggle
        bsPrefix="toggle"
        as={Link}
        to="#!"
        className={classNames('px-0 nav-link', {
          'notification-indicator notification-indicator-danger': isAllRead
        })}
      >
        {isAllRead && (
          <span className="notification-indicator-number">
            {newNumber > 9 ? 9 : newNumber}
          </span>
        )}
        <FontAwesomeIcon icon="bell" transform="shrink-6" className="fs-4" />
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-menu-card dropdown-menu-end dropdown-caret dropdown-caret-bg">
        <Card
          className="dropdown-menu-notification dropdown-menu-end shadow-none"
          style={{ maxWidth: '20rem' }}
        >
          <FalconCardHeader
            className="card-header"
            title="Мэдэгдэл"
            titleTag="h6"
            light={false}
            endEl={
              <Link
                className="card-link fw-normal"
                to="#!"
                onClick={markAsRead}
              >
                {newNotifications.length > 0 && 'Уншисан гэж тэмдэглэх'}
              </Link>
            }
          />
          <ListGroup
            variant="flush"
            className="fw-normal fs--1 scrollbar"
            style={{ maxHeight: '19rem' }}
          >
            {newNotifications.length > 0 && (
              <div className="list-group-title">Шинэ</div>
            )}
            {newNotifications.length > 0 &&
              newNotifications.map(notification => (
                <ListGroup.Item
                  key={notification._id}
                  onClick={() => handMarkAsRead(notification._id)}
                >
                  <Notification
                    {...notification}
                    unread={true}
                    flush
                    className={
                      'rounded-0 border-x-0 border-300 border-bottom-0'
                    }
                  />
                </ListGroup.Item>
              ))}
            {getNotifications.length > 0 && (
              <div className="list-group-title">Хуучин</div>
            )}
            {getNotifications.length > 0 &&
              getNotifications.map(notification => (
                <ListGroup.Item
                  key={notification._id}
                  onClick={() => handleGoTo(notification?.modelUrl)}
                >
                  <Notification
                    {...notification}
                    unread={true}
                    flush
                    className={
                      'rounded-0 border-x-0 border-300 border-bottom-0'
                    }
                  />
                </ListGroup.Item>
              ))}
          </ListGroup>
          <div
            className="card-footer text-center border-top"
            onClick={handleToggle}
          >
            <Link className="card-link d-block" to="/message/message-list">
              Бүгдийг харах
            </Link>
          </div>
        </Card>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default NotificationDropdown;
