import IconButton from 'components/common/IconButton';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Col, Form, Row, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useMutation } from '@apollo/client';
import {
  CUSTOMER_BULK_UPDATE,
  CUSTOMER_BULK_DELETE
} from '../../../lib/mutations';
import usePermission from 'components/common/usePermission';

const CustomersTableHeader = ({ selectedRowIds, customers, refetch }) => {
  const { authorized, styles } = usePermission('U11');
  const { authorized: updatedAuthorized } = usePermission('U21');
  const { authorized: deleteAuthorized } = usePermission('U31');
  const navigage = useNavigate();
  const [customerBulkUpdate, { data }] = useMutation(CUSTOMER_BULK_UPDATE);
  const [customerBulkDelete, { data: deletedData }] =
    useMutation(CUSTOMER_BULK_DELETE);
  const [actionType, setActionType] = useState('editable');
  const keyArray = Object.keys(selectedRowIds).map(Number);
  const newUpdateIds = keyArray.map(item => {
    return customers[item]?._id;
  });

  useEffect(() => {
    if (data) {
      if (data?.usersBulkUpdate) {
        refetch();

        toast.success(`Амжиллтай засварлалаа`, {
          theme: 'colored'
        });
      } else {
        toast.warning(`Ямар нэгэн алдаа гарлаа`, {
          theme: 'colored'
        });
      }
    }
    if (deletedData) {
      if (deletedData?.usersBulkDelete) {
        refetch();

        toast.success(`Амжиллтай устгагдлаа`, {
          theme: 'colored'
        });
      } else {
        toast.warning(`Ямар нэгэн алдаа гарлаа`, {
          theme: 'colored'
        });
      }
    }
  }, [data, deletedData]);

  const handleSubmit = e => {
    e.preventDefault();
    if (newUpdateIds.length > 0 && actionType !== 'editable') {
      if (actionType !== 'delete') {
        customerBulkUpdate({
          variables: {
            actionType: actionType === 'active' ? true : false,
            usersIds: newUpdateIds
          }
        });
      } else {
        customerBulkDelete({
          variables: {
            usersIds: newUpdateIds
          }
        });
      }
    } else {
      toast.warning(`Засварлах утгаа сонгоно уу`, {
        theme: 'colored'
      });
    }
  };

  const handleFieldChange = e => {
    setActionType(e.target.value);
  };

  const handleGoTo = () => {
    navigage('/customers/customer-invite');
  };
  return (
    <Row className="flex-between-center">
      <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
        <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">Ажилтан</h5>
      </Col>
      <Col xs={8} sm="auto" className="ms-auto text-end ps-0">
        {Object.keys(selectedRowIds).length > 0 ? (
          <div className="d-flex">
            <Form.Select
              size="sm"
              aria-label="Bulk actions"
              onChange={handleFieldChange}
            >
              <option value="editable">Засварлах</option>
              <option disabled={updatedAuthorized} value="active">
                Нээх
              </option>
              <option disabled={updatedAuthorized} value="closed">
                Хаах
              </option>
              <option disabled={deleteAuthorized} value="delete">
                Устгах
              </option>
            </Form.Select>
            <Button
              type="button"
              variant="falcon-default"
              size="sm"
              className="ms-2"
              onClick={handleSubmit}
              style={
                actionType === 'delete' && deleteAuthorized
                  ? { cursor: 'not-allowed', opacity: '0.5' }
                  : undefined
              }
              disabled={actionType === 'delete' && deleteAuthorized}
            >
              Хадгалах
            </Button>
          </div>
        ) : (
          <div id="orders-actions">
            <IconButton
              variant="falcon-default"
              size="sm"
              icon="plus"
              transform="shrink-3"
              onClick={handleGoTo}
              style={styles}
              disabled={authorized}
            >
              <span className="d-none d-sm-inline-block ms-1">
                Ажилтан нэмэх
              </span>
            </IconButton>
            <IconButton
              variant="falcon-default"
              size="sm"
              icon="filter"
              transform="shrink-3"
              className="mx-2"
            >
              <span className="d-none d-sm-inline-block ms-1">Шүүх</span>
            </IconButton>
            {/* <IconButton
              variant="falcon-default"
              size="sm"
              icon="external-link-alt"
              transform="shrink-3"
            >
              <span className="d-none d-sm-inline-block ms-1">Экспорт</span>
            </IconButton> */}
          </div>
        )}
      </Col>
    </Row>
  );
};

CustomersTableHeader.propTypes = {
  selectedRowIds: PropTypes.object,
  customers: PropTypes.array,
  refetch: PropTypes.func
};

export default CustomersTableHeader;
