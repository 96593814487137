import React, { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import CompanyPaymentInformation from './NewCompanyForms/CompanyPaymentInformation';
import CompanyPaymentHeader from './NewCompanyForms/CompanyPaymentHeader';
import CompanyDescription from './NewCompanyForms/CompanyDescription';
import CompanyPaymentDetail from './NewCompanyForms/CompanyPaymentDetail';
import { useMutation, useQuery } from '@apollo/client';
import { COMPANY_PAYMENT_UPDATE } from '../../../lib/mutations';
import { COMPANY_BY_ID } from 'lib/query';
import { useNavigate } from 'react-router-dom';

const schema = yup
  .object({
    fromBankAccount: yup.string().required('Энэ талбарыг оруулна уу!'),
    paidmoney: yup.string().required('Энэ талбарыг оруулна уу!'),
    description: yup.string().required('Энэ талбарыг оруулна уу!')
  })
  .required();

const CompanyPayment = () => {
  let navigage = useNavigate();
  const { companyId } = useParams();
  const [updatePaymentCompanyData, { data }] = useMutation(
    COMPANY_PAYMENT_UPDATE
  );
  const { data: getCompanyOne } = useQuery(COMPANY_BY_ID, {
    variables: { companyId }
  });
  const [payment, setPayment] = useState({});
  const submittedValues = {};
  const methods = useForm({
    resolver: yupResolver(schema)
  });
  const { handleSubmit, reset } = methods;

  const onSubmit = data => {
    if (Object.keys(data).length === 0) {
      toast.warning('Та мэдээллээ гүйцэт оруулна уу', {
        theme: 'colored'
      });
    } else {
      updatePaymentCompanyData({
        variables: {
          input: {
            fromBankAccount: parseFloat(data.fromBankAccount),
            paidmoney: parseFloat(data.paidmoney),
            isPaidMoney: 'Төлсөн',
            description: data.description
          },
          companyId
        }
      });
      const keys = Object.keys(data);
      for (const key of keys) {
        submittedValues[key] = '';
      }
      reset({ ...submittedValues });
    }
  };

  useEffect(() => {
    if (getCompanyOne) {
      const {
        fromBankAccount,
        paidmoney: newPaidMoney,
        isPaidMoney,
        description
      } = getCompanyOne.companyById.data.payment.paymently[
        getCompanyOne.companyById.data.payment.paymently.length - 1
      ];
      setPayment(getCompanyOne.companyById.data.payment);
      const paidmoney = Math.abs(newPaidMoney);
      reset({
        fromBankAccount,
        paidmoney,
        isPaidMoney,
        description
      });
    }
  }, [getCompanyOne, reset]);

  useEffect(() => {
    if (data) {
      if (data?.companySetPayment?.status?.code === 200) {
        toast.success(`Амжиллтай илгээлээ`, {
          theme: 'colored'
        });
        navigage('/company/company-list');
      } else {
        toast.warning(`${data?.companySetPayment?.status?.moreInfo}`, {
          theme: 'colored'
        });
      }
    }
  }, [data]);

  return (
    <FormProvider {...methods}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row className="g-3">
          <Col xs={12}>
            <CompanyPaymentHeader />
          </Col>
          <Col lg={8}>
            <CompanyPaymentInformation />
            <CompanyPaymentDetail payment={payment && payment} />
          </Col>
          <Col lg={4}>
            <div className="sticky-sidebar">
              <CompanyDescription />
            </div>
          </Col>
        </Row>
      </Form>
    </FormProvider>
  );
};

export default CompanyPayment;
