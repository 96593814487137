import React, { useEffect, useContext, useState } from 'react';
import {
  Button,
  Col,
  Card,
  Collapse,
  Form,
  Row,
  ProgressBar
} from 'react-bootstrap';
import InputField from '../../common/InputFields';
import { v4 as uuid } from 'uuid';
import TooltipBadge from 'components/common/TooltipBadge';
import { useMutation } from '@apollo/client';
import { CUSTOMER_INVITE_SET_PASSWORD } from '../../../lib/mutations';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';
import { AuthWizardContext } from '../../../context/Context';

function checkValue(value, item) {
  const newArr = item.split('W')[1];
  const first = value.split('')[0];
  const two = value.split('')[1];
  const foo = newArr[first] === two;
  return foo;
}

function calculateRoleName(array) {
  const newArray = array.map(item => parseInt(item.split('W')[1]));
  const sum = newArray.reduce(
    (accumulator, currentValue) => accumulator + currentValue,
    0
  );
  return findRoleName(sum);
}

function calculateProcent(array) {
  const full = 6666;
  let result = 0;
  const newArray = array.map(item => parseInt(item.split('W')[1]));
  const sum = newArray.reduce(
    (accumulator, currentValue) => accumulator + currentValue,
    0
  );
  result = Number((100 * sum) / full);
  return result;
}

function findRoleName(sum) {
  if (sum > 0 && sum <= 3333) {
    return 'Ажилчин';
  } else if (sum >= 3333 && sum <= 4444) {
    return 'Нийтлэгч';
  } else if (sum >= 4444 && sum <= 6666) {
    return 'Админ';
  }
}

function findSendRoleName(roleName) {
  switch (roleName) {
    case 'Ажилчин':
      return 'Worker';
    case 'Нийтлэгч':
      return 'Publisher';
    case 'Админ':
      return 'Admin';
    default:
      return 'Worker';
  }
}

const ExperienceForm = () => {
  const getCompany = Cookies.get('company');
  const cookieCompany = getCompany ? JSON.parse(getCompany) : null;
  const { company } = useContext(AuthWizardContext);
  const getUser = Cookies.get('user');
  const user = getUser ? JSON.parse(getUser) : null;
  const [inviteSetPassword, { data }] = useMutation(
    CUSTOMER_INVITE_SET_PASSWORD
  );
  const [permissionArray, setPermissionArray] = useState([
    'MW1000',
    'PW1000',
    'CW1000',
    'BW0000',
    'SW0000',
    'UW0000'
  ]);

  const [formData, setFormData] = useState({
    email: '',
    firstName: '',
    lastName: '',
    password: '123123'
  });
  const [roleName, setRoleName] = useState();

  useEffect(() => {
    const newName = calculateRoleName(permissionArray);
    setRoleName(newName);
  }, [permissionArray]);

  useEffect(() => {
    if (data) {
      if (data?.customerInviteByEmail?.status?.code === 200) {
        toast.success(
          `Таны хүсэлт тус ${formData.email} имэйл рүү амжилттай илгээгдлээ`,
          {
            theme: 'colored'
          }
        );
      } else {
        toast.warning(`${data?.customerInviteByEmail?.status?.moreInfo}`, {
          theme: 'colored'
        });
      }
    }
  }, [data]);

  const convertToTitle = content => {
    switch (content.charAt(0)) {
      case 'M':
        return 'Зурвас';
      case 'P':
        return 'Каталог';
      case 'C':
        return 'Контент';
      case 'B':
        return 'Мэдээлэл';
      case 'S':
        return 'Тохиргоо';
      case 'U':
        return 'Хэрэглэгч';
      default:
        return '';
    }
  };

  const handleChange = e => {
    const filteredArray = permissionArray.filter(item =>
      item.includes(e.target.value)
    );
    const newArray = filteredArray[0].split('W');
    const newToo = newArray[1].split('');
    if (!checkValue(e.target.name, filteredArray[0])) {
      newToo[e.target.name.split('')[0]] = e.target.name.split('')[1];
    } else {
      newToo[e.target.name.split('')[0]] = 0;
    }

    const newCipher = e.target.value + 'W' + newToo.join('');

    const convertedArray = permissionArray.map(item => {
      if (item.startsWith(e.target.value.charAt(0))) {
        return newCipher;
      }
      return item;
    });

    setPermissionArray(convertedArray);
  };
  const handleSubmit = e => {
    e.preventDefault();
    inviteSetPassword({
      variables: {
        input: {
          email: formData.email,
          firstName: formData.firstName,
          lastName: formData.lastName,
          password: formData.password,
          role: {
            value: findSendRoleName(roleName),
            permission: permissionArray
          },
          companyId: company?._id ? company._id : cookieCompany._id
        },
        inviteEmail: user.email
      }
    });
    setFormData({
      email: '',
      firstName: '',
      lastName: '',
      password: '123123'
    });
    setPermissionArray([
      'MW1000',
      'PW1000',
      'CW1000',
      'BW0000',
      'SW0000',
      'UW0000'
    ]);
  };

  const handleChangeEmail = e =>
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });

  return (
    <Row className="g-3">
      <Col lg={12}>
        <Card className="h-100">
          <Card.Header>
            <h5 className="mb-0">Ажилтан нэмэх</h5>
          </Card.Header>
          <Card.Body className="bg-light pb-0">
            <div>
              <Collapse in={true}>
                <div>
                  {/* <ExperienceForm /> */}
                  <Form onSubmit={handleSubmit}>
                    <InputField
                      type="email"
                      value={formData.email}
                      label="Имэйл хаяг"
                      name="email"
                      handleChange={handleChangeEmail}
                    />

                    <InputField
                      type="text"
                      value={formData.firstName}
                      label="Нэр"
                      name="firstName"
                      handleChange={handleChangeEmail}
                    />

                    <InputField
                      type="text"
                      value={formData.lastName}
                      label="Овог"
                      name="lastName"
                      handleChange={handleChangeEmail}
                    />

                    <Form.Group as={Row} className="mb-3">
                      <Col sm={{ span: 7, offset: 3 }}>
                        <Button type="submit">Ажилтанд илгээх</Button>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="my-5">
                      <Col sm={{ span: 7, offset: 3 }}>
                        <Form.Label>{roleName}</Form.Label>
                      </Col>
                      <Col sm={{ span: 7, offset: 3 }}>
                        <ProgressBar
                          variant="success"
                          now={calculateProcent(permissionArray)}
                          label={
                            parseInt(calculateProcent(permissionArray)) + '%'
                          }
                        />
                      </Col>
                    </Form.Group>
                    {permissionArray.length > 0 &&
                      permissionArray.map((item, i) => (
                        <Form.Group as={Row} className="mb-3" key={i}>
                          <Col sm={{ span: 7, offset: 3 }}>
                            <div className="border-dashed border-bottom my-3 pe-2" />
                            <h6 className="fw-bold">
                              {convertToTitle(item)}
                              <TooltipBadge
                                tooltip="Only The group of selected people can see your profile"
                                icon="question-circle"
                              />
                            </h6>
                            <div className="ps-2">
                              <Form.Check
                                type="checkbox"
                                id={`invalidCheck-${uuid()}`}
                                label="Унших"
                                className="form-label-nogutter"
                                name="01"
                                value={item.split('W')[0]}
                                onChange={handleChange}
                                checked={checkValue('01', item)}
                              />
                              <Form.Check
                                type="checkbox"
                                id={`invalidCheck-${uuid()}`}
                                label="Үүсгэх"
                                className="form-label-nogutter"
                                name="11"
                                value={item.split('W')[0]}
                                onChange={handleChange}
                                checked={checkValue('11', item)}
                              />
                              <Form.Check
                                type="checkbox"
                                id={`invalidCheck-${uuid()}`}
                                label="Засварлах"
                                className="form-label-nogutter"
                                name="21"
                                value={item.split('W')[0]}
                                onChange={handleChange}
                                checked={checkValue('21', item)}
                              />
                              <Form.Check
                                type="checkbox"
                                id={`invalidCheck-${uuid()}`}
                                label="Устгах"
                                className="form-label-nogutter"
                                name="31"
                                value={item.split('W')[0]}
                                onChange={handleChange}
                                checked={checkValue('31', item)}
                              />
                            </div>
                          </Col>
                        </Form.Group>
                      ))}
                  </Form>
                </div>
              </Collapse>
            </div>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default ExperienceForm;
