import React, { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import ProductCatalogBasicInformation from './NewProductCatalogForms/ProductCatalogBasicInformation';
import ProductCatalogHeader from './NewProductCatalogForms/ProductCatalogHeader';
import ProductCatalogUpdateUpload from './NewProductCatalogForms/ProductCatalogUpdateUpload';
import ProductCatalogDetails from './NewProductCatalogForms/ProductCatalogDetails';
import ProductCatalogType from './NewProductCatalogForms/ProductCatalogType';
import ProductCatalogTags from './NewProductCatalogForms/ProductCatalogTags';
import ProductCatalogPrice from './NewProductCatalogForms/ProductCatalogPrice';
import { useMutation, useQuery } from '@apollo/client';
import {
  PRODUCT_CATALOG_UPDATE,
  PRODUCT_CATALOG_BY_ID
} from '../../../lib/mutations';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Resizer from 'react-image-file-resizer';

const schema = yup
  .object({
    name: yup.string().required('Энэ талбарыг оруулна уу!'),
    description: yup.string().required('Энэ талбарыг оруулна уу!'),
    price: yup.string().required('Энэ талбарыг оруулна уу!'),
    quantity: yup.string().required('Энэ талбарыг оруулна уу!')
  })
  .required();

// const cloudinaryURLToDropzoneFile = async (url, filename) => {
//   return await fetch(url)
//     .then(response => response.blob())
//     .then(blob => {
//       const file = new File([blob], filename, { type: blob.type });
//       return file;
//     });
// };

const BlogUpdate = () => {
  let navigage = useNavigate();
  const { productId } = useParams();
  const [updateCatalogData, { data }] = useMutation(PRODUCT_CATALOG_UPDATE);
  const { data: getCatalogOne } = useQuery(PRODUCT_CATALOG_BY_ID, {
    variables: { productId: productId }
  });
  // images
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const [imageUrls, setImageUrls] = useState([]);
  const submittedValues = {};
  const methods = useForm({
    resolver: yupResolver(schema)
  });
  const { handleSubmit, reset } = methods;

  const imageUploader = image => {
    setLoading(true);
    Resizer.imageFileResizer(
      image,
      800,
      600,
      'WEBP',
      100,
      0,
      uri => {
        axios
          .post(`${process.env.REACT_APP_FILE_URL}/uploadimage`, {
            image: uri
          })
          .then(response => {
            if (response.data?.url && response.data?.public_id) {
              const newImageUrl = {
                url: String(response?.data?.url),
                public_id: String(response?.data?.public_id),
                bucketname: image.name
              };

              setImageUrls(prevUrls => [...prevUrls, newImageUrl]);
              setLoading(false);
            }
          })
          .catch(error => {
            console.log('CLOUDINARY UPLOAD FAILED', error);
          });
      },
      'base64',
      400,
      300
    );
  };

  const onSubmit = data => {
    if (Object.keys(data).length === 0) {
      toast.warning('Та мэдээллээ гүйцэт оруулна уу', {
        theme: 'colored'
      });
    } else {
      updateCatalogData({
        variables: {
          input: {
            price: parseFloat(data.price),
            description: data.description,
            image: imageUrls,
            quantity: parseFloat(data.quantity),
            name: data.name,
            tags:
              data.tags && Array.isArray(data.tags) && data.tags.length > 0
                ? [...data.tags.map(item => item.value)]
                : []
          },
          productId
        }
      });

      const keys = Object.keys(data);
      for (const key of keys) {
        submittedValues[key] = '';
      }
      reset({ ...submittedValues });
    }
  };

  useEffect(() => {
    if (getCatalogOne) {
      const { name, description, price, quantity, image, tags } =
        getCatalogOne.productCatalogById.data;
      reset({
        name,
        description,
        price,
        quantity,
        tags: tags.map(tag => ({ value: tag, label: tag }))
      });
      const buffer = [];
      image.forEach(item => {
        const newFile = {};
        Object.assign(newFile, {
          path: item.bucketname,
          preview: item.url
        });
        buffer.push(newFile);
      });
      const updatedData = image.map(item => {
        const obj = {};
        obj.url = item.url;
        obj.bucketname = item.bucketname;
        obj.public_id = item.public_id;
        return obj;
      });
      setImageUrls(updatedData);
      setFiles(buffer);
    }
  }, [getCatalogOne, reset]);

  useEffect(() => {
    if (data) {
      if (data?.productCatalogUpdate?.status?.code === 200) {
        toast.success(`Амжиллтай илгээлээ`, {
          theme: 'colored'
        });
        setTimeout(() => {
          navigage('/product-catalog/product-list');
        }, 2000);
      } else {
        toast.warning(`${data?.productCatalogUpdate?.status?.moreInfo}`, {
          theme: 'colored'
        });
      }
    }
  }, [data]);

  const handleRemove = path => {
    let removeItem = '';
    imageUrls.forEach(item => {
      if (item.bucketname === path) {
        removeItem = item.public_id;
      }
    });
    setFiles(files.filter(file => file.path !== path));
    const updatedImageUrls = imageUrls.filter(
      image => image.bucketname !== path
    );
    setImageUrls(updatedImageUrls);
    if (removeItem) {
      axios
        .post(`${process.env.REACT_APP_FILE_URL}/removeimage`, {
          public_id: removeItem
        })
        .then(response => {
          console.log(response);
        })
        .catch(error => {
          console.log('CLOUDINARY UPLOAD FAILED', error);
        });
    }
  };

  return (
    <FormProvider {...methods}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row className="g-3">
          <Col xs={12}>
            <ProductCatalogHeader />
          </Col>
          <Col lg={8}>
            <ProductCatalogBasicInformation />
            <ProductCatalogUpdateUpload
              setFiles={setFiles}
              files={files}
              imageUploader={imageUploader}
              handleRemove={handleRemove}
              loading={loading}
            />
            <ProductCatalogDetails />
          </Col>
          <Col lg={4}>
            <div className="sticky-sidebar">
              <ProductCatalogType />
              <ProductCatalogPrice />
              <ProductCatalogTags />
            </div>
          </Col>
        </Row>
      </Form>
    </FormProvider>
  );
};

export default BlogUpdate;
