import React, { useEffect, useState } from 'react';
import { Card, Dropdown, Col } from 'react-bootstrap';
import { toast } from 'react-toastify';
import CardDropdown from 'components/common/CardDropdown';
import SoftBadge from 'components/common/SoftBadge';
import { Link } from 'react-router-dom';
import ProductCatalogTableHeader from './ProductCatalogTableHeader';
import ProductCatalogHeader from './ProductCatalogHeader';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import { PRODUCT_CATALOG_LIST } from '../../../lib/query';
import {
  PRODUCT_CATALOG_BULK_DELETE,
  PRODUCT_CATALOG_BULK_UPDATE
} from '../../../lib/mutations';
import { useQuery, useMutation } from '@apollo/client';
import usePermission from 'components/common/usePermission';

const ProductCatalogList = () => {
  const { authorized } = usePermission('P21');
  const { authorized: deleteAuthorized, styles: deleteStyles } =
    usePermission('P31');
  const [productCatalogBulkUpdate, { data }] = useMutation(
    PRODUCT_CATALOG_BULK_UPDATE
  );
  const [productCatalogBulkDelete, { data: deletedData }] = useMutation(
    PRODUCT_CATALOG_BULK_DELETE
  );
  const { data: getProductCatalog, refetch } = useQuery(PRODUCT_CATALOG_LIST, {
    fetchPolicy: 'network-only'
  });

  const [getProductCatalogDatas, setGetProductCatalogDatas] = useState([]);

  useEffect(() => {
    if (data) {
      if (data?.productCatalogsBulkUpdate) {
        refetch();

        toast.success(`Амжиллтай засварлалаа`, {
          theme: 'colored'
        });
      } else {
        toast.warning(`Ямар нэгэн алдаа гарлаа`, {
          theme: 'colored'
        });
      }
    }
    if (deletedData) {
      if (deletedData?.productCatalogBulkDelete) {
        refetch();

        toast.success(`Амжиллтай устгагдлаа`, {
          theme: 'colored'
        });
      } else {
        toast.warning(`Ямар нэгэн алдаа гарлаа`, {
          theme: 'colored'
        });
      }
    }
  }, [data, deletedData]);

  useEffect(() => {
    if (getProductCatalog?.productCatalogs?.status?.code === 200) {
      setGetProductCatalogDatas(getProductCatalog?.productCatalogs?.data);
    }
  }, [getProductCatalog]);

  const handleFieldChange = (value, productId) => {
    if (value === 'delete') {
      productCatalogBulkDelete({
        variables: {
          productCatalogIds: [productId]
        }
      });
    } else {
      productCatalogBulkUpdate({
        variables: {
          actionType: value === 'active' ? true : false,
          productCatalogIds: [productId]
        }
      });
    }
  };

  const columns = [
    {
      accessor: 'name',
      Header: 'Мэдээний дугаар',
      headerProps: { className: 'pe-5' },
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        const { _id } = rowData.row.original;
        return (
          <>
            {authorized ? (
              <strong className="text-primary">#{_id.slice(-6)}</strong>
            ) : (
              <Link to={`/product-catalog/product-update/${_id}`}>
                <strong>#{_id.slice(-6)}</strong>
              </Link>
            )}
          </>
        );
      }
    },
    {
      accessor: 'date',
      Header: 'Огноо',
      headerProps: { className: 'pe-7' },
      Cell: rowData => {
        const { createdAt } = rowData.row.original;
        return <>{createdAt.split('T')[0]}</>;
      }
    },
    {
      accessor: 'address',
      Header: 'Гарчиг',
      Cell: rowData => {
        const { name } = rowData.row.original;
        return <>{name.substring(0, 40)}</>;
      }
    },
    {
      accessor: 'status',
      Header: 'Төлөв',
      headerProps: { className: 'pe-7' },
      Cell: rowData => {
        const { status } = rowData.row.original;
        return status ? (
          <SoftBadge pill bg="success" className="me-2">
            Нээлттэй
          </SoftBadge>
        ) : (
          <SoftBadge pill bg="secondary" className="me-2">
            Хаалттай
          </SoftBadge>
        );
      }
    },
    {
      accessor: 'user',
      Header: 'Нийтэлсэн',
      Cell: rowData => {
        const { createdAtBy } = rowData.row.original;
        return <>{createdAtBy?.email}</>;
      }
    },
    {
      accessor: 'none',
      Header: '',
      disableSortBy: true,
      cellProps: {
        className: 'text-end'
      },
      Cell: rowData => {
        const { _id: productId } = rowData.row.original;
        return (
          <CardDropdown>
            <div className="py-2">
              <Dropdown.Item
                onClick={() => handleFieldChange('active', productId)}
              >
                Нээх
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => handleFieldChange('close', productId)}
              >
                Хаах
              </Dropdown.Item>
              <Dropdown.Divider as="div" />
              <Dropdown.Item
                onClick={() => handleFieldChange('delete', productId)}
                className="text-danger"
                style={deleteStyles}
                disabled={deleteAuthorized}
              >
                Устгах
              </Dropdown.Item>
            </div>
          </CardDropdown>
        );
      }
    }
  ];

  return (
    <>
      <Col xs={12} className="mb-3">
        <ProductCatalogHeader />
      </Col>

      <AdvanceTableWrapper
        columns={columns}
        data={getProductCatalogDatas}
        selection
        sortable
        pagination
        perPage={20}
      >
        <Card className="mb-3">
          <Card.Header>
            <ProductCatalogTableHeader
              table
              getBlogDatas={getProductCatalogDatas}
              refetch={refetch}
            />
          </Card.Header>
          <Card.Body className="p-0">
            <AdvanceTable
              table
              headerClassName="bg-200 text-900 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                size: 'sm',
                striped: true,
                className: 'fs--1 mb-0 overflow-hidden'
              }}
            />
          </Card.Body>
          <Card.Footer>
            <AdvanceTablePagination table />
          </Card.Footer>
        </Card>
      </AdvanceTableWrapper>
    </>
  );
};

export default ProductCatalogList;
