import React from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { getSize } from 'helpers/utils';
import { Button, Card, Dropdown, Spinner } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import cloudUpload from 'assets/img/icons/cloud-upload.svg';
import CardDropdown from 'components/common/CardDropdown';

const ProductUpload = ({
  files,
  setFiles,
  imageUploader,
  handleRemove,
  loading
}) => {
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*': []
    },
    maxFiles: 4,
    multiple: true,
    onDrop: async acceptedFiles => {
      setFiles([
        ...files,
        ...acceptedFiles.map(file =>
          Object.assign(file, {
            preview: URL.createObjectURL(file)
          })
        )
      ]);
      acceptedFiles.map(file => {
        imageUploader(file);
      });
    }
  });

  return (
    <Card className="mb-3">
      <Card.Header as="h6" className="bg-light">
        Зураг илгээх
      </Card.Header>
      <Card.Body>
        <div {...getRootProps({ className: 'dropzone-area py-6' })}>
          <input name="image" {...getInputProps()} />
          <div className="fs--1">
            <img src={cloudUpload} alt="" width={25} className="me-2" />
            <span className="d-none d-lg-inline">
              Зураг оруулах
              <br />
              эсвэл,{' '}
            </span>
            <Button variant="link" size="sm" className="p-0 fs--1">
              Хайх
            </Button>
          </div>
        </div>

        <div>
          {files.map((file, index) => (
            <Flex
              alignItems="center"
              className="py-3 border-bottom btn-reveal-trigger"
              key={file.path}
            >
              <img
                className="rounded"
                width={40}
                height={40}
                src={file.preview}
                alt={file.path}
              />

              <Flex
                justifyContent="between"
                alignItems="center"
                className="ms-3 flex-1"
              >
                <div>
                  <h6>{file.path}</h6>
                  <Flex className="position-relative" alignItems="center">
                    <p className="mb-0 fs--1 text-400 line-height-1">
                      <strong>{getSize(file.size)}</strong>
                    </p>
                  </Flex>
                </div>
              </Flex>
              {index === files.length - 1 && loading ? (
                <Spinner animation="grow" size="sm" />
              ) : (
                <CardDropdown>
                  <div className="py-2">
                    <Dropdown.Item
                      className="text-danger"
                      onClick={() => handleRemove(file.path)}
                    >
                      Устгах
                    </Dropdown.Item>
                  </div>
                </CardDropdown>
              )}
            </Flex>
          ))}
        </div>
      </Card.Body>
    </Card>
  );
};
ProductUpload.propTypes = {
  files: PropTypes.array.isRequired,
  setFiles: PropTypes.func.isRequired,
  imageUploader: PropTypes.func.isRequired,
  handleRemove: PropTypes.func.isRequired,
  loading: PropTypes.bool
};
export default ProductUpload;
