import IconButton from 'components/common/IconButton';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import React, { useEffect, useState, useRef, useContext } from 'react';
import { useMutation } from '@apollo/client';
import { Col, Form, Row, Button } from 'react-bootstrap';
import {
  MESSAGE_BULK_UPDATE,
  MESSAGE_BULK_DELETE,
  CONTENT_BULK_CREATE
} from '../../../lib/mutations';
import * as XLSX from 'xlsx';
import { LOCALE_TYPE } from '../../../lib/query';
import { useQuery } from '@apollo/client';
import { AuthWizardContext } from '../../../context/Context';

function convertFromJsonToObject(obj, locale, companyId) {
  const array = [];

  for (let item in obj) {
    const content = [];

    for (let otem in obj[item]) {
      const little = {};
      little['key'] = otem;
      little['value'] = obj[item][otem];
      content.push(little);
    }

    const buffer = {};
    buffer['name'] = item;
    buffer['locale'] = locale;
    buffer['content'] = content;
    buffer['companyId'] = companyId;
    array.push(buffer);
  }
  return array;
}

function convertArrayToExcel(array) {
  const worksheet = XLSX.utils.json_to_sheet(array);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
  const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

  // Create a Blob object with the Excel data
  const blob = new Blob([excelBuffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  });

  // Create a temporary anchor element
  const anchor = document.createElement('a');
  anchor.href = URL.createObjectURL(blob);
  anchor.download = 'data.xlsx'; // Change the filename as desired
  anchor.style.display = 'none';

  // Append the anchor to the document body and click it programmatically
  document.body.appendChild(anchor);
  anchor.click();

  // Clean up
  document.body.removeChild(anchor);
  URL.revokeObjectURL(anchor.href);
}

const ProductCatalogTableHeader = ({
  selectedRowIds,
  getBlogDatas,
  refetch,
  defaultLocale,
  setDefaultLocale
}) => {
  const { company } = useContext(AuthWizardContext);
  const { data: getLocaleType } = useQuery(LOCALE_TYPE);
  const [contentBulkCreate, { data: createdBulkContents }] =
    useMutation(CONTENT_BULK_CREATE);
  const [messageBulkUpdate, { data }] = useMutation(MESSAGE_BULK_UPDATE);
  const [messageBulkDelete, { data: deletedData }] =
    useMutation(MESSAGE_BULK_DELETE);
  const [localeType, setLocaleType] = useState();
  const [actionType, setActionType] = useState('editable');
  const keyArray = Object.keys(selectedRowIds).map(Number);
  const newUpdateIds = keyArray.map(item => {
    return getBlogDatas[item]?._id;
  });

  useEffect(() => {
    if (getLocaleType) {
      setLocaleType(getLocaleType.getLocaleType);
    }
    if (data) {
      if (data?.messageBulkUpdate) {
        refetch();

        toast.success(`Амжиллтай засварлалаа`, {
          theme: 'colored'
        });
      } else {
        toast.warning(`Ямар нэгэн алдаа гарлаа`, {
          theme: 'colored'
        });
      }
    }
    if (deletedData) {
      if (deletedData?.messageBulkDelete) {
        refetch();

        toast.success(`Амжиллтай устгагдлаа`, {
          theme: 'colored'
        });
      } else {
        toast.warning(`Ямар нэгэн алдаа гарлаа`, {
          theme: 'colored'
        });
      }
    }

    if (createdBulkContents) {
      if (createdBulkContents?.textureCreateBulk) {
        refetch();

        toast.success(`Амжиллтай илгээгдлээ`, {
          theme: 'colored'
        });
      } else {
        toast.warning(`Ямар нэгэн алдаа гарлаа`, {
          theme: 'colored'
        });
      }
    }
  }, [data, deletedData, getLocaleType, createdBulkContents]);

  const handleSubmit = e => {
    e.preventDefault();
    if (newUpdateIds.length > 0 && actionType !== 'editable') {
      if (actionType !== 'delete') {
        messageBulkUpdate({
          variables: {
            actionType: actionType === 'active' ? true : false,
            messageIds: newUpdateIds
          }
        });
      } else {
        messageBulkDelete({
          variables: {
            messageIds: newUpdateIds
          }
        });
      }
    }
  };

  const handleFieldChange = e => {
    setActionType(e.target.value);
  };

  const handleChangeFileFormat = () => {
    const newRawData = getBlogDatas.map(item => {
      return {
        _id: item._id,
        locale: item.locale,
        name: item.name,
        content: item.content
      };
    });
    convertArrayToExcel(newRawData);
  };

  const fileInputRef = useRef(null);

  const handleClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = event => {
    try {
      const file = event.target.files[0];
      const locale = file.name.split('.')[0].split('_')[1];
      if (locale) {
        const reader = new FileReader();

        reader.onload = () => {
          const fileContent = reader.result;
          const jsonObject = JSON.parse(fileContent);
          const converted = convertFromJsonToObject(
            jsonObject,
            locale,
            company._id
          );
          if (converted) {
            contentBulkCreate({
              variables: {
                input: converted
              }
            });
            refetch();
          } else {
            toast.warning(
              'Файлын формат буруу байна common_mn.json эсвэл common_en.json гэсэн форматтай байх ёстой',
              {
                theme: 'colored'
              }
            );
          }
        };

        reader.readAsText(file);
      } else {
        toast.warning(
          'Файлын формат буруу байна common_mn.json эсвэл common_en.json гэсэн форматтай байх ёстой',
          {
            theme: 'colored'
          }
        );
      }
    } catch (error) {
      toast.warning(error.message, {
        theme: 'colored'
      });
    }
  };

  return (
    <Row className="flex-between-center">
      <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
        <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">Мэдээлэл</h5>
      </Col>
      <Col xs={8} sm="auto" className="ms-auto text-end ps-0">
        {Object.keys(selectedRowIds).length > 0 ? (
          <div className="d-flex">
            <Form.Select
              size="sm"
              aria-label="Bulk actions"
              onChange={handleFieldChange}
            >
              <option value="editable">Засварлах</option>
              <option value="closed">Үзсэн гэж бүртгэх</option>
              <option value="delete">Устгах</option>
            </Form.Select>
            <Button
              type="button"
              variant="falcon-default"
              size="sm"
              className="ms-2"
              onClick={handleSubmit}
            >
              Хадгалах
            </Button>
          </div>
        ) : (
          <div className="d-flex align-items-baseline">
            <Form.Select
              size="sm"
              className="mr-2"
              aria-label="Bulk actions"
              onChange={e => setDefaultLocale(e.target.value)}
              defaultValue={defaultLocale}
            >
              {localeType &&
                localeType.map((item, i) => (
                  <option
                    selected={defaultLocale === item}
                    key={i}
                    value={item}
                  >
                    {item}
                  </option>
                ))}
            </Form.Select>
            <div className="d-flex align-items-baseline">
              <label htmlFor="fileInput">
                <IconButton
                  variant="falcon-default"
                  type="file"
                  size="sm"
                  icon="external-link-alt"
                  transform="shrink-3"
                  className="mx-2 d-flex align-items-center"
                  onClick={handleClick}
                >
                  Импорт
                </IconButton>
              </label>
              <input
                type="file"
                id="fileInput"
                accept=".json"
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={handleFileChange}
              />
            </div>

            <IconButton
              variant="falcon-default"
              size="sm"
              icon="external-link-alt"
              transform="shrink-3"
              className="mx-2 d-flex align-items-center"
              onClick={handleChangeFileFormat}
            >
              Экспорт
            </IconButton>
          </div>
        )}
      </Col>
    </Row>
  );
};

ProductCatalogTableHeader.propTypes = {
  selectedRowIds: PropTypes.object,
  getBlogDatas: PropTypes.array,
  refetch: PropTypes.func,
  setDefaultLocale: PropTypes.func,
  defaultLocale: PropTypes.string
};

export default ProductCatalogTableHeader;
